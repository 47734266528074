import { takeLatest, call } from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import * as actions from './SerieActions';
import { SerieActionType } from './SerieActionType';
import { callApi } from '../../sagas/APICallSaga';

import { SerieStateData } from './SerieStateReducer';
import { Movie, MovieAsset } from '../../domain/work';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { Cycle } from '../../domain/editorial';
import { LegalContract } from '../../domain/legal';
import { VideoProduct } from '../../domain/commerce';
import { MovieStateData } from '../movie';
import { createFetchEpisodesProductSuccessAction } from './SerieActions';

function* fetchSerie(action: actions.SerieAction): SagaIterator {
    yield call(callApi, {
        endpoint: [
            `movies/${action.id}`,
            `movies/${action.id}/cycles`,
            `movies/${action.id}/contracts`,
            `movies/${action.id}/resources?size=1000`,
            `movies?q_work_type=episode&q_parent=${action.id}`,
        ],
        onSuccess: (
            movie: AxiosResponse<Movie>,
            cycles: AxiosResponse<EmbeddedEntities<'cycles', Cycle>>,
            contracts: AxiosResponse<EmbeddedEntities<'legalContracts', LegalContract>>,
            resources: AxiosResponse<EmbeddedEntities<'resources', MovieAsset>>,
            episodes: AxiosResponse<EmbeddedEntities<'movies', Movie>>
        ): actions.SerieAction => {
            const payload: SerieStateData = {
                ...movie.data,
                cycles: getEmbeddedEntities(cycles.data, 'cycles'),
                legalContracts: getEmbeddedEntities(contracts.data, 'legalContracts'),
                resources: getEmbeddedEntities(resources.data, 'resources'),
                episodes: getEmbeddedEntities(episodes.data, 'movies'),
            };

            return actions.createFetchSerieSuccessAction(payload);
        },
        onError: actions.createFetchSerieErrorAction,
    });
}

function* fetchEpisodesProducts(action: actions.SerieAction): SagaIterator {
    if (action.payload == null) {
        return;
    }

    const serie = action.payload as SerieStateData;

    const episodesIds = uniq((serie.episodes || []).map((episode): string => episode.id));

    yield call(callApi, {
        endpoint: episodesIds.map((id: string): string => `movies/${id}/products`),
        onSuccess: (
            ...responses: AxiosResponse<EmbeddedEntities<'videoProducts', VideoProduct>>[]
        ): actions.SerieAction => {
            const allVideoProducts = flatten(
                responses.map((response): VideoProduct[] => getEmbeddedEntities(response.data, 'videoProducts'))
            );

            const episodes = (serie.episodes || []).map(
                (episode): MovieStateData => {
                    return {
                        ...episode,
                        videoProducts: allVideoProducts.filter(
                            (videoProduct): boolean => videoProduct.movie!.id === episode.id
                        ),
                    };
                }
            );

            return createFetchEpisodesProductSuccessAction({ ...serie, episodes });
        },
        onError: (): AnyAction => ({
            type: 'NO_OP',
        }),
    });
}

export default function* serieSaga(): SagaIterator {
    yield takeLatest(SerieActionType.FETCH_SERIE, fetchSerie);
    yield takeLatest(SerieActionType.FETCH_EPISODES_PRODUCTS, fetchEpisodesProducts);
}
