import React, { useState } from 'react';
import { isFunction, noop, map } from 'lodash';
import axios, { AxiosResponse } from 'axios';
import classNames from 'clsx';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../common/ModalDialog';
import { CommunicationKitProps } from './CommunicationKit';
import { TabList, Tab } from '../../common/A11y/TabList';
import { TFunction } from 'next-i18next';
import { KitCategoryType, KitCategory, getKitCategory, DownloadableFile, fullKitFile } from './communication-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons';
import { humanFileSize } from '../../../utils/FileUtils';
import { ActionButton } from '../../common/Button';
import Router from 'next/router';

import './CommunicationKitModal.scss';

export interface CommunicationKitModalProps extends CommunicationKitProps {
    isOpen?: boolean;
    onClose?: () => void;
}

function renderCategoryTab(
    tabCategory: KitCategoryType,
    tabPosition: number,
    tabsCount: number,
    activeTabCategory: KitCategoryType,
    setActiveTabCategory: React.Dispatch<React.SetStateAction<KitCategoryType>>,
    t: TFunction
): JSX.Element {
    const category: KitCategory = getKitCategory(tabCategory);
    const resourcesCount = category.files.length;

    const active: boolean = activeTabCategory === tabCategory;
    const className: string = classNames('CommunicationKitModal-Header-TabList-Tab', {
        active,
    });
    const resourcesCountSuffix = ` (${resourcesCount})`;
    return (
        <Tab
            key={tabPosition}
            ariaLabel={t(tabCategory)}
            className={className}
            onClick={(): void => {
                setActiveTabCategory(tabCategory);
            }}
            ariaSelected={active}
            ariaPosinset={tabPosition}
            ariaSetsize={tabsCount}
            ariaDisabled={resourcesCount === 0}
        >
            {t(tabCategory)}
            <span className="count">{resourcesCountSuffix}</span>
        </Tab>
    );
}

function downloadFile(file: DownloadableFile, t: TFunction): void {
    if (file == null) {
        return;
    }

    axios
        .get(file.url + '?t=' + Date.now(), {
            responseType: 'blob',
        })
        .then((response: AxiosResponse<Blob>): void => {
            const filename = file.name;
            saveAs(response.data, filename);
        })
        .catch((_err: Error): void => {
            alert(t('could_not_find_asset'));
        });
}

function renderFile(file: DownloadableFile, key: number, locale: string, t: TFunction): JSX.Element {
    return (
        <li key={key} className="CommunicationKitModal-Body-Resources-Resource">
            <FontAwesomeIcon
                size="3x"
                className="CommunicationKitModal-Body-Resources-Resource-Icon"
                aria-hidden="true"
                icon={faFileImage}
            />
            <div className="CommunicationKitModal-Body-Resources-Resource-Data">
                <span className="CommunicationKitModal-Body-Resources-Resource-Data-Filename">{file.name}</span>
                <span className="CommunicationKitModal-Body-Resources-Resource-Data-Filesize">
                    {humanFileSize(file.size, locale)}
                </span>
            </div>
            <button
                className="CommunicationKitModal-Body-Resources-Resource-DownloadButton"
                onClick={(): void => {
                    downloadFile(file, t);
                }}
            >
                <FontAwesomeIcon size="2x" aria-hidden="true" icon={faArrowToBottom} />
            </button>
        </li>
    );
}

export function CommunicationKitModal({ isOpen, onClose, i18n, t }: CommunicationKitModalProps): JSX.Element {
    const [categoryFilter, setCategoryFilter] = useState<KitCategoryType>(KitCategoryType.MUTUAL);

    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    const fullKitFileSize = humanFileSize(fullKitFile.size, i18n.language);

    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('communicationKit')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
            className="CommunicationKitModal"
        >
            <ModalDialogHeader className="CommunicationKitModal-Header" onClose={handleClose} t={t}>
                <span className="CommunicationKitModal-Header-Title">{t('communicationKit')}</span>
                <ActionButton
                    className="CommunicationKitModal-Header-Download"
                    textClassName="CommunicationKitModal-Download-Text"
                    text={t('downloadCommunicationKit', { fullKitFileSize })}
                    onClick={(): void => {
                        Router.push(fullKitFile.url);
                    }}
                />
                <TabList className="CommunicationKitModal-Header-TabList" orientation="horizontal">
                    {[KitCategoryType.MUTUAL, KitCategoryType.FRENCH_CINEMA, KitCategoryType.CINEMATHEQUE_AFRIQUE].map(
                        (category: KitCategoryType, idx: number, categories: KitCategoryType[]): JSX.Element => {
                            return renderCategoryTab(
                                category,
                                idx,
                                categories.length,
                                categoryFilter,
                                setCategoryFilter,
                                t
                            );
                        }
                    )}
                </TabList>
            </ModalDialogHeader>
            <ModalDialogBody>
                <ul className="CommunicationKitModal-Body-Resources">
                    {map(
                        getKitCategory(categoryFilter).files,
                        (file: DownloadableFile, key: number): JSX.Element => {
                            return renderFile(file, key, i18n.language, t);
                        }
                    )}
                </ul>
            </ModalDialogBody>
        </ModalDialog>
    );
}
