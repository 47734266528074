import { PaginationData } from '../../domain/pagination';
import { Playlist } from '../../domain/community';
import { FetchState } from '../Fetch';
import { PlaylistsAction, PlaylistAction, DeletePlaylistAction } from './PlaylistsActions';
import { PlaylistsActionType } from './PlaylistsActionType';
import { RequestError } from '../../sagas/APICallSaga';
import filter from 'lodash/filter';
import { map } from 'lodash';

export interface PlaylistState extends FetchState<Playlist[]> {
    pagination?: PaginationData;
    selectedPlaylistId?: string;
}

export const initialState: PlaylistState = {
    isLoading: false,
};

type Action = PlaylistsAction & PlaylistAction & DeletePlaylistAction;

export const playlistsReducer = (state: PlaylistState = initialState, action: Action): PlaylistState => {
    switch (action.type) {
        case PlaylistsActionType.FETCH_PLAYLISTS:
            return {
                ...state,
                isLoading: true,
            };

        case PlaylistsActionType.FETCH_PLAYLISTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload as Playlist[],
            };

        case PlaylistsActionType.FETCH_PLAYLISTS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
            };

        case PlaylistsActionType.CREATE_PLAYLIST:
            return {
                ...state,
                isLoading: true,
            };

        case PlaylistsActionType.CREATE_PLAYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case PlaylistsActionType.UPDATE_PLAYLIST:
            return {
                ...state,
                isLoading: true,
            };

        case PlaylistsActionType.UPDATE_PLAYLIST_SUCCESS:
            const updatedPlaylist = action.payload as Playlist;
            const updatedPlaylistId = updatedPlaylist.id;
            return {
                ...state,
                isLoading: false,
                data: map(
                    state.data,
                    (playlist: Playlist): Playlist => {
                        return playlist.id === updatedPlaylistId ? { ...updatedPlaylist } : playlist;
                    }
                ),
            };

        case PlaylistsActionType.UPDATE_PLAYLIST_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
            };

        case PlaylistsActionType.DELETE_PLAYLIST:
            return {
                ...state,
                isLoading: false,
                selectedPlaylistId: action.playlistId,
            };

        case PlaylistsActionType.DELETE_PLAYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: filter(state.data, (playlist: Playlist): boolean => {
                    return playlist.id !== state.selectedPlaylistId;
                }),
            };
        default:
            return state;
    }
};
