import { ScreeningsAction } from './ScreeningsActions';
import { ScreeningsActionType } from './ScreeningsActionType';
import { ScreeningEvaluation } from '../../domain/user';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import findIndex from 'lodash/findIndex';
import { LegalContract } from '../../domain/legal';

export interface ScreeningsState extends FetchState<ScreeningEvaluation[]> {
    contracts: LegalContract[][];
    successfullyAdded: boolean;
    successfullyEdited: boolean;
}

const initialState: ScreeningsState = {
    contracts: [],
    isLoading: false,
    successfullyAdded: false,
    successfullyEdited: false,
};

export const screeningsReducer = (state: ScreeningsState = initialState, action: ScreeningsAction): ScreeningsState => {
    switch (action.type) {
        case ScreeningsActionType.FETCH_SCREENINGS:
            return {
                ...state,
                isLoading: true,
            };

        case ScreeningsActionType.FETCH_SCREENINGS_SUCCESS:
            return {
                ...state,
                isLoading: true,
                error: undefined,
                data: action.payload as ScreeningEvaluation[],
            };

        case ScreeningsActionType.FETCH_SCREENINGS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
            };

        case ScreeningsActionType.FETCH_CONTRACTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contracts: action.payload as LegalContract[][],
            };

        case ScreeningsActionType.ADD_SCREENING_SUCCESS:
            const payload = action.payload as ScreeningEvaluation;
            const updatedScreeningIndex = findIndex(state.data, (screening): boolean => screening.id === payload.id);
            const screenings = Object.assign([], state.data, {
                [updatedScreeningIndex]: payload,
            });

            return {
                ...state,
                successfullyAdded: true,
                data: screenings,
            };

        case ScreeningsActionType.EDIT_SCREENING_SUCCESS:
            const payloadEdit = action.payload as ScreeningEvaluation;
            const updatedScreeningIndexEdit = findIndex(
                state.data,
                (screening): boolean => screening.id === payloadEdit.id
            );
            const newScreenings = Object.assign([], state.data, {
                [updatedScreeningIndexEdit]: payloadEdit,
            });

            return {
                ...state,
                successfullyEdited: true,
                data: newScreenings,
            };

        case ScreeningsActionType.RESET_ADD_SCREENING_SUCCESS:
            return {
                ...state,
                successfullyAdded: false,
                successfullyEdited: false,
            };

        default:
            return state;
    }
};
