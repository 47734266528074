import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilm, faVolume, faCommentAltLines } from '@fortawesome/pro-regular-svg-icons';
import { WithT } from 'i18next';
import { library, IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { CatalogLanguage, VideoProduct } from '../../../../domain/commerce';
import { MoviePoster } from '../../../common/MoviePoster';
import { Link } from '../../../../i18n';

import './SimpleMovieCard.scss';

library.add(faFilm, faVolume, faCommentAltLines);

const getFormattedAudioLanguage = (videoProduct: VideoProduct): string | null => {
    if (!videoProduct.audioLanguages || videoProduct.audioLanguages.length === 0) {
        return null;
    }
    return videoProduct.audioLanguages.map((audio: CatalogLanguage): string => audio.name).join(', ');
};

const getFormattedSubtitlesLanguage = (videoProduct: VideoProduct): string | null => {
    if (!videoProduct.subtitleLanguages || videoProduct.subtitleLanguages.length === 0) {
        return null;
    }
    return videoProduct.subtitleLanguages
        .map((sub: CatalogLanguage): string => sub.name)
        .sort()
        .join(', ');
};

const renderOption = (option: string | null, icon: IconProp): JSX.Element | null =>
    option === null ? null : (
        <span className="SimpleMovieCard-Info-Option">
            <FontAwesomeIcon size="1x" aria-hidden="true" icon={icon} className="SimpleMovieCard-Info-Icon" />
            {option}
        </span>
    );

const renderAdditionalInfo = (videoProduct: VideoProduct): JSX.Element | null => {
    if (isEmpty(videoProduct.additionalInfo)) {
        return null;
    }
    return <span className="SimpleMovieCard-AdditionalInfo">{videoProduct.additionalInfo}</span>;
};

const getVideoProductTypeName = (videoProduct: VideoProduct): string => {
    const name: string = videoProduct.type.name;
    return name;
};

interface SimpleMovieCardProps extends WithT {
    videoProduct: VideoProduct;
    className?: string;
    moviePosterWidth: number;
    moviePosterHeight: number;
}

export const SimpleMovieCard: FunctionComponent<SimpleMovieCardProps> = ({
    videoProduct,
    className,
    moviePosterWidth,
    moviePosterHeight,
    t,
}: SimpleMovieCardProps): JSX.Element | null => {
    const { movie } = videoProduct;

    if (movie === undefined) {
        return null;
    }

    const formattedAudioLanguages = getFormattedAudioLanguage(videoProduct);
    const formattedSubtitlesLanguages = getFormattedSubtitlesLanguage(videoProduct);

    return (
        <article className={classNames('SimpleMovieCard', className)}>
            <MoviePoster
                movie={movie}
                width={moviePosterWidth}
                height={moviePosterHeight}
                className="SimpleMovieCard-Poster"
                t={t}
            />
            <div className="SimpleMovieCard-Info">
                <Link href={{ pathname: '/movie', query: { id: movie.id } }}>
                    <h1 className="SimpleMovieCard-Info-Title">{movie.title}</h1>
                </Link>
                {renderOption(getVideoProductTypeName(videoProduct), faFilm)}
                {renderOption(formattedAudioLanguages, faVolume)}
                {renderOption(formattedSubtitlesLanguages, faCommentAltLines)}
                {renderAdditionalInfo(videoProduct)}
            </div>
        </article>
    );
};
