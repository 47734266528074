/* eslint-disable react/jsx-no-literals */
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { WithT } from 'i18next';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../common/ModalDialog';
import { FrontUser, ProfileType } from '../../../domain/user';
import { AppState } from '../../../store/reducers';
import { MovieStateData } from '../../../modules/movie';
import { Router, Trans } from '../../../i18n';
import { ActionButton } from '../../common/Button';
import { SimpleMovieCard } from './SimpleMovieCard/SimpleMovieCard';
import { DownloadButton } from '../../common/Player';
import { Link } from '../../common/A11y/Link';

import './DigitalOrderModal.scss';

export interface OwnProps extends WithT {
    isOpen?: boolean;
    onClose?: () => void;
}

interface StateProps {
    movie?: MovieStateData;
    frontUser?: FrontUser;
}

type DigitalOrderModalProps = OwnProps & StateProps;

const _DigitalOrderModal: FunctionComponent<DigitalOrderModalProps> = ({
    isOpen,
    onClose,
    movie,
    frontUser,
    t,
}: DigitalOrderModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;

    if (!movie || !frontUser) {
        return null;
    }

    if (movie.videoProducts === undefined || movie.videoProducts[0] === undefined) {
        // console.error('No videoProduct!'); // FIXME: use a proper logger, not console.xxx
        return null;
    }

    const videoProduct = movie.videoProducts[0];

    const contract = movie && movie.legalContracts && movie.legalContracts[0];
    let endDate = dayjs().add(30, 'days');

    if (contract && contract.endingAt && dayjs(contract.endingAt).isBefore(endDate)) {
        endDate = dayjs(contract.endingAt);
    }

    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('cart:addedToDownloadedMovies')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
        >
            <ModalDialogHeader className="DigitalOrderModal-Header" onClose={handleClose} t={t}>
                <FontAwesomeIcon
                    size="1x"
                    aria-hidden="true"
                    icon={faCheck}
                    className="DigitalOrderModal-Header-Icon"
                />
                {t('cart:addedToDownloadedMovies')}
            </ModalDialogHeader>
            <ModalDialogBody className="DigitalOrderModal-Body">
                <SimpleMovieCard videoProduct={videoProduct} t={t} moviePosterWidth={120} moviePosterHeight={160} />
                <span className="DigitalOrderModal-Body-Text">
                    {t('movie:movieAvailableUntil')} <strong>{endDate.format('DD MMMM YYYY')}</strong>
                    {'.'}
                </span>
                {frontUser.profileType === ProfileType.PROFESSIONAL && (
                    <span className="DigitalOrderModal-Body-Text">
                        <Trans i18nKey="remainingCredits" ns="movie">
                            You will have <strong>{{ credits: (frontUser.availableCredits || 1) - 1 }}</strong> credits
                            remaining.
                        </Trans>
                    </span>
                )}
                <ActionButton
                    text={t('cart:goToMyDownloadedMovies')}
                    className="DigitalOrderModal-Body-GoToOrderButton"
                    onClick={(): void => {
                        Router.push('/orders');
                        handleClose();
                    }}
                />
                <DownloadButton text={t('cart:downloadOnPlayer')} className="DigitalOrderModal-Body-DownloadButton" />
                <div className="DigitalOrderModal-Body-DownloadPlayer">
                    <Link className="DigitalOrderModal-Body-DownloadPlayer-Link" href="/player">
                        {t('order:downloadPlayer')}
                    </Link>{' '}
                    <span className="DigitalOrderModal-Body-DownloadPlayer-Compatibility">
                        {`(${t('order:playerInfoCompatibility')})`}
                    </span>
                </div>
            </ModalDialogBody>
        </ModalDialog>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    frontUser: state.profile.data,
    movie: state.movie.data,
});

export const DigitalOrderModal = connect(mapStateToProps)(_DigitalOrderModal) as FunctionComponent<OwnProps>;
