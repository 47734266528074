import { CookiesAction, GetCookiesConsentSuccessAction } from './CookiesActions';
import { CookiesActionType } from './CookiesActionType';

export enum CookiesConsent {
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
}

export interface CookiesState {
    consent?: CookiesConsent;
}

export const initialState: CookiesState = {};

export const cookiesReducer = (state: CookiesState = initialState, action: CookiesAction): CookiesState => {
    switch (action.type) {
        case CookiesActionType.ACCEPT:
            return {
                consent: CookiesConsent.ACCEPTED,
            };

        case CookiesActionType.DECLINE:
            return {
                consent: CookiesConsent.DECLINED,
            };

        case CookiesActionType.GET_CONSENT_SUCCESS:
            return {
                consent: (action as GetCookiesConsentSuccessAction).consent,
            };

        default:
            return state;
    }
};
