import { SerieActionType } from './SerieActionType';
import { uuid } from '../../domain/core';
import { SerieStateData } from './SerieStateReducer';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export interface SerieAction extends FetchAction<SerieActionType, Partial<SerieStateData>> {
    id?: uuid;
}

export function createFetchSerieAction(id: uuid): SerieAction {
    return {
        type: SerieActionType.FETCH_SERIE,
        id,
    };
}

export function createFetchSerieSuccessAction(payload: Partial<SerieStateData>): SerieAction {
    return {
        type: SerieActionType.FETCH_SERIE_SUCCESS,
        payload,
    };
}

export function createFetchSerieErrorAction(error: RequestError): SerieAction {
    return {
        type: SerieActionType.FETCH_SERIE_ERROR,
        payload: error,
    };
}

export function createFetchEpisodesProducts(payload: Partial<SerieStateData>): SerieAction {
    return {
        type: SerieActionType.FETCH_EPISODES_PRODUCTS,
        payload,
    };
}

export function createFetchEpisodesProductSuccessAction(payload: Partial<SerieStateData>): SerieAction {
    return {
        type: SerieActionType.FETCH_EPISODES_PRODUCTS_SUCCESS,
        payload,
    };
}
