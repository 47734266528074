import { ReferenceItem, datetime, TrackedObject, uri, VocabularyEntry } from './core';
import { Movie, MovieSlideData } from './work';
import { Asset, AssetData } from './media';
import { MovieStateData } from '../modules/movie';

export type Homepage = ReferenceItem & HomepageData & TrackedObject;

export enum EditorialStatus {
    ARCHIVED,
    DRAFT,
    PUBLISHED,
}

export interface HomepageData {
    title?: string; // #425298 - TO DELETE
    wall?: NewsWallLayer;
    recentlyAdded?: MovieListLayer;
    cycle?: HomeCycleLayer;
    upcoming?: MovieListLayer;
    lastChance?: MovieListLayer;
    event?: EventLayer;
    focus?: Focus;
    mostSeen?: MovieListLayer;
    collection?: CollectionLayer;
    news?: NewsLayer;
    currentEvent?: CurrentEventLayer;
}

export interface CurrentEventLayer {
    title: string;
    currentEvents: CurrentEvent[];
}

export interface NewsWallLayer {
    walls?: NewsWall[];
}

export interface NewsWall {
    title: string;
    movies?: MovieSlideData[];
}

export interface EventLayer {
    countryCount?: number;
    screeningCount?: number;
    movieCount?: number;
    events?: Event[];
}

export interface MovieListLayer {
    movies?: MovieSlideData[];
}

export interface Focus {
    title: string;
    mainImage: Asset;
    subhead?: string;
    description?: string;
    externalUrl?: uri;
}

export interface CollectionLayer {
    title?: string;
    collections?: Collection[];
}

export interface CycleLayer {
    cycles?: Cycle[];
}

export interface HomeCycleLayer {
    cycles?: HomeCycle[];
}

export interface NewsLayer {
    items?: NewsItem[];
}

export type HomepageUpdate = ReferenceItem & HomepageData;

export type Theme = ReferenceItem & ThemeData & TrackedObject;

export type ThemeData = VocabularyEntry;

export type ThemeUpdate = ReferenceItem & Required<ThemeData>;

export type Collection = ReferenceItem & CollectionData & TrackedObject;

export interface CollectionData {
    title: string;
    subtitle?: string; // #425298 - TO DELETE
    description?: string;
    queryUrl?: uri; // #425298 - TO DELETE
    movies?: Movie[];
    mainImage?: Asset;
}

export type CollectionUpdate = ReferenceItem & Required<CollectionData>;

export type Cycle = ReferenceItem & CycleData & TrackedObject;

export enum CycleType {
    DEFAULT = 'default',
    STREAMING = 'streaming',
}

export interface CycleData {
    title: string;
    publicationStartDate: datetime;
    type: CycleType;
    subTitle?: string;
    description?: string;
    works?: Movie[]; // Cannot change to MovieSlideData because other data are involved
    themes?: Theme[];
}

export type CycleUpdate = ReferenceItem & Required<CycleData>;

export type HomeCycle = ReferenceItem & HomeCycleData & TrackedObject;

export interface HomeCycleData {
    title: string;
    publicationStartDate: datetime;
    type: CycleType;
    subTitle?: string;
    description?: string;
    works?: MovieSlideData[];
}

export type NewsItem = ReferenceItem & NewsItemData & TrackedObject;

export interface NewsItemData {
    title: string;
    subtitle?: string;
    date: datetime; // #425298 - TO DELETE
    externalUrl: uri;
}

export type NewsItemUpdate = ReferenceItem & Required<NewsItemData>;

export type Event = ReferenceItem & EventData & TrackedObject;

export interface EventData {
    title: string;
    subTitle: string;
    location?: EventLocation;
    startingAt?: datetime;
    endingAt?: datetime;
    url?: string;
}

export type EventUpdate = ReferenceItem & Required<EventData>;

export type EventLocation = ReferenceItem & EventLocationData & TrackedObject;

export type EventLocationData = VocabularyEntry;

export type EventLocationUpdate = ReferenceItem & Required<EventLocationData>;

export type CatalogType = 'fr' | 'afr' | 'alt';

export type StaticPage = ReferenceItem & StaticPageData & TrackedObject;

export interface StaticPageData {
    code: string;
    title: string;
    content: string;
}

export type FaqUserProfileType = 'if' | 'teacher' | 'french_partner' | 'other';

export interface FaqCategoryQuestionData {
    title: string;
    code: string;
    content: string;
    userProfileTypeList: FaqUserProfileType[];
}

export type FaqCategoryQuestion = FaqCategoryQuestionData & TrackedObject & ReferenceItem;

export interface FaqCategoryData {
    name: string;
    code: string;
    category: 'catalog' | 'technical' | 'usage';
    qas: FaqCategoryQuestion[];
}

export type FaqCategory = FaqCategoryData & TrackedObject & ReferenceItem;

export type Catalog = CatalogData & ReferenceItem;
export interface CatalogData {
    name: string;
    hidden: boolean;
    code: string;
    description: string;
}

export type Partner = ReferenceItem & PartnerData & TrackedObject;
export interface PartnerData {
    code: string; // #425298 - TO DELETE
    name: string;
    externalUrl: string;
    logo: Asset;
}

export type StreamingEvent = ReferenceItem & StreamingEventData & TrackedObject;

export enum StreamingEventStatus {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
}

export interface StreamingEventData {
    title: string;
    publicationStartDate: datetime;
    publicationEndDate: datetime;
    subTitle: string;
    description: string;
    slug: string;
    status: StreamingEventStatus;
    mainColor: string;
    mainImage?: Asset;
    works?: Movie[];
    themes?: Theme[];
    waitingMessage?: string;
    endMessage?: string;
    isSeries: boolean;
    partners?: Partner[];
}

export type CurrentEvent = ReferenceItem & CurrentEventData & TrackedObject;

export interface CurrentEventData {
    title?: string;
    subTitle?: string;
    description?: string;
    shortDescription?: string;
    url?: string;
    poster?: Asset;
    color?: string;
    cycles?: Cycle[];
    partners?: Partner[];
}

export interface EducationInitiativeData {
    title: string;
    description: string;
    url: string;
    urlLabel?: string;
    mainImage: AssetData;
}

export interface EducationResourceData {
    title: string;
    subTitle: string;
    url: string;
}

export type EducationPage = ReferenceItem &
    TrackedObject &
    EducationData & {
        countCinema: number;
        countFrench: number;
        hasFetchedRessources?: boolean;
    };

export interface EducationData {
    title: string;
    frenchLayer: { movies: MovieStateData[] };
    cinemaLayer: { movies: MovieStateData[] };
    initiativeLayer: { initiatives: EducationInitiativeData[] };
    resourceLayer: { resources: EducationResourceData[] };
}
