import React, { FunctionComponent } from 'react';
import { withTranslation } from '../../i18n';
import { createToggleMenuMobileAction, UIAction } from '../../modules/UI';
import { NavbarBurger } from '../common/TypedBulmaComponents';
import { WithTranslation } from 'next-i18next';
import { ActionCreator, compose } from 'redux';
import { connect } from 'react-redux';

interface DispatchProps {
    toogleMenuMobile: ActionCreator<UIAction>;
}

type NavBarBurgerButtonProps = DispatchProps & WithTranslation;

const NavBarBurgerButton: FunctionComponent<NavBarBurgerButtonProps> = ({
    t,
    toogleMenuMobile,
}: NavBarBurgerButtonProps): JSX.Element => {
    return (
        <button className="burger-menu" onClick={toogleMenuMobile}>
            <NavbarBurger renderAs="span" aria-label={t('header:burgerMenuLabel')} />
            <span>{t('menu')}</span>
        </button>
    );
};

const mapDispatchToProps: DispatchProps = {
    toogleMenuMobile: createToggleMenuMobileAction,
};

export default compose(
    connect(
        null,
        mapDispatchToProps
    ),
    withTranslation(['header', 'common'])
)(NavBarBurgerButton) as FunctionComponent;
