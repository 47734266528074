import { StaticPage } from '../../domain/editorial';
import { StaticPageActionType } from './StaticPageActionType';
import { StaticPageAction } from './StaticPageActions';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type StaticPageState = FetchState<StaticPage>;

export const initialState: StaticPageState = {
    isLoading: false,
};

export const staticPageReducer = (state: StaticPageState = initialState, action: StaticPageAction): StaticPageState => {
    switch (action.type) {
        case StaticPageActionType.FETCH_STATIC_PAGE:
            return {
                ...state,
                isLoading: true,
            };

        case StaticPageActionType.FETCH_STATIC_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as StaticPage,
            };

        case StaticPageActionType.FETCH_STATIC_PAGE_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
