import { CurrentEventsActionType } from './CurrentEventsActionType';
import { AnyAction } from 'redux';
import { CurrentEvent } from '../../domain/editorial';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type CurrentEventState = FetchState<CurrentEvent[]>;

export const initialState: CurrentEventState = {
    isLoading: false,
};

export const CurrentEventsReducer = (state: CurrentEventState = initialState, action: AnyAction): CurrentEventState => {
    switch (action.type) {
        case CurrentEventsActionType.FETCH_CURRENT_EVENTS:
            return {
                ...state,
                isLoading: true,
            };

        case CurrentEventsActionType.FETCH_CURRENT_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload,
            };

        case CurrentEventsActionType.FETCH_CURRENT_EVENTS_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
