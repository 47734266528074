import { InjectedFormikProps, withFormik, Field } from 'formik';
import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import AriaRole from '../AriaRole';
import { TextAreaField } from '../common/Form';
import { ActionButton } from '../common/Button';
import { WithT } from 'i18next';
import { QuantityPicker } from '../common/QuantityPicker';
import { CreditRequest } from '../../domain/user';
import c from 'lodash/upperFirst';

interface FormProps extends WithT {
    onSubmit: (request: CreditRequest) => void;
}

type FormValues = CreditRequest;

const InnerForm: FunctionComponent<InjectedFormikProps<FormProps, FormValues>> = ({
    t,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    values,
}: InjectedFormikProps<FormProps, FormValues>): JSX.Element => {
    return (
        <form onSubmit={handleSubmit} className="CreditsRequestForm">
            <div className="CreditsRequestForm-Amount">
                <label className="CreditsRequestForm-Amount-Label" htmlFor="CreditsRequestForm-QtyPicker">
                    {c(t('credits:newCreditsQtyRequested'))}
                </label>
                <QuantityPicker
                    quantity={values.amount}
                    className="CreditsRequestForm-Amount-Picker"
                    buttonClassName="CreditsRequestForm-Amount-Picker-Buttons"
                    onChange={(qty: number): void => setFieldValue('amount', qty, false)}
                    id="CreditsRequestForm-QtyPicker"
                />
            </div>
            <Field
                name="description"
                label={c(t('credits:projectDescAndPlannedDates'))}
                component={TextAreaField}
                t={t}
                displayErrors={false}
                maxTextLength={500}
            />

            <ActionButton
                text={t('credits:sendNewCreditsRequest')}
                className="CreditsRequestForm-SendRequestButton"
                type="submit"
            />

            {Object.keys(errors).length > 0 && Object.keys(touched).length > 0 && (
                <ul className="FormErrors CreditsRequestForm-Errors" role={AriaRole.ALERT}>
                    {touched.amount && errors.amount && <li>{errors.amount}</li>}
                    {touched.description && errors.description && <li>{errors.description}</li>}
                </ul>
            )}
        </form>
    );
};

export const CreditsRequestForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (): FormValues => ({ amount: 0, description: '' }),
    validationSchema: (props: FormProps): Yup.Schema<FormValues> =>
        Yup.object().shape({
            amount: Yup.number()
                .positive(c(props.t('credits:requestInvalidAmount')))
                .integer(c(props.t('credits:requestInvalidAmount')))
                .min(1, c(props.t('credits:requestInvalidAmount')))
                .required(c(props.t('creditsRequestAmountRequired'))),
            description: Yup.string().required(c(props.t('credits:requestDescriptionRequired'))),
        }),
    handleSubmit: (values: FormValues, { setSubmitting, props }): void => {
        props.onSubmit(values);
        setSubmitting(false);
    },
})(InnerForm);
