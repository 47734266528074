import { CreditsRequestActionType } from './CreditsRequestActionType';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { CreditsRequestAction } from './CreditsRequestActions';

export interface CreditsRequestState extends FetchState<{}> {
    requestSubmitted: boolean;
}

export const initialState: CreditsRequestState = {
    isLoading: false,
    requestSubmitted: false,
};

export const creditsRequestReducer = (
    state: CreditsRequestState = initialState,
    action: CreditsRequestAction
): CreditsRequestState => {
    switch (action.type) {
        case CreditsRequestActionType.SUBMIT_SUCCESS:
            return {
                ...state,
                error: undefined,
                requestSubmitted: true,
            };

        case CreditsRequestActionType.SUBMIT_ERROR:
            return {
                ...state,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
