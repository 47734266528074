import React, { FunctionComponent } from 'react';
import { ActionButton } from '../Button';

const PLAYER_PROTOCOL: string = process.env.PROTOCOL_NAME || 'player-ifcinemapp';

function openOrDownloadPlayer(): void {
    let timeout: number;
    window.addEventListener('blur', function(): void | undefined {
        window.clearTimeout(timeout);
    });
    timeout = window.setTimeout((): void => {
        window.location.href = '/player';
    }, 200);
    window.location.href = PLAYER_PROTOCOL + '://';
}

interface DownloadButtonProps {
    text: string;
    className?: string;
}

export const DownloadButton: FunctionComponent<DownloadButtonProps> = ({ text, className }): JSX.Element => (
    <ActionButton text={text} className={className} onClick={openOrDownloadPlayer} />
);
