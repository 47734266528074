import { SearchSuggestionsActionType } from './SearchSuggestionsActionType';
import { FetchState } from '../Fetch';
import { SearchSuggestionsAction } from './SearchSuggestionsActions';
import { RequestError } from '../../sagas/APICallSaga';
import { SearchSuggestions } from '../../domain/search';

export type SearchSuggestionsState = FetchState<SearchSuggestions>;

export const initialState: SearchSuggestionsState = {
    isLoading: false,
};

export const searchSuggestionsReducer = (
    state: SearchSuggestionsState = initialState,
    action: SearchSuggestionsAction
): SearchSuggestionsState => {
    switch (action.type) {
        case SearchSuggestionsActionType.FETCH_SUGGESTIONS:
            return {
                ...state,
                isLoading: true,
            };

        case SearchSuggestionsActionType.FETCH_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload as SearchSuggestions,
            };

        case SearchSuggestionsActionType.FETCH_SUGGESTIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
            };

        case SearchSuggestionsActionType.RESET_SUGGESTIONS:
            return {
                ...state,
                isLoading: false,
                data: undefined,
            };

        default:
            return state;
    }
};
