import React, { FunctionComponent } from 'react';
import { Navbar } from 'react-bulma-components';
import { Link, withTranslation } from '../../i18n';
import { NavbarBrand } from '../common/TypedBulmaComponents';
import { WithTranslation } from 'next-i18next';

interface OwnProps {
    className?: string;
}

type NavBarBrandProps = OwnProps & WithTranslation;

const _NavBarBrand: FunctionComponent<NavBarBrandProps> = ({ className, t }: NavBarBrandProps): JSX.Element => {
    return (
        <NavbarBrand renderAs="div" className={className}>
            <Navbar.Item renderAs="h1">
                <Link href="/">
                    <a title={t('header:logoTooltip')} className="logo_if">
                        <img src="/static/img/logo_if.svg" alt={t('logoAlt')} />
                    </a>
                </Link>
                <Link href="/">
                    <a title={t('header:logoTooltip')} className="logo_if_cinema">
                        <img src="/static/img/logo_ifcinema.svg" alt={t('logoAlt')} />
                    </a>
                </Link>
            </Navbar.Item>
        </NavbarBrand>
    );
};

export const NavBarBrand = withTranslation(['header', 'common'])(_NavBarBrand);
