import { UserJobsAction } from './UserJobsActions';
import { RequestError } from '../../../sagas/APICallSaga';
import { UserJob } from '../../../domain/user';
import { UserJobsState } from '../UserFormDataStateReducer';
import { UserJobsActionType } from './UserJobsActionType';

const initialUserJobsState: UserJobsState = {
    isLoading: false,
};

export const userJobsReducer = (state: UserJobsState = initialUserJobsState, action: UserJobsAction): UserJobsState => {
    switch (action.type) {
        case UserJobsActionType.FETCH:
            return {
                ...state,
                isLoading: true,
            };
        case UserJobsActionType.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as UserJob[],
            };
        case UserJobsActionType.FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };
        default:
            return state;
    }
};
