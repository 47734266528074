import { takeLatest, take, fork, put } from 'redux-saga/effects';
import { FreeMoviesActionType } from './FreeMoviesActionType';
import { SagaIterator } from 'redux-saga';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import { FreeMoviesWatchRequestAction, createWatchRequestSuccesAction } from './FreeMoviesActions';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { MovieAction, createFetchMovieSuccessAction, MovieStateData, MovieActionType } from '../movie';
import { AnyAction } from 'redux';
import { MovieAsset } from '../../domain/work';

function* handleWatchRequest(action: FreeMoviesWatchRequestAction): SagaIterator {
    yield fork(callApi, {
        endpoint: `movies/${action.payload.id}/resources?size=1000`,
        onSuccess: (resources: AxiosResponse<EmbeddedEntities<'resources', MovieAsset>>): MovieAction => {
            const payload: Partial<MovieStateData> = {
                ...action.payload,
                resources: getEmbeddedEntities(resources.data, 'resources'),
            };
            return createFetchMovieSuccessAction(payload);
        },
        onError: (): AnyAction => ({
            type: 'NO_OP',
        }),
    });

    const movieAction: MovieAction = yield take([
        MovieActionType.FETCH_MOVIE_SUCCESS,
        MovieActionType.FETCH_MOVIE_ERROR,
    ]);

    if (movieAction.type === MovieActionType.FETCH_MOVIE_ERROR) {
        return;
    }

    const moviePayload = movieAction.payload as Partial<MovieStateData>;
    yield put(createWatchRequestSuccesAction(moviePayload));
}

export default function* FreeMoviesSaga(): SagaIterator {
    yield takeLatest(FreeMoviesActionType.WATCH_REQUEST, handleWatchRequest);
}
