import { FaqCategoryActionType } from './FaqCategoryActionType';
import { RequestError } from '../../sagas/APICallSaga';
import { FetchAction } from '../Fetch';
import { FaqCategory } from '../../domain/editorial';
import { uuid } from '../../domain/core';

export interface FaqCategoryAction extends FetchAction<FaqCategoryActionType, FaqCategory> {
    id?: uuid;
    code?: string;
}

export function createFetchCategoryAction(id: uuid): FaqCategoryAction {
    return {
        type: FaqCategoryActionType.FETCH_FAQ_CATEGORY,
        id,
    };
}

export function createFetchCategoryByCodeAction(code: string): FaqCategoryAction {
    return {
        type: FaqCategoryActionType.FETCH_FAQ_CATEGORY,
        code,
    };
}

export function createFetchCategorySuccessAction(faqCategory: FaqCategory): FaqCategoryAction {
    return {
        type: FaqCategoryActionType.FETCH_FAQ_CATEGORY_SUCCESS,
        payload: faqCategory,
    };
}

export function createFetchCategoryErrorAction(error: RequestError): FaqCategoryAction {
    return {
        type: FaqCategoryActionType.FETCH_FAQ_CATEGORY_ERROR,
        payload: error,
    };
}
