import React, { FunctionComponent, useEffect } from 'react';
import { WithT } from 'i18next';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../../common/ModalDialog';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import { AddReviewForm } from '../AddReviewForm';
import { FrontUser } from '../../../../domain/user';
import { Review } from '../../../../domain/community';
import { AppState } from '../../../../store/reducers';
import { connect } from 'react-redux';
import { MovieStateData } from '../../../../modules/movie';

export interface OwnProps extends WithT {
    isOpen?: boolean;
    onClose?: () => void;
}

interface StateProps {
    successfullyAdded: boolean;
    movie?: MovieStateData;
    frontUser?: FrontUser;
    reviewToEdit?: Review;
}

type AddReviewModalProps = OwnProps & StateProps;

const _AddReviewModal: FunctionComponent<AddReviewModalProps> = ({
    isOpen,
    onClose,
    movie,
    frontUser,
    reviewToEdit,
    successfullyAdded,
    t,
}: AddReviewModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    useEffect((): void => {
        if (successfullyAdded) {
            handleClose();
        }
    }, [handleClose, successfullyAdded]);

    if (!movie || !frontUser) {
        return null;
    }

    return (
        <ModalDialog isOpen={!!isOpen} onEscKeyPress={handleClose} onOutsideClick={handleClose}>
            <ModalDialogHeader className="AddReviewModal-Header" onClose={handleClose} t={t}>
                {t('your_review_of', { movie: movie.title })}
            </ModalDialogHeader>
            <ModalDialogBody>
                <AddReviewForm movie={movie} t={t} frontUser={frontUser} reviewToEdit={reviewToEdit} />
            </ModalDialogBody>
        </ModalDialog>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    successfullyAdded: state.reviews.successfullyAdded,
    frontUser: state.profile.data,
    movie: state.movie.data,
    reviewToEdit: state.reviews.reviewToEdit,
});
export const AddReviewModal = connect(mapStateToProps)(_AddReviewModal) as FunctionComponent<OwnProps>;
