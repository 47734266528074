import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './LegalCountriesActions';
import { LegalCountriesActionType } from './LegalCountriesActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { LegalCountry } from '../../domain/legal';

function* fetchLegalCountries(): SagaIterator {
    yield call(callApi, {
        endpoint: { url: `legal-countries`, params: { size: 1000 } },
        onSuccess: (
            res: AxiosResponse<EmbeddedEntities<'legalCountries', LegalCountry>>
        ): actions.LegalCountriesAction =>
            actions.createFetchLegalCountriesSuccessAction(getEmbeddedEntities(res.data, 'legalCountries')),
        onError: actions.createFetchLegalCountriesErrorAction,
    });
}

export default function* centresSaga(): SagaIterator {
    yield takeLatest(LegalCountriesActionType.FETCH, fetchLegalCountries);
}
