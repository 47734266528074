import React, { FunctionComponent, useMemo, ReactEventHandler } from 'react';
import { WithTranslation } from 'next-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import last from 'lodash/last';
import find from 'lodash/find';
import isFunction from 'lodash/isFunction';

import { createFetchMovieResourcesAction, MovieAction, MovieState } from '../../../modules/movie';
import { FrontUser } from '../../../domain/user';
import { AppState } from '../../../store/reducers';
import { AssetType, Movie, AssetCategory } from '../../../domain/work';
import { useToggleState } from '../../../hooks';
import { getMovieTrailerUrlForPlayer } from '../../../domain/utils/MovieUtils';
import { uuid } from '../../../domain/core';
import { Button } from '../A11y/Button';

import PlayerModal from './PlayerModal';

interface StateProps {
    movieState: MovieState;
    userInfo?: FrontUser;
}

interface DispatchProps {
    /**
     * Action to fetch the movie resources (containing the video to be played)
     * if necessary.
     */
    onMovieResourcesRequested: (movieId: uuid) => MovieAction;
}

interface OwnProps {
    /**
     * The movie to be played.
     */
    movie: Movie;
    /**
     * If passed as true, the trailer from movie.trailer
     * will be played.
     */
    playTrailer?: boolean;
    className?: string;

    tooltip?: string;
    text?: JSX.Element | string;
    onClick?: () => void;
    watchWithoutOrder?: boolean;

    /**
     * Optional callback called when the button is hovered or
     * gets focused.
     */
    onMouseOverOrFocus?: ReactEventHandler<HTMLButtonElement>;

    /**
     * Optional callback called when the button is not hovered
     * anymore or looses focus.
     */
    onMouseOutOrBlur?: ReactEventHandler<HTMLButtonElement>;
}

export type PlaybackButtonProps = StateProps & DispatchProps & OwnProps & WithTranslation;

export const APPLICATION_DOMAIN: string = process.env.APPLICATION_DOMAIN || 'ifcinemapp';
export const USERNAME_PREFIX: string = process.env.USERNAME_PREFIX || 'ifcinemapp_';

export const ifcinemaPlayerBaseConf: Omit<ArteVodPlayerConfig, 'username' | 'elementId' | 'contentId'> = {
    applicationDomain: APPLICATION_DOMAIN,
    doNotShowPLay: true,
    licenceAdditionalParam: {
        orderLineUuid: '83081f78-f823-431b-8e67-ce7d09c15448',
    },
    manifestBaseURL: 'https://ifcinemastreams.lab.arte.tv/ifcinema/',
    contentBaseURL: 'https://ifcinemastorage.lab.arte.tv/',
};

export function removeMp4OrWebmOrF4v(videoFileUrl: string): string {
    if (videoFileUrl.indexOf('.mp4') > 0) {
        videoFileUrl = videoFileUrl.replace('.mp4', '');
    }
    if (videoFileUrl.indexOf('.webm') > 0) {
        videoFileUrl = videoFileUrl.replace('.webm', '');
    }
    if (videoFileUrl.indexOf('.f4v') > 0) {
        videoFileUrl = videoFileUrl.replace('.f4v', '');
    }
    return videoFileUrl;
}

export const _PlaybackButton: FunctionComponent<PlaybackButtonProps> = ({
    playTrailer,
    movieState,
    className,
    t,
    i18n,
    tReady,
    userInfo,
    onMovieResourcesRequested,
    movie,
    text,
    tooltip,
    onClick,
    watchWithoutOrder = false,
    ...rest
}: PlaybackButtonProps): JSX.Element | null => {
    const [isPlayerModalOpen, togglePlayerModal] = useToggleState(false);

    const playerConfiguration: Omit<ArteVodPlayerConfig, 'elementId'> | null = useMemo((): Omit<
        ArteVodPlayerConfig,
        'elementId'
    > | null => {
        // If playTrailer is true, simply directly return the configuration
        // for the trailer movie
        if (playTrailer) {
            const trailerUrl = getMovieTrailerUrlForPlayer(movie);
            if (!trailerUrl) {
                return null;
            }

            return {
                applicationDomain: APPLICATION_DOMAIN, // not sure if necessary for trailers...
                forcedMedia: removeMp4OrWebmOrF4v(trailerUrl),
                username: 'educarte_Du8BwsFPuswDddhNjWx6A_0', // not sure if necessary for trailers...
                // The rest is not necessary for trailers, but the index.d.ts file requires it... :
                contentId: '',
                contentBaseURL: '',
                manifestBaseURL: '',
            };
        }

        const { data: movieStateData } = movieState;
        // No movie data available (maybe not yet fetched) or not connected => return null
        // FIXME: Is that the proper test w.r.t. authentication ???
        if (!movieStateData || !userInfo) {
            return null;
        }

        // If the id of the already fetched movie in the store is !== from movie.id,
        // the configuration is outdated => return null
        const idOfMovieInState: uuid | undefined = movieStateData.id;
        if (movie.id !== idOfMovieInState) {
            return null;
        }

        // If the video resource could not be found => return null
        const videoResource = find(movieStateData.resources, {
            type: AssetType.VIDEO,
            category: AssetCategory.TECHNICAL_VIDEO,
        });
        if (!videoResource) {
            return null;
        }

        const contentId = removeMp4OrWebmOrF4v(last(videoResource.asset.files[0].url.split('/'))!);
        const username = USERNAME_PREFIX + (userInfo.email ? userInfo.email.split('@')[0] : 'unknow_user');
        const licenceAdditionalParam: Record<string, string> = watchWithoutOrder
            ? { frontUserUuid: userInfo.id, movieUuid: movie.id }
            : movieStateData.licenseAdditionalParam || {};

        return {
            ...ifcinemaPlayerBaseConf,
            contentId,
            username,
            licenceAdditionalParam,
        };
    }, [movie, movieState, playTrailer, userInfo, watchWithoutOrder]);

    const areRequiredResourcesFetched = useMemo((): boolean => {
        if (playTrailer) {
            return true;
        }
        if (playerConfiguration && playerConfiguration.licenceAdditionalParam) {
            const { orderLineUuid, frontUserUuid, movieUuid } = playerConfiguration.licenceAdditionalParam;

            if (orderLineUuid !== undefined || (frontUserUuid !== undefined && movieUuid !== undefined)) {
                return true;
            }
        }

        return false;
    }, [playTrailer, playerConfiguration]);

    return (
        <>
            <Button
                disabled={isPlayerModalOpen}
                text={text || t('orders:streamingPlayback')}
                tooltip={tooltip}
                className={className}
                onClick={(): void => {
                    if (isFunction(onClick)) {
                        onClick();
                    }

                    // Fetch resources if necessary :
                    if (!areRequiredResourcesFetched) {
                        onMovieResourcesRequested(movie.id);
                    }
                    // In any case, toggle the modal.
                    togglePlayerModal();
                }}
                {...rest}
            ></Button>
            <PlayerModal
                isLoading={movieState.isLoading}
                error={movieState.error}
                configuration={playerConfiguration}
                isOpen={isPlayerModalOpen && areRequiredResourcesFetched}
                onClose={togglePlayerModal}
                t={t}
                tReady={tReady}
                i18n={i18n}
            />
        </>
    );
};

function mapStateToProps(appState: AppState): StateProps {
    return {
        movieState: appState.movie,
        userInfo: appState.profile.data,
    };
}

const mapDispatchToProps: DispatchProps = {
    onMovieResourcesRequested: createFetchMovieResourcesAction,
};

export const PlaybackButton = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('order')
)(_PlaybackButton) as FunctionComponent<OwnProps>;
