import { takeLatest, call } from 'redux-saga/effects';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { Catalog } from '../../domain/editorial';
import { HttpStatusCode } from '../../domain/HttpStatusCode';
import { CatalogAction, createFetchCatalogErrorAction, createFetchCatalogSuccessAction } from './CatalogActions';
import { CatalogActionType } from './CatalogActionType';

function* fetchCatalog(action: CatalogAction): SagaIterator {
    yield call(callApi, {
        endpoint: `movie-catalogs?q_code=${action.code}`,
        onSuccess: (res: AxiosResponse<EmbeddedEntities<'movieCatalogs', Catalog>>): CatalogAction => {
            const catalogs = getEmbeddedEntities(res.data, 'movieCatalogs');
            if (catalogs.length === 0) {
                return createFetchCatalogErrorAction({
                    status: HttpStatusCode.NOT_FOUND,
                    statusText: 'Not found',
                    description: 'Contents not found',
                });
            }
            return createFetchCatalogSuccessAction(catalogs[0]);
        },
        onError: createFetchCatalogErrorAction,
    });
}

export default function* CatalogSaga(): SagaIterator {
    yield takeLatest(CatalogActionType.FETCH_CATALOG, fetchCatalog);
}
