export function getCookie(name: string, cookieString: string): string | null {
    const v = cookieString.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export function setCookie(name: string, value: string, days: number): void {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + '=' + value + ';SameSite=Lax;Secure;path=/;expires=' + d.toUTCString();
}

export function deleteCookie(name: string): void {
    setCookie(name, '', -1);
}
