import { StaticPageActionType } from './StaticPageActionType';
import { StaticPage } from '../../domain/editorial';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export interface StaticPageAction extends FetchAction<StaticPageActionType, StaticPage> {
    code?: string;
}

export function createFetchStaticPageAction(code: string): StaticPageAction {
    return { type: StaticPageActionType.FETCH_STATIC_PAGE, code };
}

export function createFetchStaticPageSuccessAction(StaticPage: StaticPage): StaticPageAction {
    return {
        type: StaticPageActionType.FETCH_STATIC_PAGE_SUCCESS,
        payload: StaticPage,
    };
}

export function createFetchStaticPageErrorAction(error: RequestError): StaticPageAction {
    return {
        type: StaticPageActionType.FETCH_STATIC_PAGE_ERROR,
        payload: error,
    };
}
