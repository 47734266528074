import { CatalogActionType } from './CatalogActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { Catalog, CatalogType } from '../../domain/editorial';

export interface CatalogAction extends FetchAction<CatalogActionType, Catalog> {
    code?: string;
}

export function createFetchCatalogAction(code: CatalogType): CatalogAction {
    return { type: CatalogActionType.FETCH_CATALOG, code };
}

export function createFetchCatalogSuccessAction(Catalog: Catalog): CatalogAction {
    return {
        type: CatalogActionType.FETCH_CATALOG_SUCCESS,
        payload: Catalog,
    };
}

export function createFetchCatalogErrorAction(error: RequestError): CatalogAction {
    return {
        type: CatalogActionType.FETCH_CATALOG_ERROR,
        payload: error,
    };
}
