import { takeLatest, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';

import { callApi } from '../../sagas/APICallSaga';

import { EducationActionType } from './EducationActionType';
import {
    EducationAction,
    createFetchEducationSuccessAction,
    createFetchEducationErrorAction,
} from './EducationActions';
import { EducationPage } from '../../domain/editorial';
import { AppState } from '../../store/reducers';
import { MovieAsset } from '../../domain/work';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { MovieStateData } from '../movie';

function* fetchEducation(): SagaIterator {
    yield call(callApi, {
        endpoint: [
            'educational-homepage',
            'search-movies?q_educational_file=french',
            `search-movies?q_educational_file=cinema`,
        ],
        onSuccess: (
            homepage: AxiosResponse<EducationPage>,
            searchFrenchMovies: AxiosResponse,
            searchCinemaMovies: AxiosResponse
        ): EducationAction => {
            return createFetchEducationSuccessAction({
                ...homepage.data,
                countFrench: parseInt(searchFrenchMovies.headers['x-total-count']),
                countCinema: parseInt(searchCinemaMovies.headers['x-total-count']),
            });
        },
        onError: createFetchEducationErrorAction,
    });
}

function* fetchResourcesAndReturnEducationPage(): SagaIterator {
    const educationPage: EducationPage = yield select(
        (state: AppState): EducationPage | undefined => state.education.data
    );

    let allMovies: MovieStateData[] = [];
    if (educationPage.cinemaLayer) {
        allMovies = flatten([...allMovies, educationPage.cinemaLayer.movies]);
    }
    if (educationPage.frenchLayer) {
        allMovies = flatten([...allMovies, educationPage.frenchLayer.movies]);
    }

    const endpoints = allMovies.map((movieStateData): string => `movies/${movieStateData.id}/resources?size=1000`);

    yield call(callApi, {
        endpoint: endpoints,
        onSuccess: (...resourcesCall: AxiosResponse<EmbeddedEntities<'resources', MovieAsset>>[]): EducationAction => {
            const allResources = flatMap(
                resourcesCall.map((resourceCall): MovieAsset[] => getEmbeddedEntities(resourceCall.data, 'resources'))
            );

            let newFrenchMovies: MovieStateData[] = [];
            if (educationPage.frenchLayer) {
                newFrenchMovies = educationPage.frenchLayer.movies.map(
                    (movie): MovieStateData => {
                        return {
                            ...movie,
                            resources: allResources.filter((resource): boolean => resource.movie.id === movie.id),
                        };
                    }
                );
            }

            let newCinemaMovies: MovieStateData[] = [];
            if (educationPage.cinemaLayer) {
                newCinemaMovies = educationPage.cinemaLayer.movies.map(
                    (movie): MovieStateData => {
                        return {
                            ...movie,
                            resources: allResources.filter((resource): boolean => resource.movie.id === movie.id),
                        };
                    }
                );
            }

            return createFetchEducationSuccessAction({
                ...educationPage,
                cinemaLayer: { movies: newCinemaMovies },
                frenchLayer: { movies: newFrenchMovies },
                hasFetchedRessources: true,
            });
        },
        onError: createFetchEducationErrorAction,
    });
}

export default function* EducationSaga(): SagaIterator {
    yield takeLatest(EducationActionType.FETCH_EDUCATION, fetchEducation);
    yield takeLatest(EducationActionType.FETCH_MOVIE_EDUCATION_WITH_RESOURCES, fetchResourcesAndReturnEducationPage);
}
