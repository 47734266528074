/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { KeyboardEvent, useState } from 'react';
import { WithT } from 'i18next';
import { Link, Router } from '../../../i18n';
import { Cycle as CycleType } from '../../../domain/editorial';
import { TotalMovies } from '../TotalMovies';
import { StackedMoviePosters } from '../StackedMoviePosters';
import { OffsetButton } from '../Button';
import EventKeys from '../../EventKeys';
import AriaRole from '../../AriaRole';

import './Cycle.scss';
import '../../Home/ThematicCycles/ThematicCycles.scss'; // FIXME

export interface CycleProps extends WithT {
    cycle: CycleType;
    'aria-setsize'?: number;
    'aria-posinset'?: number;
}

export function Cycle({ cycle, t, 'aria-posinset': posinset, 'aria-setsize': setsize }: CycleProps): JSX.Element {
    const cycleUrl = { pathname: '/cycle', query: { id: cycle.id } };
    const [isHovering, setIsHovering] = useState(false);
    return (
        <article
            className="Cycle"
            aria-posinset={posinset}
            aria-setsize={setsize}
            onClick={(): Promise<boolean> => Router.push(cycleUrl)}
            onKeyDown={(event: KeyboardEvent): void => {
                if (event.key === EventKeys.ENTER) {
                    Router.push(cycleUrl);
                }
            }}
            role={AriaRole.ARTICLE}
            tabIndex={0}
        >
            <span className="Cycle__Background-Title" aria-hidden="true">
                {cycle.title}
            </span>
            <div
                className="thumbnails-box"
                onMouseOver={(): void => setIsHovering(true)}
                onFocus={(): void => setIsHovering(true)}
                onMouseOut={(): void => setIsHovering(false)}
                onBlur={(): void => setIsHovering(false)}
                role="presentation"
            >
                {!!cycle.works && (
                    <>
                        <TotalMovies total={cycle.works.length} t={t} />
                        <StackedMoviePosters
                            movies={cycle.works}
                            width={{ desktop: 200, mobile: 120 }}
                            height={{ desktop: 267, mobile: 160 }}
                            spacing={{ desktop: 10, mobile: 5 }}
                            limit={{ desktop: 10, mobile: 7 }}
                            t={t}
                            isHovering={isHovering}
                        />
                    </>
                )}
                <h2 className="Cycle__Title">
                    <Link href={cycleUrl}>
                        <a>{cycle.title}</a>
                    </Link>
                </h2>
                <h3 className="Cycle__Subtitle">{cycle.subTitle}</h3>
                <div className="Cycle__Info">
                    <p className="Cycle__Description">{cycle.description}</p>
                    <OffsetButton text={t('discoverCycle')} onClick={(): Promise<boolean> => Router.push(cycleUrl)} />
                </div>
            </div>
        </article>
    );
}
