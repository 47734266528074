import { FetchAction } from '../../Fetch';
import { RequestError } from '../../../sagas/APICallSaga';
import { CentreType } from '../../../domain/user';
import { CentreTypesActionType } from './CentreTypesActionType';

export type CentreTypesAction = FetchAction<CentreTypesActionType, CentreType[]>;

export function createFetchCentreTypesAction(): CentreTypesAction {
    return {
        type: CentreTypesActionType.FETCH,
    };
}
export function createFetchCentreTypesSuccessAction(centreTypes: CentreType[]): CentreTypesAction {
    return {
        type: CentreTypesActionType.FETCH_SUCCESS,
        payload: centreTypes,
    };
}
export function createFetchCentreTypesErrorAction(error: RequestError): CentreTypesAction {
    return {
        type: CentreTypesActionType.FETCH_ERROR,
        payload: error,
    };
}
