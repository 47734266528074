import { Order, OrderLine, ProductType } from '../../domain/commerce';
import { AnyAction } from 'redux';
import { OrdersType } from './OrdersActionType';
import { RequestError } from '../../sagas/APICallSaga';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import { FetchState } from '../Fetch';

export type OrdersState = FetchState<Order[]>;

export interface OrderLinesState {
    isLoading: boolean;
    error?: RequestError;
    physicalOrderLines?: OrderLine[];
    digitalOrderLines?: OrderLine[];
}

const ordersInitialState: OrdersState = {
    isLoading: false,
};

const orderLinesInitialState: OrderLinesState = {
    isLoading: false,
};

export const ordersReducer = (state: OrdersState = ordersInitialState, action: AnyAction): OrdersState => {
    switch (action.type) {
        case OrdersType.FETCH_ORDERS:
            return {
                ...state,
                isLoading: true,
            };

        case OrdersType.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as Order[],
            };

        case OrdersType.FETCH_ORDERS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};

export const orderLinesReducer = (
    state: OrderLinesState = orderLinesInitialState,
    action: AnyAction
): OrderLinesState => {
    switch (action.type) {
        case OrdersType.FETCH_ORDER_LINES:
            return {
                ...state,
                isLoading: true,
            };

        case OrdersType.FETCH_ORDER_LINES_SUCCESS:
            const fetchedOrderLines = compact(action.payload as OrderLine[]);

            return {
                ...state,
                isLoading: false,
                error: undefined,
                digitalOrderLines: filter(
                    fetchedOrderLines,
                    (item: OrderLine): boolean => item.videoProduct.type.productType === ProductType.DIGITAL
                ),
                physicalOrderLines: filter(
                    fetchedOrderLines,
                    (item: OrderLine): boolean => item.videoProduct.type.productType === ProductType.PHYSICAL
                ),
            };

        case OrdersType.FETCH_ORDER_LINES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload as RequestError,
                digitalOrderLines: undefined,
                physicalOrderLines: undefined,
            };

        default:
            return state;
    }
};
