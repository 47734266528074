import { EducationActionType } from './EducationActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { EducationPage } from '../../domain/editorial';

export type EducationAction = FetchAction<EducationActionType, EducationPage>;

export function createFetchEducationAction(): EducationAction {
    return { type: EducationActionType.FETCH_EDUCATION };
}

export function createFetchEducationSuccessAction(education: EducationPage): EducationAction {
    return {
        type: EducationActionType.FETCH_EDUCATION_SUCCESS,
        payload: education,
    };
}

export function createFetchResourcesAndReturnEducationPageAction(): EducationAction {
    return {
        type: EducationActionType.FETCH_MOVIE_EDUCATION_WITH_RESOURCES,
    };
}

export function createFetchEducationErrorAction(error: RequestError): EducationAction {
    return {
        type: EducationActionType.FETCH_EDUCATION_ERROR,
        payload: error,
    };
}
