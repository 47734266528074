import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './CentresActions';
import { CentresActionType } from './CentresActionType';
import { callApi } from '../../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { EmbeddedEntities, getEmbeddedEntities } from '../../../domain/core';
import { Centre } from '../../../domain/user';

function* fetchCentres(action: actions.CentresAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `centres`,
            // eslint-disable-next-line @typescript-eslint/camelcase
            params: { q_profile_type: action.profileType, q_type_code: action.code, q_name: action.query },
        },
        onSuccess: (res: AxiosResponse<EmbeddedEntities<'centres', Centre>>): actions.CentresAction =>
            actions.createFetchCentresSuccessAction(getEmbeddedEntities(res.data, 'centres')),
        onError: actions.createFetchCentresErrorAction,
    });
}

export default function* centresSaga(): SagaIterator {
    yield takeLatest(CentresActionType.FETCH, fetchCentres);
}
