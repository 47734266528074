import React, { PropsWithChildren, useEffect, FunctionComponent, useRef } from 'react';
import { LanguageAndAccessibilityDropdowns } from './LanguageAndAccessibilityDropdowns';
import { Footer } from './Footer/Footer';
import classnames from 'clsx';
import { ConnectionModal } from './ConnectionModal';
import {
    UIState,
    UIAction,
    createToggleConnectionModalAction,
    createToggleSearchModalAction,
    createToggleOrderModalAction,
    createToggleSuccessfullyAddedToCartModalAction,
    createToggleAlreadyInCartErrorModalAction,
    createToggleCreditsRequestModalAction,
    createToggleReactivateAccountModalAction,
    createToggleOrderedRecentlyErrorModalAction,
    createToggleAddReviewModalAction,
    createToggleGenericErrorModalAction,
    createToggleNotEnoughCreditsErrorModalAction,
    createToggleDigitalOrderModalAction,
} from '../modules/UI';
import { SearchModal } from './Search/SearchModal';
import { useWindowHeight } from '../hooks';
import { connect } from 'react-redux';
import { AppState } from '../store/reducers';
import { ActionCreator, compose } from 'redux';
import { OrderModal } from './Order';
import { MiniCart } from './Cart/MiniCart';
import { SuccessfullyAddedToCartModal } from './Cart/SuccessfullyAddedToCartModal';
import { AlreadyInCartErrorModal } from './Cart/AlreadyInCartErrorModal';
import { NotEnoughCreditsErrorModal } from './Cart/NotEnoughCreditsErrorModal';
import { CreditsRequestModal } from './Credits';
import { ReactivateAccountModal } from './ReactivateAccount/ReactivateAccountModal';
import { withTranslation } from '../i18n';
import { WithTranslation } from 'next-i18next';
import { OrderedRecentlyErrorModal } from './Cart/OrderedRecentlyErrorModal';
import { AddReviewModal } from './MovieBrochure/MovieReviews/AddReviewModal';
import { GenericErrorModal } from './GenericErrorModal';
import Header from './Header/Header';
import { DigitalOrderModal } from './MovieBrochure/DigitalOrderModal/DigitalOrderModal';

type StateProps = UIState;
interface DispatchProps {
    toggleSearchModal: ActionCreator<UIAction>;
    toggleConnectionModal: ActionCreator<UIAction>;
    toggleOrderModal: ActionCreator<UIAction>;
    toggleAddedToCartModal: ActionCreator<UIAction>;
    toggleAlreadyInCartErrorModal: ActionCreator<UIAction>;
    toggleOrderedRecentlyErrorModal: ActionCreator<UIAction>;
    toggleNotEnoughCreditsErrorModal: ActionCreator<UIAction>;
    toggleCreditsRequestModal: ActionCreator<UIAction>;
    toggleReactivateAccountModal: ActionCreator<UIAction>;
    toggleAddReviewModal: ActionCreator<UIAction>;
    toggleGenericErrorModal: ActionCreator<UIAction>;
    toggleDigitalOrderModal: ActionCreator<UIAction>;
}

type LayoutProps = PropsWithChildren<StateProps & DispatchProps> & WithTranslation;

const Layout = ({
    animations,
    contrasts,
    searchModalIsOpen,
    connectionModalIsOpen,
    orderModalIsOpen,
    addedToCartModalIsOpen,
    alreadyInCartErrorModalIsOpen,
    orderedRecentlyErrorModalIsOpen,
    notEnoughCreditErrorModalIsOpen,
    creditsRequestModalIsOpen,
    reactivateAccountModalIsOpen,
    addReviewModalIsOpen,
    genericErrorModalIsOpen,
    digitalOrderModalIsOpen,
    toggleConnectionModal,
    toggleSearchModal,
    toggleOrderModal,
    toggleAddedToCartModal,
    toggleAlreadyInCartErrorModal,
    toggleOrderedRecentlyErrorModal,
    toggleNotEnoughCreditsErrorModal,
    toggleCreditsRequestModal,
    toggleReactivateAccountModal,
    toggleAddReviewModal,
    toggleGenericErrorModal,
    toggleDigitalOrderModal,
    children,
    t,
}: LayoutProps): JSX.Element => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const windowHeight = useWindowHeight();
    useEffect((): void => {
        if (windowHeight) {
            const vh = windowHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }, [windowHeight]);

    /**
     * The following is a React antipattern but due to a weird react bug the DOM
     * is not updated when I set the classes using `className={classes}`
     */
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect((): void => {
        const classes = classnames('App', 'js-enabled', {
            'acc-contrast-on': contrasts,
            'acc-no-anim': !animations,
        });
        if (containerRef.current) {
            containerRef.current.className = classes;
        }
    }, [contrasts, animations]);

    return (
        <div className="App" ref={containerRef}>
            <SearchModal isDisplayed={searchModalIsOpen} onClose={toggleSearchModal} />
            <ConnectionModal onClose={toggleConnectionModal} isOpen={connectionModalIsOpen} />
            <OrderModal onClose={toggleOrderModal} isOpen={orderModalIsOpen} className="OrderModal" />
            <SuccessfullyAddedToCartModal onClose={toggleAddedToCartModal} isOpen={addedToCartModalIsOpen} />
            <AlreadyInCartErrorModal onClose={toggleAlreadyInCartErrorModal} isOpen={alreadyInCartErrorModalIsOpen} />
            <OrderedRecentlyErrorModal
                onClose={toggleOrderedRecentlyErrorModal}
                isOpen={orderedRecentlyErrorModalIsOpen}
            />
            <NotEnoughCreditsErrorModal
                onClose={toggleNotEnoughCreditsErrorModal}
                isOpen={notEnoughCreditErrorModalIsOpen}
            />
            <CreditsRequestModal onClose={toggleCreditsRequestModal} isOpen={creditsRequestModalIsOpen} />
            <ReactivateAccountModal onClose={toggleReactivateAccountModal} isOpen={reactivateAccountModalIsOpen} />
            <AddReviewModal t={t} isOpen={addReviewModalIsOpen} onClose={toggleAddReviewModal} />
            <GenericErrorModal t={t} isOpen={genericErrorModalIsOpen} onClose={toggleGenericErrorModal} />
            <DigitalOrderModal t={t} isOpen={digitalOrderModalIsOpen} onClose={toggleDigitalOrderModal} />
            <MiniCart />
            <div className="avoid">
                <a href="#content">{t('common:skipToContent')}</a>
            </div>
            <LanguageAndAccessibilityDropdowns />
            <Header openSearchModal={toggleSearchModal} />
            <main role="main" className="Content" id="content">
                {children}
            </main>
            <Footer />
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => state.UI;

const mapDispatchToProps: DispatchProps = {
    toggleConnectionModal: createToggleConnectionModalAction,
    toggleSearchModal: createToggleSearchModalAction,
    toggleOrderModal: createToggleOrderModalAction,
    toggleAddedToCartModal: createToggleSuccessfullyAddedToCartModalAction,
    toggleAlreadyInCartErrorModal: createToggleAlreadyInCartErrorModalAction,
    toggleOrderedRecentlyErrorModal: createToggleOrderedRecentlyErrorModalAction,
    toggleNotEnoughCreditsErrorModal: createToggleNotEnoughCreditsErrorModalAction,
    toggleCreditsRequestModal: createToggleCreditsRequestModalAction,
    toggleReactivateAccountModal: createToggleReactivateAccountModalAction,
    toggleAddReviewModal: createToggleAddReviewModalAction,
    toggleGenericErrorModal: createToggleGenericErrorModalAction,
    toggleDigitalOrderModal: createToggleDigitalOrderModalAction,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('common')
)(Layout) as FunctionComponent;
