import { CollectionActionType } from './CollectionActionType';
import { Collection } from '../../domain/editorial';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { uuid } from '../../domain/core';

export interface CollectionAction extends FetchAction<CollectionActionType, Collection> {
    id?: uuid;
}

export function createFetchCollectionAction(id: uuid): CollectionAction {
    return { type: CollectionActionType.FETCH_COLLECTION, id };
}

export function createFetchCollectionSuccessAction(collection: Collection): CollectionAction {
    return {
        type: CollectionActionType.FETCH_COLLECTION_SUCCESS,
        payload: collection,
    };
}

export function createFetchCollectionErrorAction(error: RequestError): CollectionAction {
    return {
        type: CollectionActionType.FETCH_COLLECTION_ERROR,
        payload: error,
    };
}
