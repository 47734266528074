import isNaN from 'lodash/isNaN';

const units = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
const thresh = 1000;

export function humanFileSize(bytes: number | undefined, locale: string): string | null {
    if (bytes == null || isNaN(bytes)) {
        return null;
    }
    let u = 0;
    while (Math.abs(bytes) >= thresh && u < units.length - 1) {
        bytes /= thresh;
        ++u;
    }
    return (Math.round(bytes * 10) / 10).toLocaleString(locale) + ' ' + units[u] + (locale === 'fr' ? 'o' : 'B');
}
