import { CentreTypesAction } from './CentreTypesActions';
import { RequestError } from '../../../sagas/APICallSaga';
import { CentreType } from '../../../domain/user';
import { CentreTypesState } from '../UserFormDataStateReducer';
import { CentreTypesActionType } from './CentreTypesActionType';

const initialCentreTypesState: CentreTypesState = {
    isLoading: false,
};

export const centreTypesReducer = (
    state: CentreTypesState = initialCentreTypesState,
    action: CentreTypesAction
): CentreTypesState => {
    switch (action.type) {
        case CentreTypesActionType.FETCH:
            return {
                ...state,
                isLoading: true,
            };
        case CentreTypesActionType.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as CentreType[],
            };
        case CentreTypesActionType.FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };
        default:
            return state;
    }
};
