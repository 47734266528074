export enum StreamingEventActionType {
    FETCH_STREAMING_EVENT = 'STREAMING_EVENT/FETCH',
    FETCH_STREAMING_EVENT_BY_SLUG = 'STREAMING_EVENT/FETCH_BY_SLUG',
    FETCH_STREAMING_EVENT_SUCCESS = 'STREAMING_EVENT/FETCH_SUCCESS',
    FETCH_STREAMING_EVENT_ERROR = 'STREAMING_EVENT/FETCH_ERROR',

    FETCH_STREAMING_EVENT_DATA = 'STREAMING_EVENT/FETCH_STREAMING_EVENT_DATA',
    FETCH_MOVIES_PRODUCTS_SUCCESS = 'STREAMING_EVENT/FETCH_MOVIES_PRODUCTS_SUCCESS',
    FETCH_MOVIES_CONTRACTS_SUCCESS = 'STREAMING_EVENT/FETCH_MOVIES_CONTRACTS_SUCCESS',
    FETCH_USER_COUNTRY_SUCCESS = 'STREAMING_EVENT/FETCH_USER_COUNTRY_SUCCESS',
}
