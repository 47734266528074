import { RequestError } from '../../sagas/APICallSaga';
import { FetchAction } from '../Fetch';
import { LegalCountriesActionType } from './LegalCountriesActionType';
import { LegalCountry } from '../../domain/legal';

export type LegalCountriesAction = FetchAction<LegalCountriesActionType, LegalCountry[]>;

export function createFetchLegalCountriesAction(): LegalCountriesAction {
    return {
        type: LegalCountriesActionType.FETCH,
    };
}
export function createFetchLegalCountriesSuccessAction(legalCountries: LegalCountry[]): LegalCountriesAction {
    return {
        type: LegalCountriesActionType.FETCH_SUCCESS,
        payload: legalCountries,
    };
}
export function createFetchLegalCountriesErrorAction(error: RequestError): LegalCountriesAction {
    return {
        type: LegalCountriesActionType.FETCH_ERROR,
        payload: error,
    };
}
