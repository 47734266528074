import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import Head from 'next/head';
import noop from 'lodash/noop';
import once from 'lodash/once';
import { WithTranslation } from 'next-i18next';

import {
    ModalDialog,
    ModalDialogHeader,
    ModalDialogBody,
    ModalDialogProps,
    ModalDialogHeaderProps,
} from '../../common/ModalDialog';
import { MovieState } from '../../../modules/movie';
import { RequestError } from '../../../sagas/APICallSaga';
import { Loader } from '../../Loader';

import './Player.scss';

type OwnProps = Pick<ModalDialogProps, 'isOpen'> &
    Pick<ModalDialogHeaderProps, 'onClose'> &
    Pick<MovieState, 'isLoading' | 'error'> & {
        configuration: Omit<ArteVodPlayerConfig, 'elementId'> | null;
    };

export type PlayerModalProps = OwnProps & WithTranslation;

const arteVodPlayerTargetElementId = 'video-element';

function renderPlayerModalBodyContent(
    isLoading: boolean,
    error: RequestError | undefined,
    conf: Omit<ArteVodPlayerConfig, 'elementId'> | null
): JSX.Element {
    if (error) {
        // FIXME: We could do better here with i18n and
        // FIXME: error message formatting
        return <span>{error.statusText || 'error'}</span>;
    }

    if (conf && !isLoading && !error) {
        return <div id={arteVodPlayerTargetElementId}></div>;
    }

    return <Loader />;
}

const setArteVodPlayerLoggingLevel = once((): void => {
    // ArteVodPlayer.setLoggingLevel('TRACE');
});

const PlayerModal: FunctionComponent<PlayerModalProps> = ({
    isOpen,
    onClose,
    configuration,
    isLoading,
    error,
    t,
    i18n,
}: PlayerModalProps): JSX.Element => {
    const player = useRef<any | null>(null);

    // Inject player after mount and props update only in
    // valid cases :
    useEffect((): void => {
        if (!isOpen || !configuration || isLoading || error) {
            return;
        }

        const completeConfiguration: ArteVodPlayerConfig = {
            ...configuration,
            elementId: arteVodPlayerTargetElementId,
            statsServiceURL: process.env.STATS_SERVICE_URL || 'https://preprod-statsservices.lab.arte.tv/stats',
            language: i18n.language as TranslatedLocale,
            originalAudioVersion: undefined,
            audioVersionsWithPartialSubs: [],
            getVttFilenameSuffix: (subtitleLanguageCode: string): string => {
                return `-${subtitleLanguageCode.toUpperCase()}`;
            },
        };

        if (player.current != null) {
            // FIXME: Why do we have two separate calls to destroy the player?
            player.current.destroy();
            player.current = null;
        }

        setArteVodPlayerLoggingLevel();
        player.current = new ArteVodPlayer(completeConfiguration);
    }, [configuration, error, i18n.language, isLoading, isOpen]);

    const handleModalClosure = useCallback<() => void>((): void => {
        if (player.current != null) {
            // FIXME: Why do we have two separate calls to destroy the player?
            player.current.destroy();
            player.current = null;
        }

        onClose && onClose();
    }, [onClose]);

    return (
        <>
            <Head>
                <link rel="stylesheet" href="/static/lib/player/artevodplayer-3.0.2.css" />
                <script src="/static/lib/player/artevodplayer-3.0.2.js" />
            </Head>

            <ModalDialog
                isOpen={!!isOpen}
                onEscKeyPress={handleModalClosure}
                onOutsideClick={noop}
                className="PlayerModal"
            >
                <ModalDialogHeader
                    className="PlayerModal-Header"
                    closeButtonClassName="PlayerModal-Header-CloseButton"
                    onClose={handleModalClosure}
                    t={t}
                >
                    <></>
                </ModalDialogHeader>
                <ModalDialogBody className="PlayerModal-Body">
                    {renderPlayerModalBodyContent(isLoading, error, configuration)}
                </ModalDialogBody>
            </ModalDialog>
        </>
    );
};

PlayerModal.defaultProps = {
    onClose: noop,
};

export default PlayerModal;
