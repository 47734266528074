import React from 'react';
import { Menu, Panel } from 'react-bulma-components';
import { Animate } from 'react-show';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'i18next';
import { Link } from '../common/A11y/Link';

library.add(faArrowLeft);

export interface PanelHelpProps {
    t: TFunction;
}

export interface PanelHelpState {
    display: 'none' | 'block';
    openpanelhelp: boolean;
}

export default class PanelHelp extends React.Component<PanelHelpProps, PanelHelpState> {
    public constructor(props: PanelHelpProps) {
        super(props);
        this.state = {
            display: 'none',
            openpanelhelp: false,
        };
        this.togglePanelHelp = this.togglePanelHelp.bind(this);
    }

    public render(): JSX.Element {
        const { openpanelhelp } = this.state;
        const { t } = this.props;
        return (
            <Panel.Header>
                <button className="button" onClick={this.togglePanelHelp}>
                    {t('help')}
                </button>
                <Animate
                    style={{ display: this.state.display }}
                    className="panel panel-secondary panel-help"
                    show={openpanelhelp}
                    transitionOnMount={true}
                    start={{
                        transform: 'translate3d(100%, 0, 0)',
                    }}
                >
                    <Panel.Block>
                        <Panel.Header>
                            <button onClick={this.togglePanelHelp} className="back button">
                                <FontAwesomeIcon aria-hidden="true" icon={faArrowLeft} />
                                <span className="visually-hidden">{t('backToMenu')}</span>
                            </button>
                            <span>{t('help')}</span>
                        </Panel.Header>
                        <Menu renderAs="nav">
                            <Menu.List className="second-level">
                                <Menu.List.Item renderAs="div">
                                    <Link href="/faq">{t('faq')}</Link>
                                </Menu.List.Item>
                                <Menu.List.Item renderAs="div">
                                    <Link href="/contact">{t('common:contactUsInfinitive')}</Link>
                                </Menu.List.Item>
                            </Menu.List>
                        </Menu>
                    </Panel.Block>
                </Animate>
            </Panel.Header>
        );
    }

    private togglePanelHelp(): void {
        if (this.state.display === 'none') {
            this.setState({ display: 'block' });
            setTimeout(
                (): void =>
                    this.setState({
                        openpanelhelp: !this.state.openpanelhelp,
                    }),
                10 // something very short
            );
        }
        if (this.state.display === 'block') {
            this.setState({
                openpanelhelp: !this.state.openpanelhelp,
            });
            setTimeout(
                (): void => this.setState({ display: 'none' }),
                350 // same as transition time
            );
        }
    }
}
