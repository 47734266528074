import { FreeMoviesAction } from './FreeMoviesActions';
import { FreeMoviesActionType } from './FreeMoviesActionType';

export interface FreeMoviesState {
    playerModalIsOpen: boolean;
}

const initialState: FreeMoviesState = {
    playerModalIsOpen: false,
};

export const freeMoviesReducer = (state: FreeMoviesState = initialState, action: FreeMoviesAction): FreeMoviesState => {
    switch (action.type) {
        case FreeMoviesActionType.WATCH_REQUEST_SUCCESS:
            return {
                ...state,
                playerModalIsOpen: true,
            };
            break;

        case FreeMoviesActionType.TOGGLE_PLAYER_MODAL:
            return {
                ...state,
                playerModalIsOpen: !state.playerModalIsOpen,
            };
            break;

        default:
            return state;
    }
};
