import { CollectionActionType } from './CollectionActionType';
import { Collection } from '../../domain/editorial';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { CollectionAction } from './CollectionActions';

export type CollectionState = FetchState<Collection>;

export const initialState: CollectionState = {
    isLoading: false,
};

export const collectionReducer = (state: CollectionState = initialState, action: CollectionAction): CollectionState => {
    switch (action.type) {
        case CollectionActionType.FETCH_COLLECTION:
            return {
                ...state,
                isLoading: true,
            };

        case CollectionActionType.FETCH_COLLECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as Collection,
            };

        case CollectionActionType.FETCH_COLLECTION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
