import React, { Fragment, useState, FunctionComponent, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Animate } from 'react-show';
import { AuthState, AuthAction, deauthenticate, LoginStatus } from '../../modules/auth';
import { AppState } from '../../store/reducers';
import { ActionCreator } from 'redux';
import { createToggleConnectionModalAction, UIAction, createToggleMiniCartAction } from '../../modules/UI';
import { WithT } from 'i18next';
import { Navbar } from 'react-bulma-components';
import { Dropdown, DropdownItem } from '../common/TypedBulmaComponents';
import { CartMenuItem } from '../Cart/CartMenuItem';
import { FrontUser, ScreeningEvaluation, ScreeningEvaluationStatus } from '../../domain/user';
import OutsideClickHandler from 'react-outside-click-handler';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Link, Router } from '../../i18n';
import { Button } from '../common/A11y/Button';
import { filter } from 'lodash';
import classNames from 'classnames';
import Breakpoint from '../Breakpoint';

interface StateProps extends Pick<AuthState, 'loginStatus'> {
    pendingScreeningEvaluationsLength: number;
    cartItemsLength: number;
    cartIsReady: boolean;
    userInfo?: FrontUser;
}

interface DispatchProps {
    deauthenticate: ActionCreator<AuthAction>;
    openConnectionModal: ActionCreator<UIAction>;
    toggleMiniCart: ActionCreator<UIAction>;
}

export type UserMenuProps = WithT & StateProps & DispatchProps;

const renderHeaderUserIcon = (): JSX.Element => {
    return (
        <span className="header-icon">
            <svg viewBox="0 0 26 26" aria-hidden="true">
                <path
                    aria-hidden="true"
                    d="M8.5,8 C8.5,5.243 10.743,3 13.5,3 C16.257,3 18.5,5.243 18.5,8 C18.5,10.757 16.257,13 13.5,13 C10.743,13 8.5,10.757 8.5,8 M13.5,17.061 C19.29,17.061 24,21.771 24,27.561 L27,27.561 C27,21.605 23.12,16.549 17.759,14.76 C20.002,13.341 21.5,10.845 21.5,8 C21.5,3.589 17.911,0 13.5,0 C9.089,0 5.5,3.589 5.5,8 C5.5,10.845 6.998,13.341 9.241,14.76 C3.88,16.549 0,21.605 0,27.561 L3,27.561 C3,21.771 7.71,17.061 13.5,17.061"
                />
            </svg>
        </span>
    );
};

/* eslint-disable react-hooks/rules-of-hooks */
const renderUserMenuMobile: FunctionComponent<UserMenuProps> = ({
    t,
    userInfo,
    deauthenticate,
}): JSX.Element | null => {
    if (!userInfo) {
        return null;
    }

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isMenuFullyOpened, setIsMenuFullyOpened] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [display, setDisplay] = useState('none');

    const openMenu = (): void => {
        setDisplay('block');
        setTimeout((): void => {
            setMenuIsOpen(true);
            setOpacity(1);
        }, 10);
    };
    const closeMenu = (): void => {
        if (isMenuFullyOpened) {
            setIsMenuFullyOpened(false);
            setTimeout((): void => setDisplay('none'), 350);
            setOpacity(0);
            setMenuIsOpen(false);
        }
    };
    const handleMenuAnimationFinished = (): void => {
        if (menuIsOpen) {
            setIsMenuFullyOpened(true);
        }
    };
    const createNavigationLink = (key: string): JSX.Element => {
        const href = `/${key}`;
        return (
            <Link href={href}>
                <a href="#" onClick={closeMenu}>
                    {t(key)}
                </a>
            </Link>
        );
    };

    return (
        <>
            <button className="UserMenu-Icon button" onClick={openMenu}>
                {renderHeaderUserIcon()}
            </button>
            <div className="MenuMobile" style={{ opacity: opacity, display: display }}>
                <OutsideClickHandler onOutsideClick={closeMenu}>
                    <Animate
                        className="panel UserMenu-MobilePanel"
                        show={menuIsOpen}
                        preMount={true}
                        transitionOnMount={true}
                        start={{
                            transform: 'translate3d(100%, 0, 0)',
                        }}
                        onFinish={handleMenuAnimationFinished}
                    >
                        <div className="UserMenu-MobilePanel-Header">
                            <span>{userInfo && t('hello', { userFirstName: userInfo.givenName })}</span>
                            <Button
                                icon={faTimes}
                                onClick={closeMenu}
                                type="button"
                                className="UserMenu-MobilePanel-Header-Close"
                                iconClassName="fa-2x"
                            />
                        </div>
                        <ul className="UserMenu-MobilePanel-Content">
                            <li>
                                {createNavigationLink('account')}
                                <hr />
                            </li>
                            <li>
                                {createNavigationLink('profile')}
                                <hr />
                            </li>
                            <li>
                                {createNavigationLink('orders')}
                                <hr />
                            </li>
                            <li>
                                {createNavigationLink('playlists')}
                                <hr />
                            </li>
                            <li>
                                {createNavigationLink('reviews')}
                                <hr />
                            </li>
                            <li>
                                {createNavigationLink('favorites')}
                                <hr />
                            </li>
                            <li>{createNavigationLink('player')}</li>
                        </ul>
                        <div className="UserMenu-MobilePanel-Footer">
                            <hr />
                            <a href="#" onClick={deauthenticate}>
                                {t('logout')}
                            </a>
                        </div>
                    </Animate>
                </OutsideClickHandler>
            </div>
        </>
    );
};

interface PendingScreeningEvaluationsProps {
    count: number;
}

function PendingScreeningEvaluations({ count }: PendingScreeningEvaluationsProps): JSX.Element {
    return (
        <span
            className={classNames('UserMenu-Count', count >= 10 && count < 99 && 'medium', count >= 100 && 'large')}
            id="pendingScreeningCount"
        >
            {count}
        </span>
    );
}

const renderUserLinksDropdown = ({
    t,
    userInfo,
    deauthenticate,
    pendingScreeningEvaluationsLength,
}: UserMenuProps): JSX.Element | null => {
    if (!userInfo) {
        return null;
    }

    const isSmallerThanFullHD: boolean = Breakpoint.getCurrent().isSmallerOrEqualTo(Breakpoint.WIDESCREEN);
    const userName = isSmallerThanFullHD
        ? `${(userInfo.givenName && userInfo.givenName.charAt(0) + '.') || ''} ${userInfo.lastName}`.trim()
        : `${userInfo.givenName || ''} ${userInfo.lastName}`.trim();

    return (
        <Dropdown
            className="UserMenu-UserDropdown"
            hoverable={true}
            onChange={(action: string): void => {
                // hacky but DropdownItem won't accept "onClick"
                if (action === 'logout') {
                    deauthenticate();
                } else {
                    Router.push(`/${action}`);
                }
            }}
            onClick={(event: MouseEvent<HTMLDivElement>): Promise<boolean> | void => {
                if (event.target instanceof HTMLDivElement && pendingScreeningEvaluationsLength > 0) {
                    return Router.push('/screenings');
                }
            }}
        >
            <DropdownItem renderAs="button" value="account" aria-expanded="false">
                <span className="UserMenu-MenuButton">
                    <span className="UserMenu-UserName">{userName.trim() === '' ? 'Menu' : userName}</span>
                    {pendingScreeningEvaluationsLength > 0 && (
                        <PendingScreeningEvaluations count={pendingScreeningEvaluationsLength} />
                    )}
                </span>
            </DropdownItem>
            <DropdownItem renderAs="a" value="account">
                {t('account')}
            </DropdownItem>
            <DropdownItem renderAs="a" value="profile">
                {t('profile')}
            </DropdownItem>
            <DropdownItem renderAs="a" value="orders">
                {t('orders')}
            </DropdownItem>
            <DropdownItem renderAs="a" value="screenings">
                <span className="UserMenu-Screenings">{t('screenings')}</span>
                {pendingScreeningEvaluationsLength > 0 && (
                    <PendingScreeningEvaluations count={pendingScreeningEvaluationsLength} />
                )}
            </DropdownItem>
            <DropdownItem renderAs="a" value="playlists">
                {t('playlists')}
            </DropdownItem>
            <DropdownItem renderAs="a" value="reviews">
                {t('reviews')}
            </DropdownItem>
            <DropdownItem renderAs="a" value="favorites">
                {t('favorites')}
            </DropdownItem>
            <DropdownItem renderAs="a" value="player">
                {t('player')}
            </DropdownItem>
            <DropdownItem renderAs="a" value="logout">
                {t('logout')}
            </DropdownItem>
        </Dropdown>
    );
};

const renderAuthenticatedMenuItems = (props: UserMenuProps): JSX.Element | null => {
    if (props.loginStatus !== LoginStatus.AUTHENTICATED) {
        return null;
    }
    return (
        <>
            <Navbar.Item renderAs="li" className="user-menu-mobile">
                {renderUserMenuMobile(props)}
            </Navbar.Item>
            <Navbar.Item renderAs="li" className="user-menu-desktop">
                {renderUserLinksDropdown(props)}
            </Navbar.Item>
            <Navbar.Item renderAs="li">
                <CartMenuItem {...props} />
            </Navbar.Item>
        </>
    );
};

const _UserMenu = (props: UserMenuProps): JSX.Element => {
    const { openConnectionModal, loginStatus, t } = props;
    return (
        <Fragment>
            {loginStatus !== LoginStatus.AUTHENTICATED && (
                <Navbar.Item renderAs="li">
                    <button className="button btn-connect" onClick={openConnectionModal}>
                        {renderHeaderUserIcon()}
                        <span>{t('common:login2')}</span>
                    </button>
                </Navbar.Item>
            )}
            {renderAuthenticatedMenuItems(props)}
        </Fragment>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    userInfo: state.profile.data,
    loginStatus: state.auth.loginStatus,
    cartItemsLength: state.cart.items.length,
    cartIsReady: state.cart.isReady,
    pendingScreeningEvaluationsLength:
        (state.screenings.data &&
            filter(
                state.screenings.data,
                (screeningEvaluation: ScreeningEvaluation): boolean =>
                    ScreeningEvaluationStatus.PENDING === screeningEvaluation.status
            ).length) ||
        0,
});
const mapDispatchToProps: DispatchProps = {
    deauthenticate,
    openConnectionModal: createToggleConnectionModalAction,
    toggleMiniCart: createToggleMiniCartAction,
};

export const UserMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(_UserMenu);
