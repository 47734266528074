import { SagaIterator } from 'redux-saga';
import { call, takeLatest, select, put, take } from 'redux-saga/effects';
import { callApi, RequestConfig } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import takeElements from 'lodash/take';
import map from 'lodash/map';
import concat from 'lodash/concat';
import get from 'lodash/get';
import { AppState } from '../../store/reducers';
import { Movie } from '../../domain/work';
import { FavoritesActionType } from './FavoritesActionType';
import { Favorite } from '../../domain/community';
import { FavoriteActionType } from '../favorite/FavoriteActionType';
import { SortOption } from '../../domain/listings';
import {
    FavoritesAction,
    createFetchFavoritesSuccessAction,
    createFetchFavoritesErrorAction,
    FavoriteMoviesAction,
    createFetchFavoriteMoviesSuccessAction,
    createFetchFavoriteMoviesErrorAction,
    createFetchFavoritesAction,
} from './FavoritesActions';

function* fetchFavorites(): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'users/favorites',
            params: { sort: SortOption.LAST_CREATED },
        },
        onSuccess: (res: AxiosResponse): FavoritesAction => {
            return createFetchFavoritesSuccessAction(get(res.data, '_embedded.favorites', []));
        },
        onError: createFetchFavoritesErrorAction,
    });
}

function* fetchFavoriteMovies(payload: FavoriteMoviesAction): SagaIterator {
    const selectFavorites = (state: AppState): Favorite[] | undefined => state.favorites.data;

    yield put(createFetchFavoritesAction());

    let favorites: Favorite[] | undefined = yield select(selectFavorites);
    if (!favorites) {
        yield take([FavoritesActionType.FETCH_FAVORITES_SUCCESS, FavoritesActionType.FETCH_FAVORITES_ERROR]);
    }
    favorites = yield select(selectFavorites);
    if (!favorites) {
        return;
    }

    if (favorites) {
        if (payload.maxMoviesToFetch) {
            favorites = takeElements(favorites, payload.maxMoviesToFetch);
        }
        yield call(callApi, {
            endpoint: favorites.map(
                (item: Favorite): RequestConfig => ({
                    url: `movies/${item.favorite.id}`,
                })
            ),
            onSuccess: (...responses: AxiosResponse<Movie>[]): FavoriteMoviesAction => {
                return createFetchFavoriteMoviesSuccessAction(concat(map(responses, 'data')));
            },
            onError: createFetchFavoriteMoviesErrorAction,
        });
    }
}

export default function* favoritesSaga(): SagaIterator {
    yield takeLatest(
        [
            FavoritesActionType.FETCH_FAVORITES,
            FavoriteActionType.ADD_TO_FAVORITES_SUCCESS,
            FavoriteActionType.REMOVE_FROM_FAVORITES_SUCCESS,
        ],
        fetchFavorites
    );
    yield takeLatest(FavoritesActionType.FETCH_FAVORITE_MOVIES, fetchFavoriteMovies);
}
