import { CycleActionType } from './CycleActionType';
import { Cycle } from '../../domain/editorial';
import { CycleAction, SetCycleMoviesProductsAction } from './CycleActions';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { Movie } from '../../domain/work';

export interface CycleStateData extends Cycle {
    relatedCycles?: Cycle[];
}

export type CycleState = FetchState<CycleStateData>;

export const initialState: CycleState = {
    isLoading: false,
};

export const cycleReducer = (
    state: CycleState = initialState,
    action: CycleAction | SetCycleMoviesProductsAction
): CycleState => {
    switch (action.type) {
        case CycleActionType.FETCH_CYCLE:
            return {
                ...state,
                isLoading: true,
            };

        case CycleActionType.FETCH_CYCLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as CycleStateData,
            };

        case CycleActionType.FETCH_CYCLE_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        case CycleActionType.FETCH_CYCLE_MOVIE_PRODUCTS_SUCCESS:
            if (!state.data || !state.data.works) {
                return state;
            }
            const works = state.data.works.map(
                (movie: Movie, i: number): Movie => {
                    return {
                        ...movie,
                        products: (action as SetCycleMoviesProductsAction).payload[i],
                    };
                }
            );

            return {
                ...state,
                data: { ...state.data, works },
            };

        default:
            return state;
    }
};
