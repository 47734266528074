import React from 'react';
import { isFunction, noop, map } from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { ModalDialog, ModalDialogBody, ModalDialogHeader } from '../../common/ModalDialog';
import { UserGuideProps } from './UserGuide';
import { TFunction } from 'next-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons';
import { humanFileSize } from '../../../utils/FileUtils';
import { DownloadableFile } from '../CommunicationKit/communication-kit';
import Router from 'next/router';
import { ActionButton } from '../../common/Button';
import './UserGuideModal.scss';

const mainUrl = `https://ifcinemastorage.lab.arte.tv/medias/${
    process.env.ENVIRONNMENT === 'production' ? 'prod' : 'preprod'
}/Guides/`;

const fullKit: DownloadableFile = {
    name: 'Télécharger tout',
    url: `${mainUrl}user-guides.zip`,
    size: 8100874,
};

const downloadableFiles: DownloadableFile[] = [
    {
        name: "Guide d'utilisation",
        url: `${mainUrl}user-guide-fr.pdf`,
        size: 3306853,
    },
    {
        name: 'User manual',
        url: `${mainUrl}user-guide-en.pdf`,
        size: 3066626,
    },
    {
        name: 'Manual do usuário',
        url: `${mainUrl}user-guide-pt.pdf`,
        size: 3137433,
    },
];

export interface UserGuideModalProps extends UserGuideProps {
    isOpen?: boolean;
    onClose?: () => void;
}

function downloadFile(file: DownloadableFile, t: TFunction): void {
    if (file == null) {
        return;
    }

    axios
        .get(file.url + '?t=' + Date.now(), {
            responseType: 'blob',
        })
        .then((response: AxiosResponse<Blob>): void => {
            const filename = file.name;
            saveAs(response.data, filename);
        })
        .catch((_err: Error): void => {
            alert(t('could_not_find_asset'));
        });
}

function renderFile(file: DownloadableFile, key: number, locale: string, t: TFunction): JSX.Element {
    return (
        <li key={key} className="UserGuideModal-Body-Resources-Resource">
            <FontAwesomeIcon
                size="3x"
                className="UserGuideModal-Body-Resources-Resource-Icon"
                aria-hidden="true"
                icon={faFileImage}
            />
            <div className="UserGuideModal-Body-Resources-Resource-Data">
                <span className="UserGuideModal-Body-Resources-Resource-Data-Filename">{file.name}</span>
                <span className="UserGuideModal-Body-Resources-Resource-Data-Filesize">
                    {humanFileSize(file.size, locale)}
                </span>
            </div>
            <button
                className="UserGuideModal-Body-Resources-Resource-DownloadButton"
                onClick={(): void => {
                    downloadFile(file, t);
                }}
            >
                <FontAwesomeIcon size="2x" aria-hidden="true" icon={faArrowToBottom} />
            </button>
        </li>
    );
}

export function UserGuideModal({ isOpen, onClose, i18n, t }: UserGuideModalProps): JSX.Element {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;

    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('userGuide')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
            className="UserGuideModal"
        >
            <ModalDialogHeader className="UserGuideModal-Header" onClose={handleClose} t={t}>
                <span className="UserGuideModal-Header-Title">{t('userGuide')}</span>
                <ActionButton
                    className="UserGuideModal-Header-Download"
                    textClassName="UserGuideModal-Download-Text"
                    text={t('downloadCommunicationKit', {
                        fullKitFileSize: humanFileSize(fullKit.size, i18n.language),
                    })}
                    onClick={(): void => {
                        Router.push(fullKit.url);
                    }}
                />
            </ModalDialogHeader>
            <ModalDialogBody>
                <ul className="UserGuideModal-Body-Resources">
                    {map(
                        downloadableFiles,
                        (file: DownloadableFile, key: number): JSX.Element => {
                            return renderFile(file, key, i18n.language, t);
                        }
                    )}
                </ul>
            </ModalDialogBody>
        </ModalDialog>
    );
}
