import { InjectedFormikProps, withFormik, Field } from 'formik';
import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { ActionCreator } from 'redux';
import { WithT } from 'i18next';
import AriaRole from '../AriaRole';
import { AuthAction, Credentials } from '../../modules/auth/AuthActions';
import { TextField, PasswordField } from '../common/Form';
import { ActionButton } from '../common/Button';
import { LoginStatus } from '../../modules/auth';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { RequestError } from '../../sagas/APICallSaga';
import { HttpStatusCode } from '../../domain/HttpStatusCode';

library.add(faCheck);

interface FormProps extends WithT {
    email?: string;
    authenticationRequestActionCreator: ActionCreator<AuthAction>;
    authError?: RequestError;
    isAuthenticating: boolean;
    loginStatus: LoginStatus;
}

type FormValues = Credentials;

const InnerForm: FunctionComponent<InjectedFormikProps<FormProps, FormValues>> = ({
    t,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    status,
    authError,
    isAuthenticating,
    loginStatus,
}: InjectedFormikProps<FormProps, FormValues>): JSX.Element => {
    return (
        <form onSubmit={handleSubmit} className="ConnectionForm">
            <Field
                name="email"
                label={t('registration:email')}
                component={TextField}
                type="email"
                autocomplete="username"
            />
            <Field
                name="password"
                label={t('registration:password')}
                component={PasswordField}
                t={t}
                autocomplete="current-password"
            />

            <ActionButton
                disabled={isSubmitting || isAuthenticating || loginStatus === LoginStatus.AUTHENTICATED}
                text={
                    loginStatus === LoginStatus.AUTHENTICATED ? (
                        <FontAwesomeIcon size="1x" icon={faCheck} />
                    ) : isSubmitting || isAuthenticating ? (
                        t('registration:loginInProgress')
                    ) : (
                        t('registration:login')
                    )
                }
                className="ConnectionForm-Button light"
                type="submit"
            />

            {((Object.keys(errors).length > 0 && Object.keys(touched).length > 0) ||
                (status && status.error) ||
                !!authError) && (
                <ul className="FormErrors ConnectionForm-Errors" role={AriaRole.ALERT}>
                    {touched.email && errors.email && <li>{errors.email}</li>}
                    {touched.password && errors.password && <li>{errors.password}</li>}
                    {status && status.error && <li>{status.error}</li>}
                    {!!authError && (
                        <li>
                            {t(
                                authError.status === HttpStatusCode.UNAUTHORIZED
                                    ? 'common:authError'
                                    : 'registration:errorSubmittingForm'
                            )}
                        </li>
                    )}
                </ul>
            )}
        </form>
    );
};

export const ConnectionForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (): FormValues => ({ email: '', password: '' }),
    validationSchema: (props: FormProps): Yup.Schema<FormValues> =>
        Yup.object().shape({
            email: Yup.string()
                .email(props.t('forms:invalid_email'))
                .required(props.t('forms:email_required')),
            password: Yup.string().required(props.t('forms:password_required')),
        }),
    handleSubmit: (values: FormValues, { setSubmitting, props }): void => {
        props.authenticationRequestActionCreator(values);
        setSubmitting(false);
    },
})(InnerForm);
