import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { StreamingEventActionType } from './StreamingEventActionType';
import { StreamingEvent } from '../../domain/editorial';
import { Action } from 'redux';
import { VideoProduct } from '../../domain/commerce';
import { LegalContract } from '../../domain/legal';
import { StreamingEventStateData } from './StreamingEventReducer';
import { GeoLocation } from '../../server/geoip-middleware';

export interface StreamingEventAction extends FetchAction<StreamingEventActionType, StreamingEventStateData> {
    id?: string;
    slug?: string;
}

export interface FetchStreamingEventDataAction extends Action<StreamingEventActionType> {
    payload: StreamingEvent;
}

export interface SetUserGeoLocationAction extends Action<StreamingEventActionType> {
    payload: GeoLocation;
}

export interface SetMoviesProductsAction extends Action<StreamingEventActionType> {
    payload: VideoProduct[][];
}

export interface SetMoviesContractsAction extends Action<StreamingEventActionType> {
    payload: LegalContract[][];
}

export function createFetchStreamingEventAction(id: string): StreamingEventAction {
    return {
        type: StreamingEventActionType.FETCH_STREAMING_EVENT,
        id,
    };
}

export function createFetchStreamingEventBySlugAction(slug: string): StreamingEventAction {
    return {
        type: StreamingEventActionType.FETCH_STREAMING_EVENT_BY_SLUG,
        slug,
    };
}

export function createFetchStreamingEventSuccessAction(
    streamingEventData: StreamingEventStateData
): StreamingEventAction {
    return {
        type: StreamingEventActionType.FETCH_STREAMING_EVENT_SUCCESS,
        payload: streamingEventData,
    };
}

export function createFetchStreamingEventErrorAction(error: RequestError): StreamingEventAction {
    return {
        type: StreamingEventActionType.FETCH_STREAMING_EVENT_ERROR,
        payload: error,
    };
}

export function createFetchStreamingEventDataAction(streamingEvent: StreamingEvent): FetchStreamingEventDataAction {
    return { type: StreamingEventActionType.FETCH_STREAMING_EVENT_DATA, payload: streamingEvent };
}

export function createSetUserGeoLocationAction(userGeoLocation: GeoLocation): SetUserGeoLocationAction {
    return {
        type: StreamingEventActionType.FETCH_USER_COUNTRY_SUCCESS,
        payload: userGeoLocation,
    };
}

export function createSetStreamingEventMoviesProductsAction(products: VideoProduct[][]): SetMoviesProductsAction {
    return {
        type: StreamingEventActionType.FETCH_MOVIES_PRODUCTS_SUCCESS,
        payload: products,
    };
}

export function createSetStreamingEventMoviesContractsAction(contracts: LegalContract[][]): SetMoviesContractsAction {
    return {
        type: StreamingEventActionType.FETCH_MOVIES_CONTRACTS_SUCCESS,
        payload: contracts,
    };
}
