import React, { ChangeEvent, useCallback } from 'react';
import classNames from 'clsx';
import { OffsetButton } from '../../common/Button';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../common/A11y/Button';
import { WithT } from 'i18next';

library.add(faArrowRight);

interface SearchInputProps extends WithT {
    userHasEnteredText: boolean;
    query: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({ onChange, query, userHasEnteredText, t }: SearchInputProps): JSX.Element => {
    const inputRef = useCallback((node: HTMLInputElement | null): void => {
        if (node) {
            node.focus();
        }
    }, []);

    return (
        <div
            className={classNames('SearchInput', {
                'SearchInput-withBottomSpacing': userHasEnteredText,
            })}
        >
            <input
                className="SearchInput-Input"
                type="search"
                value={query}
                name="q_text"
                onChange={onChange}
                placeholder={t('searchPlacholder')}
                ref={inputRef}
            />
            <OffsetButton
                text={
                    <>
                        <FontAwesomeIcon aria-hidden="true" icon={faArrowRight} fixedWidth />
                        <span className="SearchInput-Button-Label">{t('header:search')}</span>
                    </>
                }
                className={classNames('SearchInput-DesktopButton', {
                    'SearchInput-DesktopButton-displayed': userHasEnteredText,
                })}
                disabled={!userHasEnteredText}
                tooltip={t('header:search')}
            />
            <Button
                className={classNames('SearchInput-MobileButton', {
                    'SearchInput-MobileButton-displayed': userHasEnteredText,
                })}
                text={<FontAwesomeIcon aria-hidden="true" icon={faArrowRight} fixedWidth />}
            />
        </div>
    );
};
