import React, { useState, useEffect } from 'react';
import { Movie } from '../../../domain/work';
import { MoviePoster } from '../../common/MoviePoster';
import { SearchSuggestions } from '../../../domain/search';
import { Link } from '../../common/A11y/Link';
import isEmpty from 'lodash/isEmpty';
import { useDrag } from 'react-use-gesture';
import { useClientRect } from '../../../hooks/useClientRect';
import { ActionCreator } from 'redux';
import { UIAction } from '../../../modules/UI';
import { Router } from '../../../i18n';
import { WithT } from 'i18next';
import { OffsetButton } from '../../common/Button';
import { Cycle } from '../../../domain/editorial';
import { CyclePoster } from '../../common/Cycle';

interface OwnProps {
    toggleSearchModal: ActionCreator<UIAction>;
}

type SearchSuggestionsMoviesProps = OwnProps &
    Pick<SearchSuggestions, 'movies'> &
    Pick<SearchSuggestions, 'cycles'> &
    WithT;

export const SearchSuggestionsMovies = ({
    movies,
    cycles,
    toggleSearchModal,
    t,
}: SearchSuggestionsMoviesProps): JSX.Element | null => {
    const [maxY, setMaxY] = useState<number>();
    const [containerRect, ref] = useClientRect();

    useEffect((): void => {
        if (!containerRect) {
            return;
        }
        const searchInput = document.querySelector<HTMLDivElement>('.SearchInput');
        if (searchInput) {
            const searchInputRect: DOMRect | ClientRect = searchInput.getBoundingClientRect();
            setMaxY(searchInputRect.top + searchInputRect.height - containerRect.top);
        }
    }, [containerRect]);

    const [prevY, setPrevY] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const bindEvents = useDrag(({ down, movement }): void => {
        if (maxY === undefined) {
            return;
        }

        const canDrag = !((y === maxY && movement[1] <= 0) || (y === 0 && movement[1] >= 0));

        if (down && canDrag) {
            setY(Math.max(maxY, Math.min(prevY + movement[1], 0)));
            setIsDragging(true);
        } else {
            setPrevY(y);
            setIsDragging(false);
        }
    });

    if (isEmpty(movies) && isEmpty(cycles)) {
        return null;
    }

    return (
        <ul
            className="SearchSuggestionsMovies"
            ref={ref}
            style={{
                transform: `translateY(${y}px)`,
                overflow: isDragging ? 'hidden' : 'auto',
            }}
            {...bindEvents()}
        >
            {movies.map(
                (movie: Movie, i: number): JSX.Element => {
                    const movieLink = { pathname: '/movie', query: { id: movie.id } };
                    return (
                        <li key={i} className="SearchSuggestionsMovies-Movie">
                            <Link
                                href={movieLink}
                                className="SearchSuggestionsMovies-Link"
                                title={movie.title}
                                onClick={(): void => {
                                    Router.push(movieLink);
                                    toggleSearchModal();
                                }}
                            >
                                <MoviePoster movie={movie} t={t} />
                                <span className="SearchSuggestionsMovies-Title">{movie.title}</span>
                            </Link>
                        </li>
                    );
                }
            )}
            {cycles.map(
                (cycle: Cycle, i: number): JSX.Element => {
                    const cycleLink = { pathname: '/cycle', query: { id: cycle.id } };
                    return (
                        <li key={i} className="SearchSuggestionsMovies-Movie">
                            <Link
                                href={cycleLink}
                                className="SearchSuggestionsMovies-Link"
                                title={cycle.title}
                                onClick={(): void => {
                                    Router.push(cycleLink);
                                    toggleSearchModal();
                                }}
                            >
                                <CyclePoster cycle={cycle} t={t} />
                                <span className="SearchSuggestionsMovies-Title">{cycle.title}</span>
                            </Link>
                        </li>
                    );
                }
            )}
            <OffsetButton
                className="SearchSuggestionsMovies-SeeMoreButton"
                textClassName="SearchSuggestionsMovies-SeeMoreButton-Text"
                text={t('header:searchViewAllResults')}
            />
        </ul>
    );
};
