import React, { SyntheticEvent, FunctionComponent } from 'react';
import { CartEntry, ProductType, CatalogLanguage, VideoProduct } from '../../../domain/commerce';
import { MoviePoster } from '../../common/MoviePoster';
import { SVGCloseIcon } from '../../common/SVGIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilm, faVolume, faCommentAltLines } from '@fortawesome/pro-regular-svg-icons';
import { WithT } from 'i18next';
import { library, IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'clsx';
import { Button } from '../../common/A11y/Button';
import { TFunction } from 'next-i18next';
import { getMovieDptCrewFullNamesList } from '../../../domain/utils/MovieUtils';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { Link } from '../../common/A11y/Link';

library.add(faFilm, faVolume, faCommentAltLines);

const getFormattedAudioLanguage = (entry: CartEntry): string | null => {
    if (!entry.videoProduct.audioLanguages) {
        return null;
    }
    return entry.videoProduct.audioLanguages.map((audio: CatalogLanguage): string => audio.name).join(', ');
};

const getFormattedSubtitlesLanguage = (entry: CartEntry): string | null => {
    if (!entry.videoProduct.subtitleLanguages || entry.videoProduct.subtitleLanguages.length === 0) {
        return null;
    }
    return entry.videoProduct.subtitleLanguages
        .map((sub: CatalogLanguage): string => sub.name)
        .sort()
        .join(', ');
};

const renderOption = (
    option: string | null,
    icon: IconProp,
    t: TFunction,
    isEditable: boolean,
    onEdit?: () => void
): JSX.Element | null =>
    option === null ? null : (
        <span className="CartEntryCard-Info-Option">
            <FontAwesomeIcon size="1x" aria-hidden="true" icon={icon} className="CartEntryCard-Info-Icon" />
            {option}
            {isEditable && (
                <Button text={t('(changeOption)')} onClick={onEdit} className="CartEntryCard-Info-EditOptionButton" />
            )}
        </span>
    );

const renderAdditionalInfo = (videoProduct: VideoProduct): JSX.Element | null => {
    if (isEmpty(videoProduct.additionalInfo)) {
        return null;
    }
    return <span className="CartEntryCard-AdditionalInfo">{videoProduct.additionalInfo}</span>;
};

const getVideoProductTypeName = (entry: CartEntry, t: TFunction): string => {
    let name: string = entry.videoProduct.type.name;
    if (ProductType.PHYSICAL === entry.videoProduct.type.productType && entry.quantity) {
        name += ' - ' + t('productQuantity', { count: entry.quantity });
    }
    return name;
};

interface CartEntryCardProps extends WithT {
    entry: CartEntry;
    onRemove?: (entry: CartEntry) => void;
    onEdit?: (entry: CartEntry) => void;
    linkPosterToMovie?: boolean;
    className?: string;
    moviePosterWidth: number;
    moviePosterHeight: number;
    displayMetadata?: boolean;
}

export const CartEntryCard: FunctionComponent<CartEntryCardProps> = ({
    entry,
    onRemove,
    onEdit,
    linkPosterToMovie,
    displayMetadata,
    className,
    moviePosterWidth,
    moviePosterHeight,
    t,
}: CartEntryCardProps): JSX.Element => {
    const movie = entry.videoProduct.movie!;
    const formattedAudioLanguages = getFormattedAudioLanguage(entry);
    const formattedSubtitlesLanguages = getFormattedSubtitlesLanguage(entry);
    const isEditable = !!onEdit;
    const directorFullName: string | undefined = getMovieDptCrewFullNamesList(movie, 'directors', 1);
    const handleEdit: () => void = isFunction(onEdit) ? (): void => onEdit(entry) : noop;
    const decoratePoster = (poster: JSX.Element): JSX.Element =>
        linkPosterToMovie ? <Link href={{ pathname: '/movie', query: { id: movie.id } }}>{poster}</Link> : poster;
    return (
        <article className={classNames('CartEntryCard', className)}>
            {onRemove && (
                <button
                    type="button"
                    aria-label={t('removeFromSelection')}
                    className="CartEntryCard-Remove"
                    onClick={(event: SyntheticEvent<HTMLButtonElement>): void => {
                        if (onRemove) {
                            onRemove(entry);
                        }
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    <SVGCloseIcon width={16} height={16} viewBox="0 0 26 26" t={t} />
                </button>
            )}
            {decoratePoster(
                <MoviePoster
                    movie={movie}
                    width={moviePosterWidth}
                    height={moviePosterHeight}
                    className="CartEntryCard-Poster"
                    t={t}
                />
            )}
            <div className="CartEntryCard-Info">
                <Link href={{ pathname: '/movie', query: { id: movie.id } }}>
                    <h1 className="CartEntryCard-Info-Title">{movie.title}</h1>
                </Link>
                {displayMetadata && (
                    <span className="CartEntryCard-Info-Metadata">
                        {compact([`${t('common:by')} ${directorFullName}`, movie.productionYear]).join('  |  ')}
                    </span>
                )}
                {renderOption(getVideoProductTypeName(entry, t), faFilm, t, isEditable, handleEdit)}
                {renderOption(formattedAudioLanguages, faVolume, t, false)}
                {renderOption(
                    formattedSubtitlesLanguages,
                    faCommentAltLines,
                    t,
                    isEditable && entry.videoProduct.type.productType !== ProductType.DIGITAL,
                    handleEdit
                )}
                {renderAdditionalInfo(entry.videoProduct)}
            </div>
        </article>
    );
};
