import React, { FunctionComponent, useEffect, useState } from 'react';
import { ActionCreator, compose } from 'redux';
import { connect } from 'react-redux';
import { Animate } from 'react-show';
import { WithTranslation } from 'next-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { LanguageAndAccessibilityDropdowns } from '../LanguageAndAccessibilityDropdowns';
import PanelMenuMobile from './PanelMenuMobile';
import { withTranslation } from '../../i18n';
import { AppState } from '../../store/reducers';
import { createToggleMenuMobileAction, UIAction } from '../../modules/UI';

interface DispatchProps {
    toogleMenuMobile: ActionCreator<UIAction>;
}

interface StateProps {
    isOpen: boolean;
}

type MenuMobileProps = DispatchProps & StateProps & WithTranslation;

const _MenuMobile: FunctionComponent<MenuMobileProps> = ({
    t,
    isOpen,
    toogleMenuMobile,
}: MenuMobileProps): JSX.Element => {
    const [isTheMobileMenuFullyOpened, setIsTheMobileMenuFullyOpened] = useState<boolean>(false);

    const [display, setDisplay] = useState<'none' | 'block'>('none');
    const [opacity, setOpacity] = useState<number>(0);
    const [openpanel, setOpenpanel] = useState<boolean>(false);

    let pathname: string | null = null;
    if (typeof window !== 'undefined') {
        pathname = window.location.pathname;
    }

    const [currentUrl, setCurrentUrl] = React.useState(pathname);

    useEffect((): void => {
        if (currentUrl !== null && pathname !== currentUrl) {
            if (isTheMobileMenuFullyOpened) {
                toogleMenuMobile();
            }
            setCurrentUrl(pathname);
        }
    }, [currentUrl, isTheMobileMenuFullyOpened, pathname, toogleMenuMobile]);

    useEffect((): void => {
        if (isOpen) {
            setDisplay('block');
            setTimeout((): void => {
                setOpacity(1);
                setOpenpanel(true);
            }, 10);
        } else {
            setIsTheMobileMenuFullyOpened(false);
            setOpacity(0);
            setOpenpanel(false);
            setTimeout((): void => setDisplay('none'), 350);
        }
    }, [isOpen]);

    const handleClickOutsideMobileMenu = (): void => {
        if (isTheMobileMenuFullyOpened) {
            toogleMenuMobile();
        }
    };

    const handleMobileMenuAnimationFinished = (): void => {
        if (openpanel) {
            setIsTheMobileMenuFullyOpened(true);
        }
    };

    return (
        <div className="MenuMobile" style={{ opacity: opacity, display: display }}>
            <OutsideClickHandler onOutsideClick={handleClickOutsideMobileMenu}>
                <Animate
                    className="panel panel-primary"
                    show={openpanel}
                    preMount={true}
                    transitionOnMount={true}
                    start={{
                        transform: 'translate3d(-100%, 0, 0)',
                    }}
                    onFinish={handleMobileMenuAnimationFinished}
                >
                    <Animate
                        className="panel-block"
                        show={true}
                        transitionOnMount={true}
                        start={{
                            transform: 'translate3d(0, 0, 0)',
                        }}
                    >
                        <svg width="216px" height="247px" viewBox="0 0 216 247" className="rect" aria-hidden="true">
                            <rect
                                aria-hidden="true"
                                transform="translate(128, 123) rotate(-150) translate(-128, -123) "
                                x="103"
                                y="-4"
                                width="50"
                                height="255"
                            />
                            <rect
                                aria-hidden="true"
                                transform="translate(108, 134) rotate(-135) translate(-108, -134) "
                                x="83"
                                y="6"
                                width="50"
                                height="255"
                            />
                        </svg>
                        <button onClick={toogleMenuMobile} className="burger-menu">
                            <svg width="26px" height="26px" viewBox="0 0 26 26" aria-hidden="true">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-19.000000, -24.000000)" fill="#000000">
                                        <g transform="translate(19.000000, 24.000000)">
                                            <path d="M16.1259318,13.3214219 L25.2405889,22.436079 C26.0150329,23.2105231 26.0150329,24.4661449 25.2405889,25.2405889 C24.4661449,26.0150329 23.2105231,26.0150329 22.436079,25.2405889 L13.3214219,16.1259318 L4.20676482,25.2405889 C3.4323208,26.0150329 2.17669896,26.0150329 1.40225494,25.2405889 C0.627810921,24.4661449 0.627810921,23.2105231 1.40225494,22.436079 L10.516912,13.3214219 L1.40225494,4.20676482 C0.627810921,3.4323208 0.627810921,2.17669896 1.40225494,1.40225494 C2.17669896,0.627810921 3.4323208,0.627810921 4.20676482,1.40225494 L13.3214219,10.516912 L22.436079,1.40225494 C23.2105231,0.627810921 24.4661449,0.627810921 25.2405889,1.40225494 C26.0150329,2.17669896 26.0150329,3.4323208 25.2405889,4.20676482 L16.1259318,13.3214219 Z" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span className="visually-hidden">{t('closeMobileMenu')}</span>
                        </button>
                        <PanelMenuMobile t={t} />
                        <LanguageAndAccessibilityDropdowns />
                    </Animate>
                </Animate>
            </OutsideClickHandler>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        isOpen: state.UI.menuMobileIsOpen,
    };
};
const mapDispatchToProps: DispatchProps = {
    toogleMenuMobile: createToggleMenuMobileAction,
};

export const MenuMobile = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation(['header', 'common'])
)(_MenuMobile) as FunctionComponent;
