import { InjectedFormikProps, withFormik, Field } from 'formik';
import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { WithT } from 'i18next';
import { TextAreaField } from '../../common/Form';
import { ActionButton } from '../../common/Button';

interface FormProps extends WithT {
    onSubmit: (description: string) => void;
}

interface FormValues {
    description: string;
}

const InnerForm: FunctionComponent<InjectedFormikProps<FormProps, FormValues>> = ({
    t,
    handleSubmit,
    isSubmitting,
}: InjectedFormikProps<FormProps, FormValues>): JSX.Element => {
    return (
        <form onSubmit={handleSubmit} className="ReactivateAccountForm">
            <p className="ReactivateAccountModal-Description">
                {t('reactivateAccountDescription')}
                <br />
                {t('subjectToValidation')}
            </p>
            <Field name="description" label={t('reactivation:requestPlaceholder')} component={TextAreaField} />

            <ActionButton
                disabled={isSubmitting}
                text={t('reactivation:sendRequest')}
                className="ReactivateAccountModal-ActionButton light"
                type="submit"
            />
        </form>
    );
};

export const ReactivateAccountForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (): FormValues => ({ description: '' }),
    validationSchema: (props: FormProps): Yup.Schema<FormValues> =>
        Yup.object().shape({
            description: Yup.string().required(props.t('reactivation:descriptionRequired')),
        }),
    handleSubmit: (values: FormValues, { setSubmitting, props }): void => {
        setSubmitting(false);
        props.onSubmit(values.description);
    },
})(InnerForm);
