import { CyclesActionType } from './CyclesActionType';
import { AnyAction } from 'redux';
import { Cycle } from '../../domain/editorial';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { Paginable, Sortable, PaginationAction } from '../../domain/listings';

export type CyclesState = FetchState<Cycle[]> & Paginable & Sortable;

export const initialState: CyclesState = {
    isLoading: false,
    isPaginating: false,
    isSorting: false,
};

export const cyclesReducer = (state: CyclesState = initialState, action: AnyAction): CyclesState => {
    switch (action.type) {
        case CyclesActionType.FETCH_CYCLES:
            return {
                ...state,
                isLoading: true,
                isPaginating: (action as PaginationAction<CyclesActionType>).requestedPage! > 0,
                sort: action.sort,
            };

        case CyclesActionType.FETCH_CYCLES_SUCCESS:
            let data = action.payload;
            if (action.pagination !== undefined && action.pagination.currentPage > 0 && state.data !== undefined) {
                data = state.data.concat(data);
            }
            return {
                ...state,
                isLoading: false,
                isPaginating: false,
                isSorting: false,
                pagination: action.pagination,
                error: undefined,
                data,
            };

        case CyclesActionType.FETCH_CYCLES_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        case CyclesActionType.UPDATE_SORTING:
            return {
                ...state,
                isLoading: true,
                isSorting: true,
                sort: action.sort,
            };

        default:
            return state;
    }
};
