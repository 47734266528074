import { takeEvery, call } from 'redux-saga/effects';
import * as actions from './FaqCategoryActions';
import get from 'lodash/get';
import { FaqCategoryActionType } from './FaqCategoryActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';

function* fetchFaqCategory(action: actions.FaqCategoryAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: action.id ? `faq-categories/${action.id}` : 'faq-categories',
            method: 'GET',
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                q_code: action.code,
            },
        },
        onSuccess: (result): actions.FaqCategoryAction =>
            actions.createFetchCategorySuccessAction(
                action.code ? get(result.data, '_embedded.faqCategories', [])[0] : result.data
            ),
        onError: actions.createFetchCategoryErrorAction,
    });
}

export default function* faqSaga(): SagaIterator {
    yield takeEvery(FaqCategoryActionType.FETCH_FAQ_CATEGORY, fetchFaqCategory);
}
