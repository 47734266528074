import { takeLatest, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { createSetUserGeoLocationAction } from './UserGeoLocationActions';
import { UserGeoLocationType } from './UserGeoLocationType';

function getUserGeoLocation(): AxiosPromise<AxiosResponse> {
    return axios.get('/geoip');
}

function* fetchUserGeoLocation(): SagaIterator {
    const geoipResponse = yield call(getUserGeoLocation);
    const geoLocation = geoipResponse.data.geoLocation;
    yield put(createSetUserGeoLocationAction(geoLocation));
}

export default function* cycleSaga(): SagaIterator {
    yield takeLatest(UserGeoLocationType.FETCH_USER_COUNTRY, fetchUserGeoLocation);
}
