import React, { Component } from 'react';
import { Navbar } from 'react-bulma-components';
import { WithTranslation } from 'react-i18next';
import NavBarMenu from './NavBarMenu';
import classNames from 'clsx';
import debounce from 'lodash/debounce';
import NavBarBurgerButton from './NavBarBurgerButton';
import { NavBarBrand } from './NavBarBrand';
import { MenuMobile } from './MenuMobile';
import { withTranslation } from '../../i18n';

import './Header.scss';

export interface OwnProps {
    openSearchModal: () => void;
}

type HeaderProps = OwnProps & WithTranslation;

export interface HeaderState {
    scroll: number | null;
    isSticky: boolean;
}

class Header extends Component<HeaderProps, HeaderState> {
    private static STICKY_THRESHOLD = 30;

    public constructor(props: HeaderProps) {
        super(props);
        this.state = {
            scroll: null,
            isSticky: false,
        };
        this.handleScroll = debounce(this.handleScroll, 100).bind(this);
    }

    public componentDidMount(): void {
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentDidUpdate(_prevProps: HeaderProps, prevState: HeaderState): void {
        if (this.state.scroll !== null && prevState.scroll !== null) {
            if (
                !this.state.isSticky &&
                this.state.scroll > prevState.scroll &&
                this.state.scroll > Header.STICKY_THRESHOLD
            ) {
                this.setState({ isSticky: true });
            }
            if (
                this.state.isSticky &&
                this.state.scroll < prevState.scroll &&
                this.state.scroll <= Header.STICKY_THRESHOLD
            ) {
                this.setState({ isSticky: false });
            }
        }
    }

    public render(): JSX.Element {
        const { t, openSearchModal } = this.props;

        const classnames: string = classNames('Header', {
            'is-sticky': this.state.isSticky,
        });

        return (
            <>
                <Navbar renderAs="header" role="banner" className={classnames}>
                    <NavBarBurgerButton />
                    <NavBarBrand />
                    <NavBarMenu t={t} openSearchModal={openSearchModal} />
                </Navbar>
                <MenuMobile />
            </>
        );
    }

    private handleScroll(): void {
        if (document.body.style.position === 'fixed') {
            const top = document.body.style.top;
            this.setState({ scroll: top ? -parseInt(top) : 0 });
        } else {
            this.setState({ scroll: window.pageYOffset });
        }
    }
}

export default withTranslation(['header', 'common'])(Header);
