import { ScreeningsActionType } from './ScreeningsActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { ScreeningEvaluation, ScreeningEvaluationData } from '../../domain/user';
import { Action } from 'redux';
import { LegalContract } from '../../domain/legal';

export type ScreeningsAction = FetchAction<
    ScreeningsActionType,
    ScreeningEvaluation[] | ScreeningEvaluationData | LegalContract[][]
>;

export interface ScreeningsUpdateAction extends Action<ScreeningsActionType> {
    payload: ScreeningEvaluation;
}

export function createFetchScreeningsAction(): ScreeningsAction {
    return {
        type: ScreeningsActionType.FETCH_SCREENINGS,
    };
}

export function createFetchScreeningsSuccessAction(screenings: ScreeningEvaluation[]): ScreeningsAction {
    return {
        type: ScreeningsActionType.FETCH_SCREENINGS_SUCCESS,
        payload: screenings,
    };
}

export function createFetchScreeningsErrorAction(error: RequestError): ScreeningsAction {
    return {
        type: ScreeningsActionType.FETCH_SCREENINGS_ERROR,
        payload: error,
    };
}

export function createAddScreeningAction(screening: ScreeningEvaluation): ScreeningsUpdateAction {
    return {
        type: ScreeningsActionType.ADD_SCREENING,
        payload: screening,
    };
}

export function createEditScreeningAction(screening: ScreeningEvaluation): ScreeningsUpdateAction {
    return {
        type: ScreeningsActionType.EDIT_SCREENING,
        payload: screening,
    };
}

export function createEditScreeningSuccessAction(screening: ScreeningEvaluation): ScreeningsUpdateAction {
    return {
        type: ScreeningsActionType.EDIT_SCREENING_SUCCESS,
        payload: screening,
    };
}

export function createEditScreeningErrorAction(error: RequestError): ScreeningsAction {
    return {
        type: ScreeningsActionType.EDIT_SCREENING_ERROR,
        payload: error,
    };
}

export function createAddScreeningSuccessAction(screening: ScreeningEvaluation): ScreeningsAction {
    return {
        type: ScreeningsActionType.ADD_SCREENING_SUCCESS,
        payload: screening,
    };
}

export function createFetchMoviesContractsSuccess(contracts: LegalContract[][]): ScreeningsAction {
    return {
        type: ScreeningsActionType.FETCH_CONTRACTS_SUCCESS,
        payload: contracts,
    };
}

export function createAddScreeningErrorAction(error: RequestError): ScreeningsAction {
    return {
        type: ScreeningsActionType.ADD_SCREENING_ERROR,
        payload: error,
    };
}

export function createResetScreeningSuccessAction(): ScreeningsAction {
    return {
        type: ScreeningsActionType.RESET_ADD_SCREENING_SUCCESS,
    };
}
