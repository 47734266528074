import { SagaIterator } from 'redux-saga';
import { take, fork, call, cancel, delay } from 'redux-saga/effects';
import * as actions from './SearchSuggestionsActions';
import { SearchSuggestionsActionType } from './SearchSuggestionsActionType';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import { EmbeddedSearchSuggestions } from '../../domain/search';

function* fetchSuggestions(action: actions.SearchSuggestionsAction): SagaIterator {
    yield delay(300);
    yield call(callApi, {
        endpoint: {
            url: `search-suggests`,
            // eslint-disable-next-line @typescript-eslint/camelcase
            params: { q_text: action.terms },
        },
        onSuccess: (res: AxiosResponse<EmbeddedSearchSuggestions>): actions.SearchSuggestionsAction =>
            actions.createFetchSearchSuggestionsSuccessAction(res.data._embedded),
        onError: actions.createFetchSearchSuggestionsErrorAction,
    });
}

export default function* searchSuggestionsSaga(): SagaIterator {
    let task;
    while (true) {
        const action = yield take([
            SearchSuggestionsActionType.FETCH_SUGGESTIONS_SUCCESS,
            SearchSuggestionsActionType.FETCH_SUGGESTIONS_ERROR,
            SearchSuggestionsActionType.RESET_SUGGESTIONS,
            SearchSuggestionsActionType.FETCH_SUGGESTIONS,
        ]);

        if (
            task !== undefined &&
            (action.type === SearchSuggestionsActionType.RESET_SUGGESTIONS ||
                action.type === SearchSuggestionsActionType.FETCH_SUGGESTIONS)
        ) {
            yield cancel(task);
        }

        if (action.type === SearchSuggestionsActionType.FETCH_SUGGESTIONS) {
            task = yield fork(fetchSuggestions, action);
        }
    }
}
