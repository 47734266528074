import { Playlist } from '../../domain/community';
import { PlaylistsActionType } from './PlaylistsActionType';
import { AxiosResponse } from 'axios';
import { call, takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { callApi } from '../../sagas/APICallSaga';
import * as actions from './PlaylistsActions';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';

function* fetchPlaylists(): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'users/playlists',
        },
        onSuccess: (res: AxiosResponse<EmbeddedEntities<'playlists', Playlist>>): actions.PlaylistsAction => {
            const payload: Playlist[] = {
                ...getEmbeddedEntities(res.data, 'playlists'),
            };
            return actions.createFetchPlaylistsSuccessAction(payload);
        },
        onError: actions.createFetchPlaylistsErrorAction,
    });
}

function* postPlaylist(action: actions.CreatePlaylistAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users/playlists`,
            method: 'POST',
            body: action.playlist,
        },
        onSuccess: (res: AxiosResponse): actions.CreateOrUpdatePlaylistSuccessAction => {
            return actions.createPlaylistSuccessAction(res.data);
        },
        onError: actions.createPlaylistErrorAction,
    });
}

function* putPlaylist(action: actions.UpdatePlaylistAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'users/playlists/' + action.playlist.id,
            method: 'PUT',
            body: {
                ...action.playlist,
            },
        },
        onSuccess: (res: AxiosResponse<Playlist>): actions.CreateOrUpdatePlaylistSuccessAction => {
            return actions.updatePlaylistSuccessAction(res.data);
        },
        onError: actions.updatePlaylistErrorAction,
    });
}

function* deletePlaylist(action: actions.DeletePlaylistAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'users/playlists/' + action.playlistId,
            method: 'DELETE',
        },
        onSuccess: (): actions.PlaylistsAction => actions.deletePlaylistSuccessAction() as any,
        onError: actions.deletePlaylistErrorAction,
    });
}

export default function* playlistsSaga(): SagaIterator {
    yield takeLatest(PlaylistsActionType.FETCH_PLAYLISTS, fetchPlaylists);
    yield takeLatest(PlaylistsActionType.CREATE_PLAYLIST, postPlaylist);
    yield takeLatest(PlaylistsActionType.UPDATE_PLAYLIST, putPlaylist);
    yield takeLatest(PlaylistsActionType.DELETE_PLAYLIST, deletePlaylist);
    yield takeEvery(PlaylistsActionType.CREATE_PLAYLIST_SUCCESS, fetchPlaylists);
}
