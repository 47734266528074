import { takeLatest, call, select } from 'redux-saga/effects';
import { CyclesActionType } from './CyclesActionType';
import { SagaIterator } from 'redux-saga';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { AppState } from '../../store/reducers';
import { SortOption } from '../../domain/listings';
import {
    FETCHED_PAGE_SIZE,
    CyclesAction,
    createFetchCyclesErrorAction,
    createFetchCyclesSuccessAction,
} from './CyclesActions';

interface CycleFetchParams {
    size: number;
    page?: number;
    q_text?: string;
    sort?: SortOption;
    has_free_movie?: boolean;
    q_type: 'default' | 'streaming';
}

function* fetchCycles(action: CyclesAction): SagaIterator {
    // eslint-disable-next-line @typescript-eslint/camelcase
    let params: CycleFetchParams = { size: FETCHED_PAGE_SIZE, page: action.requestedPage, q_type: 'default' };
    const searchTerm = yield select((state: AppState): string => state.filter.searchTerm);
    if (searchTerm) {
        params = {
            ...params,
            // eslint-disable-next-line @typescript-eslint/camelcase
            q_text: searchTerm,
        };
    }
    const sort = yield select((state: AppState): SortOption | undefined => state.cycles.sort);
    if (sort) {
        params = {
            ...params,
            sort,
        };
    }

    yield call(callApi, {
        endpoint: { url: 'search-cycles', params },
        onSuccess: (res: AxiosResponse): CyclesAction =>
            createFetchCyclesSuccessAction(get(res, 'data._embedded.cycles', []), {
                currentPage: action.requestedPage || 0,
                pageSize: action.pageSize || FETCHED_PAGE_SIZE,
                totalCount: parseInt(res.headers['x-total-count']),
                pageCount: parseInt(res.headers['x-page-count']),
            }),
        onError: createFetchCyclesErrorAction,
    });
}

export default function* cyclesSaga(): SagaIterator {
    yield takeLatest([CyclesActionType.FETCH_CYCLES, CyclesActionType.UPDATE_SORTING], fetchCycles);
}
