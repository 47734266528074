import { CurrentEventsActionType } from './CurrentEventsActionType';
import { CurrentEvent } from '../../domain/editorial';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type CurrentEventsAction = FetchAction<CurrentEventsActionType, CurrentEvent[]>;

export function createFetchCurrentEventsAction(): CurrentEventsAction {
    return { type: CurrentEventsActionType.FETCH_CURRENT_EVENTS };
}

export function createFetchCurrentEventsSuccessAction(CurrentEvents: CurrentEvent[]): CurrentEventsAction {
    return {
        type: CurrentEventsActionType.FETCH_CURRENT_EVENTS_SUCCESS,
        payload: CurrentEvents,
    };
}

export function createFetchCurrentEventsErrorAction(error: RequestError): CurrentEventsAction {
    return {
        type: CurrentEventsActionType.FETCH_CURRENT_EVENTS_ERROR,
        payload: error,
    };
}
