import { takeLatest, call, put } from 'redux-saga/effects';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { StreamingEvent } from '../../domain/editorial';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import first from 'lodash/first';
import { StreamingEventActionType } from './StreamingEventActionType';
import {
    StreamingEventAction,
    createFetchStreamingEventSuccessAction,
    createFetchStreamingEventErrorAction,
    createSetStreamingEventMoviesProductsAction,
    SetMoviesProductsAction,
    createSetStreamingEventMoviesContractsAction,
    SetMoviesContractsAction,
    FetchStreamingEventDataAction,
    createSetUserGeoLocationAction,
} from './StreamingEventActions';
import { Movie } from '../../domain/work';
import { map } from 'lodash';
import { AnyAction } from 'redux';
import { VideoProduct } from '../../domain/commerce';
import { LegalContract } from '../../domain/legal';
import { StreamingEventStateData } from './StreamingEventReducer';

function getUserGeoLocation(): AxiosPromise<AxiosResponse> {
    return axios.get('/geoip');
}

function* fetchStreamingEvent(action: StreamingEventAction): SagaIterator {
    yield call(callApi, {
        endpoint: { url: `streaming-events/${action.id}` },
        onSuccess: (streamingEvent: AxiosResponse<StreamingEvent>): StreamingEventAction => {
            return createFetchStreamingEventSuccessAction(streamingEvent.data as StreamingEventStateData);
        },
        onError: createFetchStreamingEventErrorAction,
    });
}

function* fetchStreamingEventBySlug(action: StreamingEventAction): SagaIterator {
    yield call(callApi, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        endpoint: { url: 'streaming-events', params: { q_slug: action.slug } },
        onSuccess: (
            streamingEvents: AxiosResponse<EmbeddedEntities<'streamingEvents', StreamingEvent>>
        ): StreamingEventAction => {
            const streamingEvent = first(
                getEmbeddedEntities(streamingEvents.data, 'streamingEvents')
            ) as StreamingEventStateData;
            return createFetchStreamingEventSuccessAction(streamingEvent);
        },
        onError: createFetchStreamingEventErrorAction,
    });
}

function* fetchUserGeoLocation(): SagaIterator {
    const geoipResponse = yield call(getUserGeoLocation);
    const geoLocation = geoipResponse.data.geoLocation;
    yield put(createSetUserGeoLocationAction(geoLocation));
}

function* fetchStreamingEventMoviesProducts(action: FetchStreamingEventDataAction): SagaIterator {
    if (action.payload == null) {
        return;
    }
    yield call(callApi, {
        endpoint: (action.payload as StreamingEvent).works!.map(
            (movie: Movie): string => `movies/${movie.id}/products`
        ),
        onSuccess: (
            ...responses: AxiosResponse<EmbeddedEntities<'videoProducts', VideoProduct>>[]
        ): SetMoviesProductsAction => {
            return createSetStreamingEventMoviesProductsAction(
                map(responses, (response): VideoProduct[] => getEmbeddedEntities(response.data, 'videoProducts'))
            );
        },
        onError: (): AnyAction => ({
            type: 'NO_OP',
        }),
    });
}

function* fetchStreamingEventMoviesContracts(action: FetchStreamingEventDataAction): SagaIterator {
    if (action.payload == null) {
        return;
    }
    yield call(callApi, {
        endpoint: (action.payload as StreamingEvent).works!.map(
            (movie: Movie): string => `movies/${movie.id}/contracts`
        ),
        onSuccess: (
            ...responses: AxiosResponse<EmbeddedEntities<'legalContracts', LegalContract>>[]
        ): SetMoviesContractsAction => {
            return createSetStreamingEventMoviesContractsAction(
                map(responses, (response): LegalContract[] => getEmbeddedEntities(response.data, 'legalContracts'))
            );
        },
        onError: (): AnyAction => ({
            type: 'NO_OP',
        }),
    });
}

export default function* cycleSaga(): SagaIterator {
    yield takeLatest(StreamingEventActionType.FETCH_STREAMING_EVENT, fetchStreamingEvent);
    yield takeLatest(StreamingEventActionType.FETCH_STREAMING_EVENT_BY_SLUG, fetchStreamingEventBySlug);

    yield takeLatest(StreamingEventActionType.FETCH_STREAMING_EVENT_DATA, fetchUserGeoLocation);
    yield takeLatest(StreamingEventActionType.FETCH_STREAMING_EVENT_DATA, fetchStreamingEventMoviesProducts);
    yield takeLatest(StreamingEventActionType.FETCH_STREAMING_EVENT_DATA, fetchStreamingEventMoviesContracts);
}
