import { FaqCategoryActionType } from './FaqCategoryActionType';
import { FaqCategoryAction } from './FaqCategoryActions';
import { FetchState } from '../Fetch';
import { FaqCategory } from '../../domain/editorial';
import { RequestError } from '../../sagas/APICallSaga';

export type FaqCategoryState = FetchState<FaqCategory>;

export const initialState: FaqCategoryState = {
    isLoading: false,
};

export const FaqCategoryReducer = (
    state: FaqCategoryState = initialState,
    action: FaqCategoryAction
): FaqCategoryState => {
    switch (action.type) {
        case FaqCategoryActionType.FETCH_FAQ_CATEGORY:
            return {
                ...state,
                isLoading: true,
            };

        case FaqCategoryActionType.FETCH_FAQ_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload as FaqCategory,
                error: undefined,
            };

        case FaqCategoryActionType.FETCH_FAQ_CATEGORY_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
