import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './FaqCategoriesActions';
import { FaqCategoriesActionType } from './FaqCategoriesActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
function* fetchFaqCategories(action: actions.FaqCategoriesAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'faq-categories',
            method: 'GET',
            params: { size: action.pageSize, page: action.requestedPage },
        },
        onSuccess: (result): actions.FaqCategoriesAction =>
            actions.createFetchCategoriesSuccessAction(result.data._embedded.faqCategories),
        onError: actions.createFetchCategoriesErrorAction,
    });
}

export default function* faqSaga(): SagaIterator {
    yield takeLatest(FaqCategoriesActionType.FETCH_FAQ_CATEGORIES, fetchFaqCategories);
}
