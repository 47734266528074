import { SerieActionType } from './SerieActionType';
import { SerieAction } from './SerieActions';
import { FetchState } from '../Fetch';
import { MovieStateData } from '../movie';
import { RequestError } from '../../sagas/APICallSaga';

export interface SerieStateData extends MovieStateData {
    episodes?: MovieStateData[];
    literalDuration?: string;
    customizationMainColor?: string;
}

export type SerieState = FetchState<SerieStateData>;

export const initialState: SerieState = {
    isLoading: false,
};

export const serieReducer = (state: SerieState = initialState, action: SerieAction): SerieState => {
    switch (action.type) {
        case SerieActionType.FETCH_SERIE:
            return {
                ...state,
                isLoading: true,
            };
        case SerieActionType.FETCH_SERIE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as SerieStateData,
            };
        case SerieActionType.FETCH_SERIE_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };
        case SerieActionType.FETCH_EPISODES_PRODUCTS:
            return {
                ...state,
                isLoading: true,
                error: undefined,
                data: action.payload as SerieStateData,
            };
        case SerieActionType.FETCH_EPISODES_PRODUCTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as SerieStateData,
            };
        default:
            return state;
    }
};
