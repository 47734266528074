import { Catalog } from '../../domain/editorial';
import { CatalogsActionType } from './CatalogsActionType';
import { CatalogsAction } from './CatalogsActions';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type CatalogsState = FetchState<Catalog[]>;

export const initialState: CatalogsState = {
    isLoading: false,
};

export const catalogsReducer = (state: CatalogsState = initialState, action: CatalogsAction): CatalogsState => {
    switch (action.type) {
        case CatalogsActionType.FETCH_CATALOGS:
            return {
                ...state,
                isLoading: true,
            };

        case CatalogsActionType.FETCH_CATALOGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as Catalog[],
            };

        case CatalogsActionType.FETCH_CATALOGS_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
