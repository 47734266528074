import { ContactActionType } from './ContactActionType';
import { RequestError } from '../../sagas/APICallSaga';
import { HelpRequest } from '../../domain/help';

export interface ContactAction {
    type: ContactActionType;
    payload?: HelpRequest;
    error?: RequestError;
}

export function createAddContactAction(contactRequest: HelpRequest): ContactAction {
    return {
        type: ContactActionType.ADD_CONTACT,
        payload: contactRequest,
    };
}

export function createAddContactSuccessAction(): ContactAction {
    return {
        type: ContactActionType.ADD_CONTACT_SUCCESS,
    };
}

export function createAddContactErrorAction(error: RequestError): ContactAction {
    return {
        type: ContactActionType.ADD_CONTACT_ERROR,
        error,
    };
}
