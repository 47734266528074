export enum ScreeningsActionType {
    FETCH_SCREENINGS = 'SCREENINGS/FETCH',
    FETCH_SCREENINGS_SUCCESS = 'SCREENINGS/FETCH_SUCCESS',
    FETCH_SCREENINGS_ERROR = 'SCREENINGS/FETCH_ERROR',

    FETCH_CONTRACTS_SUCCESS = 'SCREENINGS/FETCH_CONTRACTS_SUCCESS',

    ADD_SCREENING = 'SCREENINGS/ADD',
    ADD_SCREENING_SUCCESS = 'SCREENINGS/ADD_SUCCESS',
    ADD_SCREENING_ERROR = 'SCREENINGS/ADD_ERROR',
    RESET_ADD_SCREENING_SUCCESS = 'SCREENINGS/RESET_ADD_SUCCESS',

    EDIT_SCREENING = 'SCREENINGS/EDIT',
    EDIT_SCREENING_SUCCESS = 'SCREENINGS/EDIT_SUCCESS',
    EDIT_SCREENING_ERROR = 'SCREENINGS/EDIT_ERROR',
}
