import { FreeMoviesActionType } from './FreeMoviesActionType';
import { Action } from 'redux';
import { Movie } from '../../domain/work';
import { MovieStateData } from '../movie';

export type FreeMoviesAction = Action<FreeMoviesActionType>;
export interface FreeMoviesWatchRequestAction extends FreeMoviesAction {
    payload: Partial<MovieStateData>;
}

export function createWatchRequestAction(movie: Movie): FreeMoviesWatchRequestAction {
    return {
        type: FreeMoviesActionType.WATCH_REQUEST,
        payload: movie,
    };
}

export function createWatchRequestSuccesAction(movie: Partial<MovieStateData>): FreeMoviesWatchRequestAction {
    return {
        type: FreeMoviesActionType.WATCH_REQUEST_SUCCESS,
        payload: movie,
    };
}

export function createTogglePlayerModalAction(): FreeMoviesAction {
    return { type: FreeMoviesActionType.TOGGLE_PLAYER_MODAL };
}
