import React, { FunctionComponent } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../common/ModalDialog';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { WithTranslation } from 'next-i18next';
import { withTranslation } from 'react-i18next';
import { ActionButton } from '../../common/Button';
import { Router } from '../../../i18n';

library.add(faTimes);

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
}

type AlreadyInCartErrorModalProps = OwnProps & WithTranslation;

export const _AlreadyInCartErrorModal: FunctionComponent<AlreadyInCartErrorModalProps> = ({
    t,
    isOpen,
    onClose,
}: AlreadyInCartErrorModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('alreadyInCartErrorTitle')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
        >
            <ModalDialogHeader className="AlreadyInCartErrorModal-Header" onClose={handleClose} t={t}>
                <FontAwesomeIcon
                    size="1x"
                    aria-hidden="true"
                    icon={faTimes}
                    className="AlreadyInCartErrorModal-Header-Icon"
                />
                {t('alreadyInCartErrorTitle')}
            </ModalDialogHeader>
            <ModalDialogBody className="AlreadyInCartErrorModal-Body">
                <ActionButton
                    text={t('goToMyOrder')}
                    className="AlreadyInCartErrorModal-ActionButton"
                    onClick={(): void => {
                        Router.push('/cart');
                        handleClose();
                    }}
                />
            </ModalDialogBody>
        </ModalDialog>
    );
};

export const AlreadyInCartErrorModal = withTranslation('cart')(_AlreadyInCartErrorModal);
