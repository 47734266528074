/* eslint-disable react/jsx-no-literals */
import React, { FunctionComponent } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../common/ModalDialog';
import { CreditsRequestForm } from './CreditsRequestForm';
import { withTranslation } from '../../i18n';
import { WithTranslation } from 'next-i18next';
import { CreditRequest } from '../../domain/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    CreditsRequestAction,
    createSubmitCreditsRequestAction,
    CreditsRequestState,
} from '../../modules/creditsRequest';
import noop from 'lodash/noop';
import { AppState } from '../../store/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import './CreditsRequestModal.scss';

library.add(faCheck);

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
}

type StateProps = CreditsRequestState;
interface DispatchProps {
    onSubmitRequest: (request: CreditRequest) => CreditsRequestAction;
}

export type CreditsRequestModalProps = OwnProps & StateProps & DispatchProps & WithTranslation;

const _CreditsRequestModal: FunctionComponent<CreditsRequestModalProps> = ({
    isOpen,
    onClose,
    t,
    onSubmitRequest,
    requestSubmitted,
    error,
}: CreditsRequestModalProps): JSX.Element | null => {
    let title = '';
    if (requestSubmitted) {
        title = t('credits:requestSubmitted');
    } else if (error !== undefined) {
        title = t('credits:requestSubmittedError');
    } else {
        title = t('credits:requestNewCredits');
    }

    return (
        <ModalDialog isOpen={!!isOpen} ariaLabel={title} onEscKeyPress={onClose!} onOutsideClick={onClose}>
            <ModalDialogHeader className="CreditsRequestModal-Header" onClose={onClose} t={t}>
                {requestSubmitted && error === undefined && (
                    <FontAwesomeIcon
                        size="1x"
                        aria-hidden="true"
                        icon={faCheck}
                        className="CreditsRequestModal-Header-Icon"
                    />
                )}
                {title}
            </ModalDialogHeader>
            <ModalDialogBody>
                {requestSubmitted && (
                    <p className="CreditsRequestModal-Confirmation">
                        {t('credits:requestAcknowledgedFirstPart')}
                        <br />
                        {t('credits:requestAcknowledgedSecondPart')}
                    </p>
                )}
                {error !== undefined && (
                    <p className="CreditsRequestModal-Confirmation">
                        {t('credits:requestSubmittedErrorOccured')}
                        <br />
                        <span>{`${error.status} : ${error.statusText}`}</span>
                        <br />
                        {t('credits:contactSupport')}
                    </p>
                )}
                {!requestSubmitted && error === undefined && <CreditsRequestForm t={t} onSubmit={onSubmitRequest} />}
            </ModalDialogBody>
        </ModalDialog>
    );
};

_CreditsRequestModal.defaultProps = {
    onClose: noop,
};

_CreditsRequestModal.displayName = 'CreditsRequestModal';

const mapStateToProps = (state: AppState): CreditsRequestState => state.creditsRequest;
const mapDispatchToProps: DispatchProps = {
    onSubmitRequest: createSubmitCreditsRequestAction,
};

export const CreditsRequestModal = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('credits')
)(_CreditsRequestModal) as FunctionComponent<OwnProps>;
