import isFunction from 'lodash/isFunction';

// Needed for IE that does not support Array.from
export function nodeListToArray<T extends HTMLElement>(nodeList: NodeListOf<T>): T[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (isFunction((Array as any).from)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (Array as any).from(nodeList);
    } else {
        return Array.prototype.slice.call(nodeList);
    }
}
