import { SagaIterator } from 'redux-saga';
import { all, call, CallEffect } from 'redux-saga/effects';

export function* rootSaga(): SagaIterator {
    // const context = require.context('../modules/', true, /.*Saga$/);
    const context = (require as any).context('../modules/', true, /.*Saga$/);
    const callAllSagas = (req: any): CallEffect[] =>
        // const callAllSagas = (req: __WebpackModuleApi.RequireContext): CallEffect[] =>
        req.keys().map((key: string): CallEffect => call(req(key).default));
    yield all(callAllSagas(context));
}
