import { OrdersType } from './OrdersActionType';
import { FetchAction } from '../Fetch';
import { Order, OrderLine } from '../../domain/commerce';
import { RequestError } from '../../sagas/APICallSaga';

export type OrdersAction = FetchAction<OrdersType, Order[]>;

export type OrdersLinesAction = FetchAction<OrdersType, OrderLine[]>;

export function createFetchOrdersAction(): OrdersAction {
    return {
        type: OrdersType.FETCH_ORDERS,
    };
}

export function createFetchOrdersSuccessAction(orders: Order[]): OrdersAction {
    return {
        type: OrdersType.FETCH_ORDERS_SUCCESS,
        payload: orders,
    };
}

export function createFetchOrdersErrorAction(error: RequestError): OrdersAction {
    return {
        type: OrdersType.FETCH_ORDERS_ERROR,
        payload: error,
    };
}

export function createFetchOrderLinesAction(): OrdersAction {
    return {
        type: OrdersType.FETCH_ORDER_LINES,
    };
}

export function createFetchOrderLinesSuccessAction(orderLines: OrderLine[]): OrdersLinesAction {
    return {
        type: OrdersType.FETCH_ORDER_LINES_SUCCESS,
        payload: orderLines,
    };
}

export function createFetchOrderLinesErrorAction(error: RequestError): OrdersLinesAction {
    return {
        type: OrdersType.FETCH_ORDER_LINES_ERROR,
        payload: error,
    };
}
