import React from 'react';
import { SearchSuggestionsProps } from './index';
import size from 'lodash/size';
import { NonBreakingWhiteSpace } from '../../common/NonBreakingSpace';

const openingDoubleQuote = '«';
const closingDoubleQuote = '»';

export const SearchSuggestionsStatus = ({
    terms,
    movies,
    cycles,
    isLoading,
    query,
    t,
}: SearchSuggestionsProps): JSX.Element | null => {
    if (size(terms) > 0 || size(movies) > 0 || size(cycles) > 0) {
        return null;
    }
    return (
        <div className="SearchSuggestions-Status">
            {isLoading ? (
                t('common:loading')
            ) : (
                <span>
                    {t('search:noResultFoundFor')}
                    <NonBreakingWhiteSpace />
                    {openingDoubleQuote}
                    <NonBreakingWhiteSpace />
                    <span className="SearchSuggestions-Status-Query">{query}</span>
                    <NonBreakingWhiteSpace />
                    {closingDoubleQuote}
                </span>
            )}
        </div>
    );
};
