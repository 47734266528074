import React, { FunctionComponent, useEffect } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../common/ModalDialog';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import chroma from 'chroma-js';
import { ConnectionForm } from '../ConnectionForm';
import { Link } from '../common/A11y/Link';
import { authenticationRequest, AuthAction } from '../../modules/auth/AuthActions';
import pick from 'lodash/pick';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { ActionCreator, compose } from 'redux';
import { AuthState, LoginStatus } from '../../modules/auth';
import { Router } from '../../i18n';
import { WithTranslation } from 'next-i18next';
import { withTranslation } from 'react-i18next';

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
}

type StateProps = Pick<AuthState, 'loginStatus' | 'authError' | 'isAuthenticating'>;
interface DispatchProps {
    authenticationRequest: ActionCreator<AuthAction>;
}

export type ConnectionModalProps = OwnProps & StateProps & DispatchProps & WithTranslation;

const ConnectionModalInner: FunctionComponent<ConnectionModalProps> = ({
    t,
    isOpen,
    onClose,
    authenticationRequest,
    loginStatus,
    authError,
    isAuthenticating,
}: ConnectionModalProps): JSX.Element => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    useEffect((): void => {
        if (loginStatus === LoginStatus.AUTHENTICATED && isOpen) {
            handleClose();
        }
    }, [handleClose, isOpen, loginStatus]);
    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel="connexion"
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
            transparentOverlayColor={chroma('black')}
            className="ConnectionModal"
        >
            <ModalDialogHeader className="ConnectionModal-Header" onClose={handleClose} t={t}>
                <img
                    src="/static/img/logo_ifcinema.svg"
                    className="ConnectionModal-Header-Title"
                    alt={t('header:logoAlt')}
                />
            </ModalDialogHeader>
            <ModalDialogBody>
                <h2 className="ConnectionModal-Title">{t('registration:welcomeToIFC')}</h2>
                <ConnectionForm
                    authenticationRequestActionCreator={authenticationRequest}
                    authError={authError}
                    isAuthenticating={isAuthenticating}
                    loginStatus={loginStatus}
                    t={t}
                />
                <Link href="/password-reset" className="ConnectionModal-ForgottenPassword">
                    {t('registration:passwordForgotten')}
                </Link>
            </ModalDialogBody>
            <div className="ConnectionModal-Footer">
                {t('registration:subscribe')}
                <Link
                    className="ConnectionModal-CreateAccountButton button is-stroke-effect"
                    href="/registration"
                    onClick={(): void => {
                        handleClose();
                        Router.push('/registration');
                    }}
                >
                    <span className="ConnectionModal-CreateAccountButton-Label">{t('registration:createAccount')}</span>
                </Link>
            </div>
        </ModalDialog>
    );
};

export const ConnectionModal = compose(
    connect(
        (state: AppState): StateProps => pick(state.auth, 'loginStatus', 'authError', 'isAuthenticating'),
        { authenticationRequest }
    ),
    withTranslation(['common', 'registration'])
)(ConnectionModalInner) as FunctionComponent<OwnProps>;
