import React, { FunctionComponent } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../common/ModalDialog';
import last from 'lodash/last';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CartEntryCard } from '../CartEntryCard';
import { CartEntry } from '../../../domain/commerce';
import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { WithTranslation } from 'next-i18next';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { ActionButton } from '../../common/Button';
import { Router } from '../../../i18n';

library.add(faCheck);

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
}

interface StateProps {
    entry: CartEntry;
}

type SuccessfullyAddedToCartModalProps = OwnProps & StateProps & WithTranslation;

export const _SuccessfullyAddedToCartModal: FunctionComponent<SuccessfullyAddedToCartModalProps> = ({
    t,
    isOpen,
    onClose,
    entry,
}: SuccessfullyAddedToCartModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('cart:addedToBasket')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
        >
            <ModalDialogHeader className="SuccessfullyAddedToCartModal-Header" onClose={handleClose} t={t}>
                <FontAwesomeIcon
                    size="1x"
                    aria-hidden="true"
                    icon={faCheck}
                    className="SuccessfullyAddedToCartModal-Header-Icon"
                />
                {t('cart:addedToBasket')}
            </ModalDialogHeader>
            <ModalDialogBody className="SuccessfullyAddedToCartModal-Body">
                <CartEntryCard
                    entry={entry}
                    t={t}
                    className="SuccessfullyAddedToCartModal-CartEntryCard"
                    moviePosterWidth={120}
                    moviePosterHeight={160}
                />
                <ActionButton
                    text={t('goToMyOrder')}
                    className="SuccessfullyAddedToCartModal-ActionButton"
                    onClick={(): void => {
                        Router.push('/cart');
                        handleClose();
                    }}
                />
            </ModalDialogBody>
        </ModalDialog>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({ entry: last(state.cart.items) as CartEntry });

export const SuccessfullyAddedToCartModal = compose(
    connect(mapStateToProps),
    withTranslation('cart')
)(_SuccessfullyAddedToCartModal) as FunctionComponent<OwnProps>;
