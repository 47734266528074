import React, { SFC } from 'react';
import AriaRole from '../../../AriaRole';
import classnames from 'clsx';

/**
 * Accessible tab component to be used within TabList.
 * Follows https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs.html
 **/
export interface TabProps extends React.Props<React.ReactInstance> {
    className?: string;
    onClick?: (e: React.SyntheticEvent) => void;
    ariaLabel?: string;
    title?: string;

    /**
     * ariaSelected is a mandatory prop on this component. It must be given
     * for a screen reader to narrate the status of the tab.
     */
    ariaSelected: boolean;

    ariaDisabled?: boolean;

    /**
     * Below 2 are needed for Edge & Narrator
     *
     * ariaPosinset: the index of the current tab. Authors MUST set the value
     * for aria-posinset to an integer greater than or equal to 1, and less than
     * or equal to the size of the set when that size is known.
     *
     * ariaSetsize: the number of tabs
     */
    ariaPosinset: number;
    ariaSetsize: number;
}

export const Tab: SFC<TabProps> = ({
    onClick,
    title,
    ariaSelected,
    ariaLabel,
    ariaPosinset,
    ariaSetsize,
    ariaDisabled,
    children,
    className,
}: TabProps): JSX.Element => {
    const classname: string = classnames('Tab', className);
    return (
        <button
            className={classname}
            role={AriaRole.TAB}
            onClick={onClick}
            title={title}
            aria-selected={ariaSelected}
            aria-label={ariaLabel}
            aria-posinset={ariaPosinset}
            aria-setsize={ariaSetsize}
            aria-disabled={ariaDisabled}
            disabled={ariaDisabled}
        >
            {children}
        </button>
    );
};
