export enum PlaylistsActionType {
    FETCH_PLAYLISTS = 'PLAYLISTS/FETCH',
    FETCH_PLAYLISTS_SUCCESS = 'PLAYLISTS/FETCH_SUCCESS',
    FETCH_PLAYLISTS_ERROR = 'PLAYLISTS/FETCH_ERROR',

    UPDATE_PLAYLIST = 'PLAYLIST/UPDATE',
    UPDATE_PLAYLIST_SUCCESS = 'PLAYLIST/UPDATE_SUCCESS',
    UPDATE_PLAYLIST_ERROR = 'PLAYLIST/UPDATE_ERROR',

    CREATE_PLAYLIST = 'PLAYLIST/CREATE',
    CREATE_PLAYLIST_SUCCESS = 'PLAYLIST/CREATE_SUCCESS',
    CREATE_PLAYLIST_ERROR = 'PLAYLIST/CREATE_ERROR',

    DELETE_PLAYLIST = 'PLAYLIST/DELETE',
    DELETE_PLAYLIST_SUCCESS = 'PLAYLIST/DELETE_SUCCESS',
    DELETE_PLAYLIST_ERROR = 'PLAYLIST/DELETE_ERROR',
}
