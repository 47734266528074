import { FaqCategoryQuestionActionType } from './FaqCategoryQuestionActionType';
import { FaqCategoryQuestionAction } from './FaqCategoryQuestionActions';
import { FetchState } from '../Fetch';
import { FaqCategoryQuestion } from '../../domain/editorial';
import { RequestError } from '../../sagas/APICallSaga';

export type FaqCategoryQuestionState = FetchState<FaqCategoryQuestion>;

export const initialState: FaqCategoryQuestionState = {
    isLoading: false,
};

export const FaqCategoryQuestionReducer = (
    state: FaqCategoryQuestionState = initialState,
    action: FaqCategoryQuestionAction
): FaqCategoryQuestionState => {
    switch (action.type) {
        case FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION:
            return {
                ...state,
                isLoading: true,
            };

        case FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload as FaqCategoryQuestion,
                error: undefined,
            };

        case FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
