import React from 'react';
import { WithT } from 'i18next';
import { Menu } from 'react-bulma-components';
import { Link } from '../../i18n';
import { PanelCatalog } from './PanelCatalog';
import PanelHelp from './PanelHelp';

export type PanelMenuMobileProps = WithT;

export default class PanelMenuMobile extends React.Component<PanelMenuMobileProps> {
    public render(): JSX.Element {
        const { t } = this.props;
        return (
            <Menu renderAs="nav" role="navigation" aria-label="Menu principal">
                <Menu.List className="first-level">
                    <Menu.List.Item renderAs="div" className="menu-item">
                        <Link href="/">
                            <a>{t('common:home')}</a>
                        </Link>
                    </Menu.List.Item>
                    <Menu.List.Item renderAs="div" className="menu-item">
                        <PanelCatalog t={t} />
                    </Menu.List.Item>
                    <Menu.List.Item renderAs="div" className="menu-item">
                        <Link href="/cycles">
                            <a>{t('common:cycles')}</a>
                        </Link>
                    </Menu.List.Item>
                    <Menu.List.Item renderAs="div" className="menu-item">
                        <Link href="/?section=collections">
                            <a>{t('homepage:collections')}</a>
                        </Link>
                    </Menu.List.Item>
                    <Menu.List.Item renderAs="div" className="menu-item">
                        <Link href="/education">
                            <a>{t('homepage:education')}</a>
                        </Link>
                    </Menu.List.Item>
                    <Menu.List.Item renderAs="div" className="menu-item">
                        <PanelHelp t={t} />
                    </Menu.List.Item>
                </Menu.List>
            </Menu>
        );
    }
}
