import { createStore, applyMiddleware, Store, Middleware, StoreEnhancer, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { Task } from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { rootReducer, AppState } from './reducers';
import { rootSaga } from './sagas';
import nextReduxWrapper from 'next-redux-wrapper';
import withReduxEnhancer from 'addon-redux/enhancer';

export interface StoreWithSaga extends Store<AppState> {
    sagaTask: Task;
}

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
    if (process.env.NODE_ENV !== 'production') {
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export const initStore = (
    preloadedState?: AppState,
    options?: nextReduxWrapper.StoreCreatorOptions<{}, {}, {}, {}, {}>
): StoreWithSaga => {
    const sagaMiddleware = createSagaMiddleware();

    const enhancers: any[] = [];
    enhancers.push(bindMiddleware([thunkMiddleware, sagaMiddleware]));
    if (process.env.NODE_ENV !== 'production') {
        enhancers.push(withReduxEnhancer);
    }
    const enhancer: any = compose(...enhancers);

    const store = createStore(rootReducer, preloadedState, enhancer) as StoreWithSaga;

    if (options && (options.req || !options.isServer)) {
        store.sagaTask = sagaMiddleware.run(rootSaga);
    }

    return store;
};
