import React, { Component, SyntheticEvent } from 'react';
import { ActionCreator } from 'redux';
import { AuthAction, AuthState, tokenSuccess, TokenStatus } from '../../modules/auth';
import { connect } from 'react-redux';
import { AppState } from '../../store/reducers';
import pick from 'lodash/pick';
import { stringify } from 'qs';
import { AUTH_ENDPOINT } from '../../modules/auth/AuthService';

type StateProps = Pick<AuthState, 'authenticatedOnOAuthServer' | 'tokenStatus'>;
interface DispatchProps {
    tokenSuccess: ActionCreator<AuthAction>;
}
type OAuthIFrameProps = StateProps & DispatchProps;

interface OAuthIFrameState {
    isFetchingToken: boolean;
}

const OAUTH_AUTHORIZE_URL: string = process.env.OAUTH_AUTHORIZE_URL || `${AUTH_ENDPOINT}/oauth/authorize`;

const OAUTH_CLIENT_ID: string = process.env.OAUTH_CLIENT_ID || 'ifcinema-front-dev';

class OAuthIFrame extends Component<OAuthIFrameProps, OAuthIFrameState> {
    private iframe: HTMLIFrameElement | null = null;

    public constructor(props: OAuthIFrameProps) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleRef = this.handleRef.bind(this);
        this.state = {
            isFetchingToken: false,
        };
    }

    private checkForExpiredToken(): void {
        if (
            this.iframe &&
            this.props.authenticatedOnOAuthServer &&
            this.props.tokenStatus === TokenStatus.EXPIRED &&
            !this.state.isFetchingToken
        ) {
            /* eslint-disable @typescript-eslint/camelcase */
            const location = `${OAUTH_AUTHORIZE_URL}?${stringify({
                client_id: OAUTH_CLIENT_ID,
                response_type: 'token',
                redirect_uri: window.location.origin + '/static/img/transparent.png',
            })}`;
            /* eslint-enable @typescript-eslint/camelcase */
            this.iframe.src = location;
            this.setState({ isFetchingToken: true });
        }
    }

    private handleRef(iframe: HTMLIFrameElement): void {
        this.iframe = iframe;
    }

    public componentDidMount(): void {
        this.checkForExpiredToken();
    }

    public componentDidUpdate(): void {
        this.checkForExpiredToken();
    }

    private extractTokenFromUrl(url: string): string | null {
        const matches = url.match(/#access_token=(.*?)&/);
        return matches && matches.length > 1 ? matches[1] : null;
    }

    private handleLoad(_event: SyntheticEvent<HTMLIFrameElement>): void {
        this.setState({ isFetchingToken: false });
        if (this.iframe === null) {
            return;
        }
        const accessToken = this.extractTokenFromUrl(this.iframe!.contentWindow!.location.href);
        if (accessToken != null) {
            this.props.tokenSuccess(accessToken);
        }
    }

    public render(): JSX.Element {
        return (
            <iframe
                style={{ display: 'none' }}
                onLoad={this.handleLoad}
                src="about:blank"
                ref={this.handleRef}
                title="Connection Iframe"
            />
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => pick(state.auth, 'authenticatedOnOAuthServer', 'tokenStatus');
const mapDispatchToProps = { tokenSuccess };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OAuthIFrame);
