import React, { FunctionComponent, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { ModalDialog, ModalDialogBody, ModalDialogHeader } from '../../common/ModalDialog';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import chroma from 'chroma-js';
import { AppState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { compose, ActionCreator } from 'redux';
import { WithTranslation, TFunction } from 'next-i18next';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import { ActionButton } from '../../common/Button';
import { ReactivateAccountForm } from '../ReactivateAccountForm';
import { ReactivateAccountRequestAction, createReactivateAccountRequestAction } from '../../../modules/profile';

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
}

interface StateProps {
    givenName?: string;
    email?: string;
    requestSuccess: boolean;
}
interface DispatchProps {
    reactivateAccountRequest: ActionCreator<ReactivateAccountRequestAction>;
}

export type ReactivateAccountModalProps = OwnProps & StateProps & DispatchProps & WithTranslation;

enum ReactivateAccountStep {
    NOTICE,
    FORM,
    CONFIRMATION,
}

const renderNotice = (
    step: ReactivateAccountStep,
    setStep: Dispatch<SetStateAction<ReactivateAccountStep>>,
    t: TFunction
): JSX.Element | null => {
    if (step !== ReactivateAccountStep.NOTICE) {
        return null;
    }
    return (
        <>
            <h2 className="ReactivateAccountModal-Subtitle">{t('reactivation:yourAccountIsDisabled')}</h2>
            <p className="ReactivateAccountModal-Description">{t('reactivation:accountDisabledDescription')}</p>
            <ActionButton
                className="ReactivateAccountModal-ActionButton light"
                text={t('reactivation:reactivateMyAccount')}
                onClick={(): void => setStep(ReactivateAccountStep.FORM)}
            />
        </>
    );
};

const renderForm = (
    step: ReactivateAccountStep,
    t: TFunction,
    onSubmit: ActionCreator<ReactivateAccountRequestAction>
): JSX.Element | null => {
    if (step !== ReactivateAccountStep.FORM) {
        return null;
    }
    return <ReactivateAccountForm t={t} onSubmit={onSubmit} />;
};

const renderConfirmation = (step: ReactivateAccountStep, t: TFunction, email?: string): JSX.Element | null => {
    if (step !== ReactivateAccountStep.CONFIRMATION) {
        return null;
    }
    return <p className="ReactivateAccountModal-Confirmation">{t('reactivation:confirmation', { email })}</p>;
};

const renderTitle = (step: ReactivateAccountStep, t: TFunction, givenName?: string): string => {
    switch (step) {
        case ReactivateAccountStep.NOTICE:
            return givenName ? `${givenName},` : t('reactivation:hello,');

        case ReactivateAccountStep.FORM:
            return t('reactivation:reactivateYourAccount');

        case ReactivateAccountStep.CONFIRMATION:
            return t('reactivation:requestSent');

        default:
            return '';
    }
};

const ReactivateAccountModalInner: FunctionComponent<ReactivateAccountModalProps> = ({
    t,
    isOpen,
    onClose,
    givenName,
    email,
    reactivateAccountRequest,
    requestSuccess,
}: ReactivateAccountModalProps): JSX.Element => {
    const [step, setStep] = useState<ReactivateAccountStep>(ReactivateAccountStep.NOTICE);
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    useEffect((): void => {
        if (requestSuccess === true) {
            setStep(ReactivateAccountStep.CONFIRMATION);
        }
    }, [requestSuccess]);
    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('reactivation:reactivateMyAccount')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
            transparentOverlayColor={chroma('black')}
            className="ReactivateAccountModal"
        >
            <ModalDialogHeader onClose={handleClose} t={t}>
                <h1 className="ReactivateAccountModal-Title">{renderTitle(step, t, givenName)}</h1>
            </ModalDialogHeader>
            <ModalDialogBody>
                {renderNotice(step, setStep, t)}
                {renderForm(step, t, reactivateAccountRequest)}
                {renderConfirmation(step, t, email)}
            </ModalDialogBody>
        </ModalDialog>
    );
};

export const ReactivateAccountModal = compose(
    connect(
        (state: AppState): StateProps => ({
            givenName: get(state, 'profile.data.givenName'),
            email: get(state, 'profile.data.email'),
            requestSuccess: state.profile.reactivateAccountRequestSent,
        }),
        {
            reactivateAccountRequest: createReactivateAccountRequestAction,
        }
    ),
    withTranslation('reactivation')
)(ReactivateAccountModalInner) as FunctionComponent<OwnProps>;
