import { FetchState } from '../Fetch';
import { Centre, CentreType, UserJob } from '../../domain/user';
import { combineReducers } from 'redux';
import { centresReducer } from './centres';
import { centreTypesReducer } from './centreTypes';
import { userJobsReducer } from './userJobs';

export type CentresState = FetchState<Centre[]>;
export type CentreTypesState = FetchState<CentreType[]>;
export type UserJobsState = FetchState<UserJob[]>;

export interface UserFormDataState {
    centres: CentresState;
    centreTypes: CentreTypesState;
    userJobs: UserJobsState;
}

export const userFormDataReducer = combineReducers<UserFormDataState>({
    centres: centresReducer,
    centreTypes: centreTypesReducer,
    userJobs: userJobsReducer,
});
