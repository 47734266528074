import { takeEvery, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { CookiesActionType } from './CookiesActionType';
import { CookiesAction, createGetCookiesConsentSuccessAction, GetCookiesConsentAction } from './CookiesActions';
import { CookiesConsent } from './CookiesStateReducer';
import { getCookie, setCookie } from '../../utils/CookieUtils';

const CONSENT_COOKIE_NAME = 'cookies_consent';

function* setCookiesConsent(action: CookiesAction): SagaIterator {
    const consent = action.type === CookiesActionType.ACCEPT ? CookiesConsent.ACCEPTED : CookiesConsent.DECLINED;
    yield call(setCookie, CONSENT_COOKIE_NAME, consent.toString(), 30 * 12); // 12 months
}

function* getCookiesConsent(action: GetCookiesConsentAction): SagaIterator {
    const consent = yield call(getCookie, CONSENT_COOKIE_NAME, action.cookies);
    yield put(createGetCookiesConsentSuccessAction(consent));
}

export default function* cookiesSaga(): SagaIterator {
    yield takeEvery(CookiesActionType.GET_CONSENT, getCookiesConsent);
    yield takeEvery([CookiesActionType.ACCEPT, CookiesActionType.DECLINE], setCookiesConsent);
}
