import { takeLatest, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { callApi } from '../../sagas/APICallSaga';

import { CurrentEventsActionType } from './CurrentEventsActionType';
import {
    CurrentEventsAction,
    createFetchCurrentEventsSuccessAction,
    createFetchCurrentEventsErrorAction,
} from './CurrentEventsActions';

function* fetchCurrentEvents(): SagaIterator {
    yield call(callApi, {
        endpoint: { url: 'current-events' },
        onSuccess: (res: AxiosResponse): CurrentEventsAction =>
            createFetchCurrentEventsSuccessAction(get(res, 'data._embedded.currentEvents', [])),
        onError: createFetchCurrentEventsErrorAction,
    });
}

export default function* CurrentEventsSaga(): SagaIterator {
    yield takeLatest(CurrentEventsActionType.FETCH_CURRENT_EVENTS, fetchCurrentEvents);
}
