import React, { useState } from 'react';
import { Panel, Menu } from 'react-bulma-components';
import { Animate } from 'react-show';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, Router } from '../../i18n';
import { WithT } from 'i18next';
import { Catalog } from '../../domain/editorial';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';

library.add(faArrowLeft);

interface StateProps {
    catalogs?: Catalog[];
}

type PanelCatalogProps = WithT & StateProps;

const _PanelCatalog = (props: PanelCatalogProps): JSX.Element | null => {
    const [display, setDisplay] = useState<'none' | 'block'>('none');
    const [openPanelCatalog, setOpenPanelCatalog] = useState(false);

    const { catalogs, t } = props;

    if (catalogs === undefined) {
        return null;
    }

    const hiddenCatalogsCode = catalogs
        .filter((catalog): boolean => catalog.hidden)
        .map((catalog): string => catalog.code);

    const togglePanelCatalog = (): void => {
        if (display === 'none') {
            setDisplay('block');
            setTimeout((): void => setOpenPanelCatalog(!openPanelCatalog), 10);
        }
        if (display === 'block') {
            setOpenPanelCatalog(!openPanelCatalog);
            setTimeout((): void => setDisplay('none'), 350);
        }
    };

    return (
        <Panel.Header>
            <button className="button" onClick={togglePanelCatalog}>
                {t('catalogs')}
            </button>
            <Animate
                style={{ display }}
                className="panel panel-secondary panel-catalog"
                show={openPanelCatalog}
                transitionOnMount={true}
                start={{
                    transform: 'translate3d(100%, 0, 0)',
                }}
            >
                <Panel.Block>
                    <Panel.Header className="panel-header">
                        <button onClick={togglePanelCatalog} className="back button">
                            <FontAwesomeIcon aria-hidden="true" icon={faArrowLeft} />
                            <span className="visually-hidden">{t('backToMenu')}</span>
                        </button>
                        <span>{t('catalogs')}</span>
                    </Panel.Header>
                    <Menu renderAs="nav">
                        <Menu.List className="second-level">
                            {!hiddenCatalogsCode.includes('fr') && (
                                <Menu.List.Item renderAs="li">
                                    <Link href={{ pathname: '/movies', query: { catalogType: 'fr' } }} as="/catalog/fr">
                                        <a>{t('common:cineFR')}</a>
                                    </Link>
                                </Menu.List.Item>
                            )}
                            {!hiddenCatalogsCode.includes('afr') && (
                                <Menu.List.Item renderAs="li">
                                    <Link
                                        href={{ pathname: '/movies', query: { catalogType: 'afr' } }}
                                        as="/catalog/afr"
                                    >
                                        <a>{t('common:cineAF')}</a>
                                    </Link>
                                </Menu.List.Item>
                            )}
                            {!hiddenCatalogsCode.includes('alt') && (
                                <Menu.List.Item renderAs="li">
                                    <Link
                                        href={{ pathname: '/movies', query: { catalogType: 'alt' } }}
                                        as="/catalog/alt"
                                    >
                                        <a>{t('common:altContent')}</a>
                                    </Link>
                                </Menu.List.Item>
                            )}
                        </Menu.List>
                    </Menu>
                    <button
                        className="button is-stroke-effect"
                        onClick={(): Promise<boolean> => Router.push('/movies')}
                    >
                        <span>{t('wholeCatalog')}</span>
                    </button>
                </Panel.Block>
            </Animate>
        </Panel.Header>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    catalogs: state.catalogs.data,
});

export const PanelCatalog = connect(mapStateToProps)(_PanelCatalog);
