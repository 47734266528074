import { ContactActionType } from './ContactActionType';
import { ContactAction } from './ContactActions';
import { FetchState } from '../Fetch';

export type ContactState = FetchState<{}>;

export const initialState: ContactState = {
    isLoading: false,
};

export const contactReducer = (state: ContactState = initialState, action: ContactAction): ContactState => {
    switch (action.type) {
        case ContactActionType.ADD_CONTACT:
            return {
                ...state,
                isLoading: true,
            };

        case ContactActionType.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };

        case ContactActionType.ADD_CONTACT_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.error,
            };

        default:
            return state;
    }
};
