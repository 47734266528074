import { SearchEditorialSuggestionsActionType } from './SearchEditorialSuggestionsActionType';
import { FetchState } from '../Fetch';
import { SearchEditorialSuggestionsAction } from './SearchEditorialSuggestionsActions';
import { RequestError } from '../../sagas/APICallSaga';
import { SearchEditorialSuggestion } from '../../domain/search';

export type SearchEditorialSuggestionsState = FetchState<SearchEditorialSuggestion[]>;

export const initialState: SearchEditorialSuggestionsState = {
    isLoading: false,
};

export const searchEditorialSuggestionsReducer = (
    state: SearchEditorialSuggestionsState = initialState,
    action: SearchEditorialSuggestionsAction
): SearchEditorialSuggestionsState => {
    switch (action.type) {
        case SearchEditorialSuggestionsActionType.FETCH_EDITORIAL_SUGGESTIONS:
            return {
                ...state,
                isLoading: true,
            };

        case SearchEditorialSuggestionsActionType.FETCH_EDITORIAL_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as SearchEditorialSuggestion[],
            };

        case SearchEditorialSuggestionsActionType.FETCH_EDITORIAL_SUGGESTIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
