import { FavoriteActionType } from './FavoriteActionType';
import { RequestError } from '../../sagas/APICallSaga';
import { FavoriteType, Favorite } from '../../domain/community';
import { uuid } from '../../domain/core';
import { FetchAction } from '../Fetch';

export type FavoriteAction<T> = FetchAction<FavoriteActionType, T>;

export interface AddToFavoritesProps {
    favorite: uuid;
    favoriteType: FavoriteType;
}

export interface RemoveFromFavoritesProps {
    favoriteId: uuid;
}

export function createAddToFavoritesAction(payload: AddToFavoritesProps): FavoriteAction<AddToFavoritesProps> {
    return {
        type: FavoriteActionType.ADD_TO_FAVORITES,
        payload,
    };
}

export function createAddToFavoritesSuccessAction(favorite: Favorite): FavoriteAction<Favorite> {
    return {
        type: FavoriteActionType.ADD_TO_FAVORITES_SUCCESS,
        payload: favorite,
    };
}

export function createAddToFavoritesErrorAction(error: RequestError): FavoriteAction<Favorite> {
    return {
        type: FavoriteActionType.ADD_TO_FAVORITES_ERROR,
        payload: error,
    };
}

export function createRemoveFromFavoritesAction(
    payload: RemoveFromFavoritesProps
): FavoriteAction<RemoveFromFavoritesProps> {
    return {
        type: FavoriteActionType.REMOVE_FROM_FAVORITES,
        payload,
    };
}

export function createRemoveFromFavoritesSuccessAction(): FavoriteAction<Favorite> {
    return {
        type: FavoriteActionType.REMOVE_FROM_FAVORITES_SUCCESS,
    };
}

export function createRemoveFromFavoritesErrorAction(error: RequestError): FavoriteAction<Favorite> {
    return {
        type: FavoriteActionType.REMOVE_FROM_FAVORITES_ERROR,
        payload: error,
    };
}
