import React, { FunctionComponent } from 'react';
import { WithT } from 'i18next';
import { FrontUser, ProfileType } from '../../domain/user';
import classNames from 'clsx';
import { OffsetButton } from '../common/Button';
import { CreditsRequestModal } from './CreditsRequestModal';
import { useToggleState } from '../../hooks';
import Breakpoint from '../Breakpoint';

export interface CreditsInfoBoxProps extends WithT {
    user: FrontUser;
    headerElement?: JSX.Element;
    showMoreElement?: JSX.Element;
    className?: string;
}

export const CreditsInfoBox: FunctionComponent<CreditsInfoBoxProps> = ({
    t,
    user,
    headerElement,
    showMoreElement,
    className,
}: CreditsInfoBoxProps): JSX.Element | null => {
    const [isCreditsRequestModalOpen, toggleCreditsRequestModal] = useToggleState(false);

    const isMobile = Breakpoint.getCurrent() === Breakpoint.MOBILE;
    const requestNewCreditsLabelKey = isMobile ? 'credits:requestNewCredits_short' : 'credits:requestNewCredits';
    const isCreditsInfoHidden = user.profileType === ProfileType.IF;

    if (!headerElement && isCreditsInfoHidden) {
        return null;
    }

    return (
        <aside className={classNames('CreditsInfoBox', className, 'tilted')}>
            <div className="tilted-inner">
                {headerElement}
                {isCreditsInfoHidden ? null : (
                    <div className="CreditsInfoBox-Content">
                        <h2>
                            <span>{user.availableCredits || 0}</span>{' '}
                            {t('credits:availableCredits', { count: user.availableCredits || 0 })}
                        </h2>
                        <OffsetButton
                            className="CreditsInfoBox-Content-RequestNewCreditsButton"
                            textClassName="CreditsInfoBox-Content-RequestNewCreditsButton-Text"
                            text={t(requestNewCreditsLabelKey)}
                            onClick={toggleCreditsRequestModal}
                        />
                    </div>
                )}
                {showMoreElement}
            </div>
            <CreditsRequestModal onClose={toggleCreditsRequestModal} isOpen={isCreditsRequestModalOpen} />
        </aside>
    );
};
