import React, { useState } from 'react';
import { WithTranslation } from 'next-i18next';
import { UserGuideModal } from './UserGuideModal';

export type UserGuideProps = WithTranslation;

export function UserGuide({ i18n, t }: UserGuideProps): JSX.Element {
    const [isModalOpen, setModalIsOpen] = useState<boolean>(false);

    return (
        <>
            <a
                href="#"
                onClick={(): void => {
                    setModalIsOpen(true);
                }}
            >
                {t('userGuide')}
            </a>
            <UserGuideModal
                isOpen={isModalOpen}
                onClose={(): void => {
                    setModalIsOpen(false);
                }}
                t={t}
                i18n={i18n}
                tReady={true}
            />
        </>
    );
}
