import { fork, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { callApi } from '../../sagas/APICallSaga';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { Catalog } from '../../domain/editorial';

import { CatalogsAction, createFetchCatalogsErrorAction, createFetchCatalogsSuccessAction } from './CatalogsActions';

function* fetchCatalogs(): SagaIterator {
    yield call(callApi, {
        endpoint: 'movie-catalogs',
        onSuccess: (res: AxiosResponse<EmbeddedEntities<'movieCatalogs', Catalog>>): CatalogsAction => {
            const catalogs = getEmbeddedEntities(res.data, 'movieCatalogs');
            return createFetchCatalogsSuccessAction(catalogs);
        },
        onError: createFetchCatalogsErrorAction,
    });
}

export default function* CatalogsSaga(): SagaIterator {
    yield fork(fetchCatalogs);
}
