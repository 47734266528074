import React from 'react';
import { Dropdown, DropdownItem } from '../common/TypedBulmaComponents';
import { WithT } from 'i18next';
import { Router } from '../../i18n';

type DropdownHelpProps = WithT;

export default class DropdownHelp extends React.Component<DropdownHelpProps> {
    public render(): JSX.Element {
        const { t } = this.props;
        return (
            <Dropdown
                className="is-right dropdown-help"
                hoverable={true}
                onChange={(action: string): void => {
                    // hacky but DropdownItem won't accept "onClick"
                    Router.push(`/${action}`);
                }}
            >
                <DropdownItem renderAs="a" value="help" aria-expanded="false">
                    {t('help')}
                </DropdownItem>
                <DropdownItem renderAs="a" value="faq">
                    {t('faq')}
                </DropdownItem>
                <DropdownItem renderAs="a" value="contact">
                    {t('common:contactUsInfinitive')}
                </DropdownItem>
            </Dropdown>
        );
    }
}
