import React from 'react';
import { SearchSuggestions, SuggestedSearchTerms } from '../../../domain/search';
import { Highlighter } from '../../common/Highlighter';
import { Link } from '../../common/A11y/Link';
import isEmpty from 'lodash/isEmpty';
import { UIAction } from '../../../modules/UI';
import { ActionCreator } from 'redux';
import { Router } from '../../../i18n';
import { getSearchTermUrl } from '../../../utils/RouterUtils';

interface SearchSuggestionsTermsProps extends Pick<SearchSuggestions, 'terms'> {
    query: string;
    toggleSearchModal: ActionCreator<UIAction>;
}

export const SearchSuggestionsTerms = ({
    terms,
    query,
    toggleSearchModal,
}: SearchSuggestionsTermsProps): JSX.Element | null => {
    if (isEmpty(terms)) {
        return null;
    }
    return (
        <ul className="SearchSuggestionsTerms">
            {terms.map(
                (term: SuggestedSearchTerms, i: number): JSX.Element => {
                    const termLink = getSearchTermUrl(term);
                    return (
                        <li key={i} className="SearchSuggestionsTerms-Item">
                            <Link
                                href={termLink}
                                className="SearchSuggestionsTerms-Link"
                                onClick={(): void => {
                                    toggleSearchModal();
                                    Router.push(termLink);
                                }}
                            >
                                <Highlighter highlightedClassName="highlighted" content={term} query={query} />
                            </Link>
                        </li>
                    );
                }
            )}
        </ul>
    );
};
