import { CreditsRequestActionType } from './CreditsRequestActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { CreditRequest } from '../../domain/user';

export interface CreditsRequestAction extends FetchAction<CreditsRequestActionType, {}> {
    request?: CreditRequest;
}

export function createSubmitCreditsRequestAction(request: CreditRequest): CreditsRequestAction {
    return {
        type: CreditsRequestActionType.SUBMIT,
        request,
    };
}

export function createSubmitCreditsRequestSuccessAction(): CreditsRequestAction {
    return {
        type: CreditsRequestActionType.SUBMIT_SUCCESS,
    };
}

export function createSubmitCreditsRequestErrorAction(error: RequestError): CreditsRequestAction {
    return {
        type: CreditsRequestActionType.SUBMIT_ERROR,
        payload: error,
    };
}
