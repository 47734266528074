import { SearchSuggestionsActionType } from './SearchSuggestionsActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { SearchSuggestions } from '../../domain/search';

export interface SearchSuggestionsAction extends FetchAction<SearchSuggestionsActionType, SearchSuggestions> {
    terms?: string;
}

export function createFetchSearchSuggestionsAction(terms: string): SearchSuggestionsAction {
    return { type: SearchSuggestionsActionType.FETCH_SUGGESTIONS, terms };
}

export function createFetchSearchSuggestionsSuccessAction(suggestions: SearchSuggestions): SearchSuggestionsAction {
    return {
        type: SearchSuggestionsActionType.FETCH_SUGGESTIONS_SUCCESS,
        payload: suggestions,
    };
}

export function createFetchSearchSuggestionsErrorAction(error: RequestError): SearchSuggestionsAction {
    return {
        type: SearchSuggestionsActionType.FETCH_SUGGESTIONS_ERROR,
        payload: error,
    };
}

export function createResetSearchSuggestionsAction(): SearchSuggestionsAction {
    return { type: SearchSuggestionsActionType.RESET_SUGGESTIONS };
}
