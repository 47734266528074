import { takeLatest, call } from 'redux-saga/effects';
import { CreditsRequestActionType } from './CreditsRequestActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import {
    CreditsRequestAction,
    createSubmitCreditsRequestSuccessAction,
    createSubmitCreditsRequestErrorAction,
} from './CreditsRequestActions';

function* submitRequest(action: CreditsRequestAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `credit-requests`,
            method: 'POST',
            body: action.request,
        },
        onSuccess: (): CreditsRequestAction => createSubmitCreditsRequestSuccessAction(),
        onError: createSubmitCreditsRequestErrorAction,
    });
}

export default function* creditsRequestSaga(): SagaIterator {
    yield takeLatest(CreditsRequestActionType.SUBMIT, submitRequest);
}
