import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { WithTranslation } from 'next-i18next';
import { compose } from 'redux';

import { Link, withTranslation } from '../../i18n';
import { Catalog } from '../../domain/editorial';
import { AppState } from '../../store/reducers';

import { UserGuide } from './UserGuide/UserGuide';
import { CommunicationKit } from './CommunicationKit';

import './Footer.scss';

const IFCinemaCopyright = `© ${new Date().getFullYear()} Institut français`;
const IFCinema = 'Ifcinema';

interface StateProps {
    catalogs?: Catalog[];
}

type FooterProps = StateProps & WithTranslation;

const _Footer = (props: FooterProps): JSX.Element | null => {
    const { catalogs, t } = props;

    if (catalogs === undefined) {
        return null;
    }

    const hiddenCatalogsCode = catalogs
        .filter((catalog): boolean => catalog.hidden)
        .map((catalog): string => catalog.code);

    return (
        <footer className="Footer" role="contentinfo">
            <svg width="146px" height="151px" viewBox="0 0 146 151" className="rect is-outlined" aria-hidden="true">
                <rect
                    aria-hidden="true"
                    transform="translate(-19, 115) rotate(-501) translate(19, -115) "
                    x="-43.5"
                    y="-11"
                    width="49"
                    height="254"
                ></rect>
                <rect
                    aria-hidden="true"
                    transform="translate(29, 97.5) rotate(-127) translate(-29, -97.5) "
                    x="4"
                    y="-29.5"
                    width="49"
                    height="254"
                ></rect>
            </svg>
            <nav role="navigation" aria-label="Menu secondaire">
                <ul>
                    <li>{t('common:catalogs')}</li>
                    {!hiddenCatalogsCode.includes('fr') && (
                        <li>
                            <Link href={{ pathname: '/movies', query: { catalogType: 'fr' } }} as="/catalog/fr">
                                <a>{t('common:cineFR')}</a>
                            </Link>
                        </li>
                    )}
                    {!hiddenCatalogsCode.includes('afr') && (
                        <li>
                            <Link href={{ pathname: '/movies', query: { catalogType: 'afr' } }} as="/catalog/afr">
                                <a>{t('common:cineAF')}</a>
                            </Link>
                        </li>
                    )}
                    {!hiddenCatalogsCode.includes('alt') && (
                        <li>
                            <Link href={{ pathname: '/movies', query: { catalogType: 'alt' } }} as="/catalog/alt">
                                <a>{t('common:altContent')}</a>
                            </Link>
                        </li>
                    )}
                </ul>
                <ul>
                    <li>{t('help')}</li>
                    <li>
                        <Link href="/faq">
                            <a>{t('faq')}</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/contact">
                            <a>{t('common:contactUsInfinitive')}</a>
                        </Link>
                    </li>
                    <li>
                        <UserGuide {...props} />
                    </li>
                </ul>
                <ul>
                    <li>{IFCinema}</li>
                    <li>
                        <Link href="/about">
                            <a>{t('aboutUs')}</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal">
                            <a>{t('legalInfo')}</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/privacy-policy">
                            <a>{t('privacyPolicy')}</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/terms-of-use">
                            <a>{t('terms')}</a>
                        </Link>
                    </li>
                    <li>
                        <Link href="/accessibility">
                            <a>{t('accessibility')}</a>
                        </Link>
                    </li>
                    <li>
                        <CommunicationKit {...props} />
                    </li>
                </ul>
            </nav>
            <a className="copyright" href="http://www.institutfrancais.com" target="_blank" rel="noopener noreferrer">
                <img src="/static/img/logo_if.svg" alt="Institut Français (logo)" />
                <p>{IFCinemaCopyright}</p>
            </a>
            <svg
                width="96px"
                height="175px"
                viewBox="0 0 96 175"
                className="rect is-outlined only-desktop"
                aria-hidden="true"
            >
                <g transform="translate(-971, -219)" aria-hidden="true">
                    <g transform="translate(0, 74)" aria-hidden="true">
                        <rect
                            aria-hidden="true"
                            transform="translate(1028, 275) rotate(-15) translate(-1028, -275) "
                            x="1004"
                            y="148"
                            width="49"
                            height="254"
                        ></rect>
                    </g>
                </g>
            </svg>
        </footer>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    catalogs: state.catalogs.data,
});

export const Footer = compose(
    connect(mapStateToProps),
    withTranslation(['footer', 'common'])
)(_Footer) as FunctionComponent;
