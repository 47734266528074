/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import EventKeys from '../../../EventKeys';
import { nodeListToArray } from '../../../../utils/ElementUtils';
import AriaRole from '../../../AriaRole';
import findIndex from 'lodash/findIndex';

/**
 * Expects <Tab> components inside.
 * Follows: https://www.w3.org/TR/wai-aria-practices/#tabpanel
 * See example here: https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
 */
export type Orientation = 'horizontal' | 'vertical';

export interface TabListProps extends React.Props<React.ReactInstance> {
    className?: string;
    onClick?: (e: React.SyntheticEvent) => void;
    /**
     * must be set to 'horizontal' for horizontal tab list
     * must be set to 'vertical' for vertical tab list
     */
    orientation: Orientation;
    /**
     * Allows the consumer component to restore the focus to the last selected tab.
     */
    refocusTab?: boolean;
    /**
     * Allows the consumer component to set the initial index of the Tab that receives
     * tabIndex=0 attribute. (it does not get focused though)
     */
    initialFocusableTabPosition?: number;
}

export interface TabListState {
    focusedIndex: number;
}

export class TabList extends Component<TabListProps, TabListState> {
    private tabs: HTMLElement[] | null = null;
    private rootDOMNode: HTMLDivElement | null = null;

    public constructor(props: TabListProps) {
        super(props);

        this.keyDown = this.keyDown.bind(this);
        this.getTabs = this.getTabs.bind(this);
        this.onClick = this.onClick.bind(this);
        this.captureRootDOMNode = this.captureRootDOMNode.bind(this);

        this.state = {
            focusedIndex: this.props.initialFocusableTabPosition! >= 0 ? this.props.initialFocusableTabPosition! : 0,
        };
    }

    private captureRootDOMNode(node: HTMLDivElement | null): void {
        this.rootDOMNode = node;
    }

    private keyDown(e: React.KeyboardEvent): void {
        const isHorizontal: boolean = this.props.orientation === 'horizontal';
        const prevKey: string = isHorizontal ? EventKeys.LEFT : EventKeys.UP;
        const nextKey: string = isHorizontal ? EventKeys.RIGHT : EventKeys.DOWN;
        switch (e.key) {
            case prevKey:
                this.setState({
                    focusedIndex: Math.max(this.state.focusedIndex - 1, 0),
                });
                break;
            case nextKey:
                this.getTabs() &&
                    this.setState({
                        focusedIndex: Math.min(this.state.focusedIndex + 1, this.getTabs()!.length - 1),
                    });
                break;
            default:
                break;
        }
    }

    public componentDidMount(): void {
        this.setTabIndexes();
    }

    private setTabIndexes(): void {
        const tabs: HTMLElement[] | null = this.getTabs();
        tabs &&
            tabs.forEach((tab: HTMLElement, index: number): void => {
                tab.tabIndex = index === this.state.focusedIndex ? 0 : -1;
            });
    }

    private focusOnTab(): void {
        const tabs: HTMLElement[] | null = this.getTabs();
        tabs && tabs[this.state.focusedIndex].focus();
    }

    public componentDidUpdate(_prevProps: TabListProps, prevState: TabListState): void {
        this.tabs = null;
        if (prevState.focusedIndex !== this.state.focusedIndex || this.props.refocusTab) {
            this.setTabIndexes();
            this.focusOnTab();
        }
    }

    private getTabs(): HTMLElement[] | null {
        if (!this.tabs && this.rootDOMNode) {
            this.tabs = nodeListToArray(this.rootDOMNode.querySelectorAll(`[role="${AriaRole.TAB}"]`));
        }
        return this.tabs;
    }

    private onClick(e: React.SyntheticEvent): void {
        const targetElement: HTMLElement = e.target as HTMLElement;
        const focusedIndex: number = findIndex(this.getTabs(), (tab: HTMLElement): boolean => {
            return tab === targetElement || tab.contains(targetElement);
        });
        if (focusedIndex !== -1) {
            if (this.props.onClick) {
                this.props.onClick(e);
            }
            this.setState({ focusedIndex: focusedIndex });
        }
    }

    public render(): JSX.Element {
        return (
            <div
                onKeyDown={this.keyDown}
                role={AriaRole.TABLIST}
                onClick={this.onClick}
                className={this.props.className}
                ref={this.captureRootDOMNode}
            >
                {this.props.children}
            </div>
        );
    }
}
