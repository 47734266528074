import { FetchAction } from '../../Fetch';
import { RequestError } from '../../../sagas/APICallSaga';
import { UserJob } from '../../../domain/user';
import { UserJobsActionType } from './UserJobsActionType';

export type UserJobsAction = FetchAction<UserJobsActionType, UserJob[]>;

export function createFetchUserJobsAction(): UserJobsAction {
    return {
        type: UserJobsActionType.FETCH,
    };
}

export function createFetchUserJobsSuccessAction(userJobs: UserJob[]): UserJobsAction {
    return {
        type: UserJobsActionType.FETCH_SUCCESS,
        payload: userJobs,
    };
}

export function createFetchUserJobsErrorAction(error: RequestError): UserJobsAction {
    return {
        type: UserJobsActionType.FETCH_ERROR,
        payload: error,
    };
}
