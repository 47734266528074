import { CentresAction } from './CentresActions';
import { RequestError } from '../../../sagas/APICallSaga';
import { Centre } from '../../../domain/user';
import { CentresState } from '../UserFormDataStateReducer';
import { CentresActionType } from './CentresActionType';
import { sortBy } from 'lodash';

const initialCentresState: CentresState = {
    isLoading: false,
};

export const centresReducer = (state: CentresState = initialCentresState, action: CentresAction): CentresState => {
    switch (action.type) {
        case CentresActionType.FETCH:
            return {
                ...state,
                isLoading: true,
            };
        case CentresActionType.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: sortBy(action.payload as Centre[], 'name'),
            };
        case CentresActionType.FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };
        default:
            return state;
    }
};
