import { SagaIterator } from 'redux-saga';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import { UIActionType } from './UIActionType';
import { selectAccessibility, AccessibilityState } from './UISelectors';
import { createSetAccessibilityOptions } from './UIActions';

function loadOptions(): AccessibilityState {
    return JSON.parse(window.localStorage.getItem('accessibility') || '{ "contrasts": false, "animations": true}');
}

function saveOptions(options: AccessibilityState): void {
    window.localStorage.setItem('accessibility', JSON.stringify(options));
}

function* syncLocalStorage(): SagaIterator {
    yield call(saveOptions, yield select(selectAccessibility));
}

function* init(): SagaIterator {
    yield put(createSetAccessibilityOptions(loadOptions()));
}

export default function* UISaga(): SagaIterator {
    if (typeof window === 'object') {
        yield call(init);
        yield takeLatest([UIActionType.TOGGLE_CONTRASTS, UIActionType.TOGGLE_ANIMATIONS], syncLocalStorage);
    }
}
