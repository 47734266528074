import React, { FunctionComponent } from 'react';
import { WithT } from 'i18next';
import { Movie } from '../../../../domain/work';
import { withFormik, Field, InjectedFormikProps } from 'formik';
import { compose, ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { createAddReviewAction, createUpdateReviewAction, ReviewUpdateAction } from '../../../../modules/reviews';
import { TextField, TextAreaField } from '../../../common/Form';
import { ActionButton } from '../../../common/Button';
import { FrontUser } from '../../../../domain/user';
import * as Yup from 'yup';
import pick from 'lodash/pick';
import { Review, ReviewData } from '../../../../domain/community';

interface OwnProps extends WithT {
    movie: Movie;
    frontUser: FrontUser;
    reviewToEdit?: Review;
}

interface DispatchProps {
    addReview: ActionCreator<ReviewUpdateAction>;
    updateReview: ActionCreator<ReviewUpdateAction>;
}

type AddReviewFormProps = OwnProps & DispatchProps;

interface AddReviewFormValues {
    title?: string;
    review: string;
}

const _AddReviewForm: FunctionComponent<InjectedFormikProps<AddReviewFormProps, AddReviewFormValues>> = ({
    t,
    movie,
    handleSubmit,
}: InjectedFormikProps<AddReviewFormProps, AddReviewFormValues>): JSX.Element => {
    return (
        <form className="AddReviewForm" onSubmit={handleSubmit}>
            <Field component={TextField} label={t('review_title')} name="title" />
            <Field component={TextAreaField} label={t('your_review_of', { movie: movie.title })} name="review" />
            <ActionButton text={t('send')} type="submit" className="AddReviewForm-ActionButton" />
        </form>
    );
};

const mapDispatchToProps = {
    addReview: createAddReviewAction,
    updateReview: createUpdateReviewAction,
};

export const AddReviewForm = compose(
    connect(
        null,
        mapDispatchToProps
    ),
    withFormik<AddReviewFormProps, AddReviewFormValues>({
        mapPropsToValues: (props: AddReviewFormProps): AddReviewFormValues => ({
            title: props.reviewToEdit && props.reviewToEdit.title ? props.reviewToEdit.title : '',
            review: props.reviewToEdit && props.reviewToEdit.review ? props.reviewToEdit.review : '',
        }),
        validationSchema: ({ t }: AddReviewFormProps): Yup.Schema<AddReviewFormValues> =>
            Yup.object().shape({
                title: Yup.string(),
                review: Yup.string().required(t('review_required')),
            }),
        handleSubmit: (values: AddReviewFormValues, { setSubmitting, props }): void => {
            setSubmitting(false);

            const valuesToSubmit: Partial<ReviewData> = {
                ...values,
                frontUser: pick(props.frontUser, 'id'),
                movie: pick(props.movie, 'id'),
            };

            if (props.reviewToEdit) {
                props.updateReview({ ...valuesToSubmit, id: props.reviewToEdit.id });
            } else {
                props.addReview(valuesToSubmit);
            }
        },
        validateOnChange: false,
    })
)(_AddReviewForm) as FunctionComponent<OwnProps>;
