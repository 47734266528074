import React, { useRef } from 'react';
import classNames from 'clsx';
import { Cycle } from '../../../domain/editorial';
import { WithT } from 'i18next';

export interface CyclePosterProps extends WithT {
    cycle: Cycle;
}

const width = 160;
const height = 213;
const imgSrc = '/static/img/cycle-poster-placeholder.svg';

export const CyclePoster = ({ cycle, t }: CyclePosterProps): JSX.Element => {
    const ref = useRef<HTMLImageElement>(null);

    const style = {
        backgroundImage: `url('${imgSrc}')`,
        backgroundSize: 'cover',
    };

    return (
        <>
            <span className="CyclePoster__MovieCount">{cycle.works!.length}</span>
            <img
                key={cycle.id}
                ref={ref}
                className={classNames('CyclePoster__Image', 'lazyload')}
                width={width}
                height={height}
                data-src={imgSrc}
                src={imgSrc}
                style={style}
                alt={t('common:filmPoster')}
                loading="lazy"
            />
        </>
    );
};
