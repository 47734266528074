import { takeEvery, call } from 'redux-saga/effects';
import * as actions from './ContactActions';
import { ContactActionType } from './ContactActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import * as Bowser from 'bowser';

function getUserPlatformAndBrowserInfo(): string {
    const { browser, os, platform } = Bowser.parse(window.navigator.userAgent);
    const info: string[] = [];
    info.push(`---`);
    info.push(`Browser: ${browser.name}, version: ${browser.version}`);
    info.push(`OS: ${os.name}, version: ${os.version}`);
    info.push(`Platform: ${platform.type}`);
    return info.join('\n');
}

function* addContact(action: actions.ContactAction): SagaIterator {
    const body = new FormData();

    if (action.payload) {
        const { technical, fullName, userMail, mailObject, content, affectedMovies, attachments } = action.payload;
        const enrichedContent = technical ? `${content}\n\n${getUserPlatformAndBrowserInfo()}` : content;

        body.append(
            'helpRequest',
            JSON.stringify({ technical, fullName, userMail, mailObject, content: enrichedContent, affectedMovies })
        );

        if (attachments && attachments.length > 0) {
            attachments.forEach((attachment): void => {
                body.append('attachments', attachment.file);
            });
        }

        yield call(callApi, {
            endpoint: {
                url: 'help-requests',
                method: 'POST',
                body,
            },
            onSuccess: (): actions.ContactAction => actions.createAddContactSuccessAction(),
            onError: actions.createAddContactErrorAction,
        });
    }
}

export default function* contactSaga(): SagaIterator {
    yield takeEvery(ContactActionType.ADD_CONTACT, addContact);
}
