import { UserGeoLocationType } from './UserGeoLocationType';
import { Action } from 'redux';
import { GeoLocation } from '../../server/geoip-middleware';

export interface UserGeoLocationAction extends Action<UserGeoLocationType> {
    payload?: GeoLocation | null;
}

export function createFetchUserGeolocationAction(): UserGeoLocationAction {
    return {
        type: UserGeoLocationType.FETCH_USER_COUNTRY,
    };
}

export function createSetUserGeoLocationAction(userGeoLocation: GeoLocation): UserGeoLocationAction {
    return {
        type: UserGeoLocationType.FETCH_USER_COUNTRY_SUCCESS,
        payload: userGeoLocation,
    };
}
