import { useState, useEffect, useCallback } from 'react';
import { SuggestedSearchTerms } from '../../../domain/search';
import size from 'lodash/size';
import EventKeys from '../../EventKeys';

function focusOnSearchInput(): void {
    const searchInput = document.querySelector<HTMLInputElement>('.SearchInput-Input');
    if (searchInput) {
        searchInput.focus();
        // make sure the caret is set at the end of the input text
        setTimeout((): void => {
            const length = searchInput.value.length;
            searchInput.setSelectionRange(length, length);
        }, 2);
    }
}

export function useCycleThroughSuggestions(terms: SuggestedSearchTerms[] | undefined): void {
    const [focusedItemIndex, setFocusedItemIndex] = useState<null | number>(null);
    const totalTerms = size(terms);

    const keyDownHandler = useCallback(
        ({ key }: KeyboardEvent): void => {
            if (key === EventKeys.DOWN) {
                if (focusedItemIndex !== null && focusedItemIndex + 1 > totalTerms - 1) {
                    focusOnSearchInput();
                    setFocusedItemIndex(null);
                } else {
                    setFocusedItemIndex(focusedItemIndex === null ? 0 : focusedItemIndex + 1);
                }
            } else if (key === EventKeys.UP) {
                if (focusedItemIndex !== null && focusedItemIndex - 1 < 0) {
                    focusOnSearchInput();
                    setFocusedItemIndex(null);
                } else {
                    setFocusedItemIndex(focusedItemIndex === null ? totalTerms - 1 : focusedItemIndex - 1);
                }
            }
        },
        [focusedItemIndex, totalTerms]
    );

    useEffect((): (() => void) => {
        window.addEventListener('keydown', keyDownHandler);
        return (): void => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, [keyDownHandler]);

    useEffect((): void => {
        if (totalTerms > 0) {
            if (focusedItemIndex !== null) {
                const link = document.querySelector<HTMLAnchorElement>(
                    `.SearchSuggestionsTerms-Item:nth-child(${focusedItemIndex + 1}) > a`
                );
                if (link) {
                    link.focus();
                }
            }
        } else {
            setFocusedItemIndex(null);
        }
    }, [focusedItemIndex, totalTerms]);
}
