import { combineReducers } from 'redux';
import { UIReducer, UIState } from '../modules/UI';
import { homepageReducer, HomepageState } from '../modules/homepage';
import { movieReducer, MovieState } from '../modules/movie';
import { moviesReducer, MoviesState } from '../modules/movies';
import { cycleReducer, CycleState } from '../modules/cycle';
import { cyclesReducer, CyclesState } from '../modules/cycles';
import { authReducer, AuthState } from '../modules/auth';
import { searchSuggestionsReducer, SearchSuggestionsState } from '../modules/searchSuggestions';
import { localeReducer, Locale } from '../modules/locale';
import {
    searchEditorialSuggestionsReducer,
    SearchEditorialSuggestionsState,
} from '../modules/searchEditorialSuggestions';
import { FilterState, filterReducer } from '../modules/filter';
import { UserFormDataState, userFormDataReducer } from '../modules/userFormData';
import { RegistrationState, registrationReducer } from '../modules/registration';
import { CartState, cartReducer } from '../modules/cart/CartStateReducer';
import { OrderState, orderReducer } from '../modules/order';
import { ScreeningsState, screeningsReducer } from '../modules/screenings';
import { StaticPageState, staticPageReducer } from '../modules/staticPage';
import { CreditsRequestState, creditsRequestReducer } from '../modules/creditsRequest';
import { ContactState, contactReducer } from '../modules/contact';
import { CookiesState, cookiesReducer } from '../modules/cookies';
import { FaqCategoriesState, FaqCategoriesReducer } from '../modules/faqCategories';
import { FaqCategoryState, FaqCategoryReducer } from '../modules/faqCategory';
import { FaqCategoryQuestionState, FaqCategoryQuestionReducer } from '../modules/faqCategoryQuestion';
import { CatalogState, catalogReducer } from '../modules/catalog';
import { ProfileState, profileReducer } from '../modules/profile';
import { OrdersState, OrderLinesState } from '../modules/orders';
import { ordersReducer, orderLinesReducer } from '../modules/orders';
import { FavoritesState, FavoriteMoviesState, favoritesReducer, favoriteMoviesReducer } from '../modules/favorites';
import { PlaylistState, playlistsReducer } from '../modules/playlists';
import { LegalCountriesState, legalCountriesReducer } from '../modules/legalCountries';
import { FavoriteState, favoriteReducer } from '../modules/favorite/FavoriteReducer';
import { CollectionState, collectionReducer } from '../modules/collection';
import { ReviewsState, reviewsReducer } from '../modules/reviews';
import { SerieState, serieReducer } from '../modules/serie';
import { FreeMoviesState, freeMoviesReducer } from '../modules/freeMovies';
import { ipReducer } from '../modules/ip';
import { StreamingEventState, streamingEventReducer } from '../modules/streamingEvent';
import { userGeolocationReducer, UserGeoLocationState } from '../modules/userGeolocation';
import { CatalogsState, catalogsReducer } from '../modules/catalogs';
import { CurrentEventState, CurrentEventsReducer } from '../modules/currentEvents';
import { EducationState, EducationReducer } from '../modules/education';

export interface AppState {
    UI: UIState;
    homepage: HomepageState;
    movie: MovieState;
    serie: SerieState;
    cycle: CycleState;
    cycles: CyclesState;
    movies: MoviesState;
    auth: AuthState;
    searchSuggestions: SearchSuggestionsState;
    searchEditorialSuggestions: SearchEditorialSuggestionsState;
    locale: Locale;
    filter: FilterState;
    contact: ContactState;
    faqCategories: FaqCategoriesState;
    faqCategory: FaqCategoryState;
    faqCategoryQuestion: FaqCategoryQuestionState;
    cart: CartState;
    userFormData: UserFormDataState;
    registration: RegistrationState;
    order: OrderState;
    orders: OrdersState;
    orderlines: OrderLinesState;
    screenings: ScreeningsState;
    staticPage: StaticPageState;
    creditsRequest: CreditsRequestState;
    catalog: CatalogState;
    profile: ProfileState;
    favorite: FavoriteState;
    favorites: FavoritesState;
    favoriteMovies: FavoriteMoviesState;
    playlists: PlaylistState;
    cookies: CookiesState;
    legalCountries: LegalCountriesState;
    collection: CollectionState;
    reviews: ReviewsState;
    freeMovies: FreeMoviesState;
    ip: string;
    streamingEvent: StreamingEventState;
    userGeolocation: UserGeoLocationState;
    catalogs: CatalogsState;
    currentEvents: CurrentEventState;
    education: EducationState;
}

export const rootReducer = combineReducers<AppState>({
    UI: UIReducer,
    homepage: homepageReducer,
    movie: movieReducer,
    movies: moviesReducer,
    cycle: cycleReducer,
    cycles: cyclesReducer,
    auth: authReducer,
    searchSuggestions: searchSuggestionsReducer,
    searchEditorialSuggestions: searchEditorialSuggestionsReducer,
    locale: localeReducer,
    filter: filterReducer,
    cart: cartReducer,
    userFormData: userFormDataReducer,
    registration: registrationReducer,
    order: orderReducer,
    orders: ordersReducer,
    orderlines: orderLinesReducer,
    screenings: screeningsReducer,
    staticPage: staticPageReducer,
    creditsRequest: creditsRequestReducer,
    contact: contactReducer,
    cookies: cookiesReducer,
    faqCategories: FaqCategoriesReducer,
    faqCategory: FaqCategoryReducer,
    faqCategoryQuestion: FaqCategoryQuestionReducer,
    catalog: catalogReducer,
    profile: profileReducer,
    favorite: favoriteReducer,
    favorites: favoritesReducer,
    favoriteMovies: favoriteMoviesReducer,
    playlists: playlistsReducer,
    legalCountries: legalCountriesReducer,
    collection: collectionReducer,
    reviews: reviewsReducer,
    freeMovies: freeMoviesReducer,
    ip: ipReducer,
    serie: serieReducer,
    streamingEvent: streamingEventReducer,
    userGeolocation: userGeolocationReducer,
    catalogs: catalogsReducer,
    currentEvents: CurrentEventsReducer,
    education: EducationReducer,
});
