import { SagaIterator } from 'redux-saga';
import { call, takeLatest } from 'redux-saga/effects';
import * as actions from './SearchEditorialSuggestionsActions';
import { SearchEditorialSuggestionsActionType } from './SearchEditorialSuggestionsActionType';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';

function* fetchEditorialSuggestions(): SagaIterator {
    yield call(callApi, {
        endpoint: 'search-suggestions',
        onSuccess: (res: AxiosResponse): actions.SearchEditorialSuggestionsAction => {
            const suggestions = get(res.data, '_embedded.suggestionLists[0].suggestions', []);
            return actions.createFetchSearchEditorialSuggestionsSuccessAction(suggestions);
        },
        onError: actions.createFetchSearchEditorialSuggestionsErrorAction,
    });
}

export default function* searchEditorialSuggestionsSaga(): SagaIterator {
    yield takeLatest(SearchEditorialSuggestionsActionType.FETCH_EDITORIAL_SUGGESTIONS, fetchEditorialSuggestions);
}
