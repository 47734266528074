import React, { ChangeEvent, SyntheticEvent, useCallback, useState, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { SVGCloseIcon } from '../../common/SVGIcons';
import { ModalDialog, ModalDialogBody, ModalDialogHeader } from '../../common/ModalDialog';
import {
    SearchSuggestionsState,
    createFetchSearchSuggestionsAction,
    SearchSuggestionsAction,
    createResetSearchSuggestionsAction,
} from '../../../modules/searchSuggestions';
import { AppState } from '../../../store/reducers';
import { ActionCreator, compose } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { SearchEditorialSuggestions } from '../SearchEditorialSuggestions';
import { SearchEditorialSuggestion } from '../../../domain/search';
import { SearchSuggestions } from '../SearchSuggestions';
import { SearchInput } from '../SearchInput';
import { Router, withTranslation } from '../../../i18n';
import classNames from 'clsx';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { UIAction, createToggleSearchModalAction } from '../../../modules/UI';
import { WithTranslation } from 'next-i18next';

library.add(faSearch);

interface OwnProps {
    isDisplayed: boolean;
    onClose: () => void;
}

interface DispatchProps {
    fetchSuggestions: ActionCreator<SearchSuggestionsAction>;
    resetSuggestions: ActionCreator<SearchSuggestionsAction>;
    toggleSearchModal: ActionCreator<UIAction>;
}

interface StateProps extends SearchSuggestionsState {
    editorialSuggestions?: SearchEditorialSuggestion[];
}

export type SearchModalProps = OwnProps & DispatchProps & StateProps & WithTranslation;

const SearchModalInner = ({
    isLoading,
    isDisplayed,
    onClose,
    fetchSuggestions,
    resetSuggestions,
    data: suggestions,
    editorialSuggestions,
    toggleSearchModal,
    t,
}: SearchModalProps): JSX.Element => {
    const [query, setQuery] = useState('');
    const searchUrl = `/search`;
    const terms = get(suggestions, 'terms');
    const movies = get(suggestions, 'movies');
    const cycles = get(suggestions, 'cycles');
    const userHasEnteredText = !isEmpty(query) && query.length > 1;

    const performSearch = useCallback(
        (value: string): SearchSuggestionsAction => {
            return isEmpty(value) || value.length === 1 ? resetSuggestions() : fetchSuggestions(value);
        },
        [fetchSuggestions, resetSuggestions]
    );

    const handleClose = (): void => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    const handleChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
            setQuery(value);
            performSearch(value);
        },
        [performSearch]
    );

    const handleSubmit = useCallback(
        (event: SyntheticEvent<HTMLFormElement>): void => {
            Router.push(`${searchUrl}?q_text=${encodeURIComponent(query)}`);
            toggleSearchModal();
            event.preventDefault();
        },
        [query, searchUrl, toggleSearchModal]
    );

    return (
        <ModalDialog
            isOpen={isDisplayed}
            ariaLabel={t('header:search')}
            onEscKeyPress={handleClose}
            className="SearchModal"
            fullScreen={true}
            autofocus={false}
        >
            <ModalDialogHeader className="SearchModal-Header" t={t}>
                <h2 className="SearchModal-Header-Title">
                    <FontAwesomeIcon className="SearchModal-Header-Icon" aria-hidden="true" icon={faSearch} />
                    <span className="SearchModal-Header-Text">{t('header:search')}</span>
                </h2>
                <button onClick={handleClose} className="SearchModal-CloseButton SearchModal-Header-CloseButton">
                    <SVGCloseIcon t={t} />
                </button>
            </ModalDialogHeader>
            <ModalDialogBody className="SearchModal-Body">
                <button onClick={handleClose} className="SearchModal-CloseButton SearchModal-CloseButton-wide">
                    <SVGCloseIcon t={t} />
                </button>
                <form
                    className={classNames('SearchModal-Form', {
                        'SearchModal-Form-background-displayed': !userHasEnteredText,
                    })}
                    action={searchUrl}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <SearchInput query={query} onChange={handleChange} userHasEnteredText={userHasEnteredText} t={t} />
                    <SearchEditorialSuggestions
                        isDisplayed={!userHasEnteredText}
                        suggestions={editorialSuggestions}
                        toggleSearchModal={toggleSearchModal}
                        t={t}
                    />
                    <SearchSuggestions
                        terms={terms}
                        movies={movies}
                        cycles={cycles}
                        query={query}
                        isDisplayed={userHasEnteredText}
                        isLoading={isLoading}
                        toggleSearchModal={toggleSearchModal}
                        t={t}
                    />
                </form>
            </ModalDialogBody>
        </ModalDialog>
    );
};

export const SearchModal = compose(
    connect(
        (state: AppState): StateProps => {
            return {
                ...state.searchSuggestions,
                editorialSuggestions: state.searchEditorialSuggestions.data,
            };
        },
        {
            fetchSuggestions: createFetchSearchSuggestionsAction,
            resetSuggestions: createResetSearchSuggestionsAction,
            toggleSearchModal: createToggleSearchModalAction,
        }
    ),
    withTranslation(['search', 'header'])
)(SearchModalInner) as FunctionComponent<OwnProps>;
