import { Playlist, PlaylistData, PlaylistUpdate } from '../../domain/community';
import { PlaylistsActionType } from './PlaylistsActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type PlaylistsAction = FetchAction<PlaylistsActionType, Playlist[]>;

export function createFetchPlaylistsAction(): PlaylistsAction {
    return { type: PlaylistsActionType.FETCH_PLAYLISTS };
}

export function createFetchPlaylistsSuccessAction(playlists: Playlist[]): PlaylistsAction {
    return {
        type: PlaylistsActionType.FETCH_PLAYLISTS_SUCCESS,
        payload: playlists,
    };
}

export function createFetchPlaylistsErrorAction(error: RequestError): PlaylistsAction {
    return {
        type: PlaylistsActionType.FETCH_PLAYLISTS_ERROR,
        payload: error,
    };
}

export type PlaylistAction = FetchAction<PlaylistsActionType, Playlist>;

export interface CreatePlaylistAction extends PlaylistAction {
    playlist: PlaylistData;
}

export interface UpdatePlaylistAction extends PlaylistAction {
    playlist: PlaylistUpdate;
}

export interface DeletePlaylistAction extends PlaylistAction {
    playlistId: string;
}

export interface CreateOrUpdatePlaylistSuccessAction extends PlaylistAction {
    payload: Playlist;
}

export interface CreateOrUpdatePlaylistErrorAction extends PlaylistAction {
    error: RequestError;
}

export function createPlaylistAction(playlist: PlaylistData): CreatePlaylistAction {
    return {
        type: PlaylistsActionType.CREATE_PLAYLIST,
        playlist,
    };
}

export function updatePlaylistAction(playlist: PlaylistUpdate): UpdatePlaylistAction {
    return {
        type: PlaylistsActionType.UPDATE_PLAYLIST,
        playlist,
    };
}

export function deletePlaylistAction(playlistId: string): DeletePlaylistAction {
    return {
        type: PlaylistsActionType.DELETE_PLAYLIST,
        playlistId,
    };
}

export function createPlaylistSuccessAction(playlist: Playlist): CreateOrUpdatePlaylistSuccessAction {
    return { type: PlaylistsActionType.CREATE_PLAYLIST_SUCCESS, payload: playlist };
}

export function createPlaylistErrorAction(error: RequestError): CreateOrUpdatePlaylistErrorAction {
    return { type: PlaylistsActionType.CREATE_PLAYLIST_ERROR, error };
}

export function updatePlaylistSuccessAction(playlist: Playlist): CreateOrUpdatePlaylistSuccessAction {
    return { type: PlaylistsActionType.UPDATE_PLAYLIST_SUCCESS, payload: playlist };
}

export function updatePlaylistErrorAction(error: RequestError): CreateOrUpdatePlaylistErrorAction {
    return { type: PlaylistsActionType.UPDATE_PLAYLIST_ERROR, error };
}

export function deletePlaylistSuccessAction(): PlaylistAction {
    return { type: PlaylistsActionType.DELETE_PLAYLIST_SUCCESS };
}

export function deletePlaylistErrorAction(error: RequestError): CreateOrUpdatePlaylistErrorAction {
    return { type: PlaylistsActionType.UPDATE_PLAYLIST_ERROR, error };
}
