import { SagaIterator } from 'redux-saga';
import { takeLatest, call, select } from 'redux-saga/effects';
import { callApi, RequestConfig } from '../../sagas/APICallSaga';
import { OrdersType } from './OrdersActionType';
import { AxiosResponse } from 'axios';
import concat from 'lodash/concat';
import map from 'lodash/map';
import get from 'lodash/get';
import { SortOption } from '../../domain/listings';
import { Order, OrderLine } from '../../domain/commerce';
import { AppState } from '../../store/reducers';
import {
    OrdersAction,
    createFetchOrdersSuccessAction,
    createFetchOrdersErrorAction,
    createFetchOrderLinesSuccessAction,
    createFetchOrderLinesErrorAction,
    OrdersLinesAction,
    createFetchOrderLinesAction,
} from './OrdersActions';

export function* fetchOrders(): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'users/orders',
            params: { sort: SortOption.ORDERED_DATE_DESC },
        },
        onSuccess: (res: AxiosResponse): OrdersAction => {
            return createFetchOrdersSuccessAction(get(res.data, '_embedded.orders', []));
        },
        onError: createFetchOrdersErrorAction,
    });
}

function* fetchOrderLines(): SagaIterator {
    const appState: AppState = yield select((state: AppState): AppState => state);

    if (appState.orders.data) {
        yield call(callApi, {
            endpoint: appState.orders.data.map(
                (item: Order): RequestConfig => ({
                    url: `orders/${item.id}/lines`,
                })
            ),
            onSuccess: (...responses: AxiosResponse<OrderLine>[]): OrdersLinesAction => {
                return createFetchOrderLinesSuccessAction(concat([], ...map(responses, 'data._embedded.orderLines')));
            },
            onError: createFetchOrderLinesErrorAction,
        });
    }

    return createFetchOrderLinesAction();
}

export default function* ordersSaga(): SagaIterator {
    yield takeLatest(OrdersType.FETCH_ORDERS, fetchOrders);
    yield takeLatest(OrdersType.FETCH_ORDERS_SUCCESS, fetchOrderLines);
}
