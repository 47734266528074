import { AnyAction } from 'redux';
import { FavoriteActionType } from './FavoriteActionType';
import { FetchState } from '../Fetch';
import { Favorite } from '../../domain/community';

export type FavoriteState = FetchState<Favorite>;

export const initialState: FavoriteState = {
    isLoading: false,
};

export const favoriteReducer = (state: FavoriteState = initialState, action: AnyAction): FavoriteState => {
    switch (action.type) {
        case FavoriteActionType.ADD_TO_FAVORITES:
            return {
                ...state,
                isLoading: true,
            };

        case FavoriteActionType.ADD_TO_FAVORITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };

        case FavoriteActionType.ADD_TO_FAVORITES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case FavoriteActionType.REMOVE_FROM_FAVORITES:
            return {
                ...state,
                isLoading: true,
            };

        case FavoriteActionType.REMOVE_FROM_FAVORITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };

        case FavoriteActionType.REMOVE_FROM_FAVORITES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
