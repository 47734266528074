import React, {
    FunctionComponent,
    useState,
    useRef,
    useEffect,
    ComponentType,
    ReactPortal,
    FocusEvent,
    useCallback,
} from 'react';
import { CartState, createRemoveFromCartAction } from '../../../modules/cart';
import { CartEntry } from '../../../domain/commerce';
import { WithTranslation } from 'next-i18next';
import { AppState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { ActionButton } from '../../common/Button';
import classNames from 'clsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import AriaRole from '../../AriaRole';
import { createToggleMiniCartAction } from '../../../modules/UI';
import { Router } from '../../../i18n';
import { CartEntryCard } from '../CartEntryCard';
import { Link } from '../../common/A11y/Link';
import { SVGCloseIcon } from '../../common/SVGIcons';
import { Button, ButtonHandle } from '../../common/A11y/Button';
import Breakpoint from '../../Breakpoint';

interface StateProps {
    cart: CartState;
    isOpen: boolean;
}

interface DispatchProps {
    onRemove: (entry: CartEntry) => void;
    onClose: () => void;
}

type MiniCartProps = StateProps & DispatchProps & WithTranslation;

const updateMiniCartPosition = (miniCart: HTMLDivElement): void => {
    if (miniCart === null) {
        return;
    }
    if (Breakpoint.getCurrent() === Breakpoint.MOBILE) {
        miniCart.style.top = '0';
        return;
    }
    const header = document.querySelector<HTMLElement>('.Header');
    if (header === null) {
        return;
    }
    if (header.classList.contains('is-sticky')) {
        miniCart.classList.add('sticky');
    } else {
        miniCart.classList.remove('sticky');
    }
    miniCart.style.top = `${header.offsetHeight}px`;
};

export const DumbMiniCart: FunctionComponent<MiniCartProps> = ({
    cart,
    t,
    onRemove,
    onClose,
    isOpen,
}: MiniCartProps): ReactPortal | null => {
    const [isOverflowing, setIsOverflowing] = useState<boolean>();
    const refToRootElement = useRef<HTMLDivElement>(null);
    const refToItemsContainer = useRef<HTMLDivElement>(null);
    const refToCloseButton = useRef<ButtonHandle>(null);

    const handleScroll = useCallback((): void => {
        if (isOpen && refToRootElement.current) {
            setTimeout((): void => {
                updateMiniCartPosition(refToRootElement.current!);
            }, 500);
        }
    }, [isOpen]);

    useEffect((): (() => void) => {
        window.addEventListener('scroll', handleScroll);
        return (): void => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect((): void => {
        if (isOpen && refToRootElement.current) {
            updateMiniCartPosition(refToRootElement.current);
            refToRootElement.current.focus({ preventScroll: true });
        }
        const newIsOverflowing =
            refToItemsContainer.current != null
                ? refToItemsContainer.current.scrollHeight > refToItemsContainer.current.clientHeight
                : undefined;
        setIsOverflowing(newIsOverflowing);
    }, [isOpen, cart.items.length]);

    const handleBlur = (event: FocusEvent): void => {
        const selectionButton = document.querySelector('.CartMenuItem');
        if (
            refToRootElement.current &&
            !refToRootElement.current.contains(event.relatedTarget as Node) &&
            (refToCloseButton.current &&
                refToCloseButton.current.getDomElement() !== null &&
                !refToCloseButton.current.getDomElement()!.contains(event.relatedTarget as Node)) &&
            !(selectionButton !== null && selectionButton.contains(event.relatedTarget as Node))
        ) {
            if (isOpen) {
                onClose();
            }
        }
    };

    if (typeof window !== 'object' || cart.items.length === 0) {
        return null;
    }

    return ReactDOM.createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={classNames('MiniCart', { open: isOpen })}
            ref={refToRootElement}
            onBlur={handleBlur}
            role={AriaRole.MENU}
            tabIndex={-1}
        >
            <header className="MiniCart-MobileHeader">
                <h1 className="MiniCart-MobileHeader-Title">
                    <span className="MiniCart-MobileHeader-Title-Text">{t('myOrder')}</span>{' '}
                    <span className="MiniCart-MobileHeader-Title-Count">{cart.items.length}</span>
                </h1>
                <Button
                    onClick={onClose}
                    text={<SVGCloseIcon width={30} height={30} t={t} />}
                    className="MiniCart-MobileHeader-CloseButton"
                    ref={refToCloseButton}
                />
            </header>
            <div className={classNames('MiniCart-Items', { overflowing: isOverflowing })} ref={refToItemsContainer}>
                {cart.items.map(
                    (entry: CartEntry, i: number): JSX.Element => {
                        return (
                            <Link href={{ pathname: '/movie', query: { id: entry.videoProduct.movie!.id } }} key={i}>
                                <CartEntryCard
                                    entry={entry}
                                    className="MiniCart-CartEntryCard"
                                    onRemove={onRemove}
                                    moviePosterWidth={80}
                                    moviePosterHeight={107}
                                    t={t}
                                />
                            </Link>
                        );
                    }
                )}
            </div>
            <ActionButton
                text={t('goToMyOrder')}
                className="MiniCart-Button"
                onClick={(): void => {
                    Router.push('/cart');
                    onClose();
                }}
            />
        </div>,
        document.querySelector('.App') || document.body
    );
};

const mapDispatchToProps: DispatchProps = {
    onRemove: createRemoveFromCartAction,
    onClose: createToggleMiniCartAction,
};
const mapStateToProps = (state: AppState): StateProps => ({
    cart: state.cart,
    isOpen: state.UI.miniCartIsOpen,
});
export const MiniCart = compose<ComponentType>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('cart')
)(DumbMiniCart);
