import { RequestError } from '../../../sagas/APICallSaga';
import { Centre, ProfileType } from '../../../domain/user';
import { FetchAction } from '../../Fetch';
import { CentresActionType } from './CentresActionType';

export interface CentresFetchParams {
    code?: string;
    profileType?: ProfileType;
    query?: string;
}

export type CentresAction = FetchAction<CentresActionType, Centre[]> & CentresFetchParams;

export function createFetchCentresAction(params?: CentresFetchParams): CentresAction {
    return {
        type: CentresActionType.FETCH,
        ...params,
    };
}
export function createFetchCentresSuccessAction(centres: Centre[]): CentresAction {
    return {
        type: CentresActionType.FETCH_SUCCESS,
        payload: centres,
    };
}
export function createFetchCentresErrorAction(error: RequestError): CentresAction {
    return {
        type: CentresActionType.FETCH_ERROR,
        payload: error,
    };
}
