export enum KitCategoryType {
    MUTUAL = 'mutual',
    FRENCH_CINEMA = 'french-cinema',
    CINEMATHEQUE_AFRIQUE = 'cinematheque-afrique',
}

export interface DownloadableFile {
    name: string;
    size: number;
    url: string;
}

export interface KitCategory {
    type: KitCategoryType;
    files: DownloadableFile[];
}

export const fullKitFile: DownloadableFile = {
    name: 'Kit_communication',
    size: 599381650,
    url: `https://ifcinemastorage.lab.arte.tv/medias/${
        process.env.ENVIRONNMENT === 'production' ? 'prod' : 'preprod'
    }/Kit_communication.zip`,
};

const mainUrl = `https://ifcinemastorage.lab.arte.tv/medias/${
    process.env.ENVIRONNMENT === 'production' ? 'prod' : 'preprod'
}/Kit_communication/`;

const mutualKitCategory: KitCategory = {
    type: KitCategoryType.MUTUAL,
    files: [
        {
            name: 'Institut_Francais_logo.zip',
            size: 1590000,
            url: `${mainUrl}Communication%20ge%CC%81ne%CC%81rale/Institut_Francais_logo.zip`,
        },
        {
            name: 'Trailer IFcinema EN.mp4',
            size: 201500000,
            url: `${mainUrl}Communication%20ge%CC%81ne%CC%81rale/Trailer%20IFcinema%20EN.mp4`,
        },
        {
            name: 'Trailer IFcinema FR.mp4',
            size: 228400000,
            url: `${mainUrl}Communication%20ge%CC%81ne%CC%81rale/Trailer%20IFcinema%20FR.mp4`,
        },
        {
            name: 'GUIDE_DE_DIFFUSION_Fev 2024.pdf',
            size: 889134,
            url: `${mainUrl}Communication%20ge%CC%81ne%CC%81rale/GUIDE_DE_DIFFUSION_Fev%202024.pdf`,
        },
        {
            name: 'How to organize a screening_Feb 2024.pdf',
            size: 930859,
            url: `${mainUrl}Communication%20ge%CC%81ne%CC%81rale/How%20to%20organize%20a%20screening_Feb%202024.pdf`,
        },
        {
            name: 'GUIA DE DIFUSAO_Fev 2024.pdf',
            size: 388156,
            url: `${mainUrl}Communication%20ge%CC%81ne%CC%81rale/GUIA%20DE%20DIFUSAO_Fev%202024.pdf`,
        },
    ],
};

const frenchCinemaKitCategory: KitCategory = {
    type: KitCategoryType.FRENCH_CINEMA,
    files: [],
};

const cinemathequeAfriqueKitCategory: KitCategory = {
    type: KitCategoryType.CINEMATHEQUE_AFRIQUE,
    files: [
        {
            name: 'Logos Cinematheque Afrique.zip',
            size: 8670607,
            url: `${mainUrl}Cine%CC%81mathe%CC%80que%20Afrique/Logos%20Cinematheque%20Afrique.zip`,
        },
        {
            name: 'Trailer Cinematheque Afrique.mp4',
            size: 146900000,
            url: `${mainUrl}Cinémathèque%20Afrique/Trailer%20Cinematheque%20Afrique.mp4`,
        },
    ],
};

export function getKitCategory(type: KitCategoryType): KitCategory {
    switch (type) {
        case KitCategoryType.MUTUAL:
            return mutualKitCategory;
        case KitCategoryType.FRENCH_CINEMA:
            return frenchCinemaKitCategory;
        case KitCategoryType.CINEMATHEQUE_AFRIQUE:
            return cinemathequeAfriqueKitCategory;
    }
}
