import { AnyAction } from 'redux';
import { FetchState } from '../Fetch';
import { StreamingEvent } from '../../domain/editorial';
import { StreamingEventActionType } from './StreamingEventActionType';
import { Movie } from '../../domain/work';
import { MovieStateData } from '../movie';
import { SetMoviesContractsAction, SetMoviesProductsAction, SetUserGeoLocationAction } from './StreamingEventActions';
import { filter, remove } from 'lodash';
import { hasExpiredRights, isFreelyWatchable } from '../../domain/utils/MovieUtils';
import { ProductType, VideoProduct } from '../../domain/commerce';
import { GeoLocation } from '../../server/geoip-middleware';

export interface StreamingEventStateData extends StreamingEvent {
    works: MovieStateData[];
    userGeoLocation?: GeoLocation;
}

export type StreamingEventState = FetchState<StreamingEventStateData>;

export const initialState: StreamingEventState = {
    isLoading: false,
};

export const streamingEventReducer = (
    state: StreamingEventState = initialState,
    action: AnyAction
): StreamingEventState => {
    switch (action.type) {
        case StreamingEventActionType.FETCH_STREAMING_EVENT:
        case StreamingEventActionType.FETCH_STREAMING_EVENT_BY_SLUG:
            return {
                ...state,
                isLoading: true,
            };

        case StreamingEventActionType.FETCH_STREAMING_EVENT_SUCCESS:
            const streamingEvent = action.payload as StreamingEventStateData;
            remove(streamingEvent.works, (movie: Movie): boolean => {
                return !isFreelyWatchable(movie, false) || hasExpiredRights(movie);
            });

            return {
                ...state,
                isLoading: false,
                data: streamingEvent,
            };

        case StreamingEventActionType.FETCH_STREAMING_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case StreamingEventActionType.FETCH_USER_COUNTRY_SUCCESS:
            if (!state.data) {
                return state;
            }

            return {
                ...state,
                data: { ...state.data, userGeoLocation: (action as SetUserGeoLocationAction).payload },
            };

        case StreamingEventActionType.FETCH_MOVIES_PRODUCTS_SUCCESS:
            if (!state.data || !state.data.works) {
                return state;
            }

            let works = state.data.works.map(
                (movie: Movie, i: number): Movie => {
                    const products: VideoProduct[] = filter(
                        (action as SetMoviesProductsAction).payload[i],
                        (product): boolean => product.type.productType === ProductType.DIGITAL
                    );
                    return {
                        ...movie,
                        products,
                    };
                }
            );

            return {
                ...state,
                data: { ...state.data, works },
            };

        case StreamingEventActionType.FETCH_MOVIES_CONTRACTS_SUCCESS:
            if (!state.data || !state.data.works) {
                return state;
            }

            works = state.data.works.map(
                (movie: Movie, i: number): MovieStateData => {
                    const contracts = (action as SetMoviesContractsAction).payload[i];
                    return {
                        ...movie,
                        legalContracts: contracts,
                    };
                }
            );

            return {
                ...state,
                data: { ...state.data, works },
            };

        default:
            return state;
    }
};
