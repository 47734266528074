import { Catalog } from '../../domain/editorial';
import { CatalogActionType } from './CatalogActionType';
import { CatalogAction } from './CatalogActions';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export type CatalogState = FetchState<Catalog>;

export const initialState: CatalogState = {
    isLoading: false,
};

export const catalogReducer = (state: CatalogState = initialState, action: CatalogAction): CatalogState => {
    switch (action.type) {
        case CatalogActionType.FETCH_CATALOG:
            return {
                ...state,
                isLoading: true,
            };

        case CatalogActionType.FETCH_CATALOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as Catalog,
            };

        case CatalogActionType.FETCH_CATALOG_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
