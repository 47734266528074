import { takeLatest, call } from 'redux-saga/effects';
import { CollectionActionType } from './CollectionActionType';
import { SagaIterator } from 'redux-saga';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import {
    CollectionAction,
    createFetchCollectionErrorAction,
    createFetchCollectionSuccessAction,
} from './CollectionActions';

function* fetchCollection(action: CollectionAction): SagaIterator {
    yield call(callApi, {
        endpoint: `collections/${action.id}`,
        onSuccess: (res: AxiosResponse): CollectionAction => createFetchCollectionSuccessAction(res.data),
        onError: createFetchCollectionErrorAction,
    });
}

export default function* cyclesSaga(): SagaIterator {
    yield takeLatest(CollectionActionType.FETCH_COLLECTION, fetchCollection);
}
