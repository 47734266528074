import { takeLatest, call, put, take, fork, select } from 'redux-saga/effects';
import { ScreeningsActionType } from './ScreeningsActionType';
import { SagaIterator } from 'redux-saga';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import dayjs from 'dayjs';
import { AnyAction } from 'redux';

import {
    ScreeningsAction,
    createFetchScreeningsSuccessAction,
    createFetchScreeningsErrorAction,
    createAddScreeningErrorAction,
    createAddScreeningSuccessAction,
    createResetScreeningSuccessAction,
    ScreeningsUpdateAction,
    createEditScreeningSuccessAction,
    createEditScreeningErrorAction,
    createFetchMoviesContractsSuccess,
} from './ScreeningsActions';
import { ScreeningEvaluation } from '../../domain/user';
import { AuthActionType, waitForAuthentication, LoginStatus } from '../auth';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { LegalContract } from '../../domain/legal';
import { Movie } from '../../domain/work';
import { ProductType } from '../../domain/commerce';
import { AppState } from '../../store/reducers';

function* fetchScreeningEvaluations(): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'users/screening-evaluations',
            params: {
                size: 1000,
            },
        },
        onSuccess: (res: AxiosResponse): ScreeningsAction =>
            createFetchScreeningsSuccessAction(get(res.data, '_embedded.screeningEvaluations', [])),
        onError: createFetchScreeningsErrorAction,
    });
}

function* fetchMoviesContracts(action: ScreeningsAction): SagaIterator {
    if (action.payload == null) {
        return;
    }

    // We are only interested in the screenings with a digital movie and ordered before 30 days
    let screenings = action.payload as ScreeningEvaluation[];
    screenings = screenings.filter(
        (screening): boolean =>
            screening.orderLine.videoProduct.type.productType === ProductType.DIGITAL &&
            dayjs().diff(dayjs(screening.orderLine.order.orderedAt), 'days', true) < 30
    );

    const movieIds = uniq(
        compact(screenings.map((screening): Movie | undefined => screening.orderLine.videoProduct.movie))
    ).map((movie): string => movie.id);

    yield call(callApi, {
        endpoint: movieIds.map((id: string): string => `movies/${id}/contracts`),
        onSuccess: (
            ...responses: AxiosResponse<EmbeddedEntities<'legalContracts', LegalContract>>[]
        ): ScreeningsAction => {
            return createFetchMoviesContractsSuccess(
                filter(
                    map(responses, (response): LegalContract[] => getEmbeddedEntities(response.data, 'legalContracts')),
                    (response): boolean => response.length > 0
                )
            );
        },
        onError: (): AnyAction => ({
            type: 'NO_OP',
        }),
    });
}

function* addScreeningEvaluation(action: ScreeningsUpdateAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users/screening-evaluations/${action.payload.id}`,
            method: 'PUT',
            body: action.payload,
        },
        onSuccess: (res: AxiosResponse<ScreeningEvaluation>): ScreeningsAction =>
            createAddScreeningSuccessAction(res.data),
        onError: createAddScreeningErrorAction,
    });
}

function* editScreeningEvaluation(action: ScreeningsUpdateAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users/screening-evaluations/${action.payload.id}`,
            method: 'PUT',
            body: action.payload,
        },
        onSuccess: (res: AxiosResponse<ScreeningEvaluation>): ScreeningsAction =>
            createEditScreeningSuccessAction(res.data),
        onError: createEditScreeningErrorAction,
    });
}

/*
    Reset screenings.successfullyAdded to false directly after success. We only use this value to toggle the screening evaluation popin off.
*/
function* resetScreeningEvaluationSuccess(_action: ScreeningsAction): SagaIterator {
    yield put(createResetScreeningSuccessAction());
}

function* resetEditScreeningEvaluationSuccess(_action: ScreeningsAction): SagaIterator {
    yield put(createResetScreeningSuccessAction());
}

function* screeningEvaluationsInitWatcher(): SagaIterator {
    while (true) {
        yield call(waitForAuthentication);
        const status: LoginStatus = yield select((state: AppState): LoginStatus => state.auth.loginStatus);

        if (status === LoginStatus.AUTHENTICATED) {
            yield call(fetchScreeningEvaluations);
        }

        yield take(AuthActionType.DEAUTHENTICATE);
    }
}

export default function* screeningsSaga(): SagaIterator {
    yield fork(screeningEvaluationsInitWatcher);
    yield takeLatest(ScreeningsActionType.FETCH_SCREENINGS, fetchScreeningEvaluations);
    yield takeLatest(ScreeningsActionType.FETCH_SCREENINGS_SUCCESS, fetchMoviesContracts);

    yield takeLatest(ScreeningsActionType.ADD_SCREENING, addScreeningEvaluation);
    yield takeLatest(ScreeningsActionType.ADD_SCREENING_SUCCESS, resetScreeningEvaluationSuccess);

    yield takeLatest(ScreeningsActionType.EDIT_SCREENING, editScreeningEvaluation);
    yield takeLatest(ScreeningsActionType.EDIT_SCREENING_SUCCESS, resetEditScreeningEvaluationSuccess);
}
