import { FaqCategoriesActionType } from './FaqCategoriesActionType';
import { RequestError } from '../../sagas/APICallSaga';
import { FetchAction } from '../Fetch';
import { FaqCategory } from '../../domain/editorial';
import { PaginationAction } from '../../domain/listings';

export type FaqCategoriesAction = FetchAction<FaqCategoriesActionType, FaqCategory[]> &
    PaginationAction<FaqCategoriesActionType>;

export function createFetchCategoriesAction({ requestedPage = 0, pageSize = 24 }): FaqCategoriesAction {
    return {
        type: FaqCategoriesActionType.FETCH_FAQ_CATEGORIES,
        pageSize,
        requestedPage,
    };
}

export function createFetchCategoriesSuccessAction(faqCategories: FaqCategory[]): FaqCategoriesAction {
    return {
        type: FaqCategoriesActionType.FETCH_FAQ_CATEGORIES_SUCCESS,
        payload: faqCategories,
    };
}

export function createFetchCategoriesErrorAction(error: RequestError): FaqCategoriesAction {
    return {
        type: FaqCategoriesActionType.FETCH_FAQ_CATEGORIES_ERROR,
        payload: error,
    };
}
