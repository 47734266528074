import { LegalCountriesAction } from './LegalCountriesActions';
import { RequestError } from '../../sagas/APICallSaga';
import { LegalCountriesActionType } from './LegalCountriesActionType';
import { LegalCountry } from '../../domain/legal';
import { FetchState } from '../Fetch';
import filter from 'lodash/filter';

export type LegalCountriesState = FetchState<LegalCountry[]>;

const initialState: LegalCountriesState = {
    isLoading: false,
};

export const legalCountriesReducer = (
    state: LegalCountriesState = initialState,
    action: LegalCountriesAction
): LegalCountriesState => {
    switch (action.type) {
        case LegalCountriesActionType.FETCH:
            return {
                ...state,
                isLoading: true,
            };
        case LegalCountriesActionType.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: filter(action.payload, ['hidden', false]) as LegalCountry[],
            };
        case LegalCountriesActionType.FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };
        default:
            return state;
    }
};
