import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './StaticPageActions';
import { StaticPageActionType } from './StaticPageActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { StaticPage } from '../../domain/editorial';
import { HttpStatusCode } from '../../domain/HttpStatusCode';

function* fetchStaticPage(action: actions.StaticPageAction): SagaIterator {
    yield call(callApi, {
        endpoint: `static-pages?q_code=${action.code}`,
        onSuccess: (res: AxiosResponse<EmbeddedEntities<'staticPages', StaticPage>>): actions.StaticPageAction => {
            const staticPages = getEmbeddedEntities(res.data, 'staticPages');
            if (staticPages.length === 0) {
                return actions.createFetchStaticPageErrorAction({
                    status: HttpStatusCode.NOT_FOUND,
                    statusText: 'Not found',
                    description: 'Contents not found',
                });
            }
            return actions.createFetchStaticPageSuccessAction(staticPages[0]);
        },
        onError: actions.createFetchStaticPageErrorAction,
    });
}

export default function* StaticPageSaga(): SagaIterator {
    yield takeLatest(StaticPageActionType.FETCH_STATIC_PAGE, fetchStaticPage);
}
