import { Cycle, CycleType } from '../../domain/editorial';
import every from 'lodash/every';
import { isFreelyWatchable } from './MovieUtils';
import { Movie } from '../work';
import dayjs from 'dayjs';

export function isOnlyContainingFreeMovies(cycle: Cycle): boolean {
    return every(cycle.works, (movie: Movie): boolean => isFreelyWatchable(movie));
}

export function isFree(cycle: Cycle): boolean {
    return cycle.type === CycleType.STREAMING;
}

export function isStarted(cycle: Cycle): boolean {
    return (
        dayjs(cycle.publicationStartDate).isSame(Date.now(), 'date') ||
        dayjs(cycle.publicationStartDate).isBefore(Date.now(), 'date')
    );
}
