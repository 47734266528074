import { AnyAction } from 'redux';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { EducationActionType } from './EducationActionType';
import { EducationPage } from '../../domain/editorial';

export type EducationState = FetchState<EducationPage>;

export const initialState: EducationState = {
    isLoading: false,
};

export const EducationReducer = (state: EducationState = initialState, action: AnyAction): EducationState => {
    switch (action.type) {
        case EducationActionType.FETCH_EDUCATION:
            return {
                ...state,
                isLoading: true,
            };

        case EducationActionType.FETCH_EDUCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as EducationPage,
            };

        case EducationActionType.FETCH_EDUCATION_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        case EducationActionType.FETCH_MOVIE_EDUCATION_WITH_RESOURCES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                error: undefined,
            };

        default:
            return state;
    }
};
