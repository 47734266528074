import { Dropdown } from 'react-bulma-components';
import { WithT } from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { Router } from '../../i18n';
import { AppState } from '../../store/reducers';
import { Catalog } from '../../domain/editorial';

interface StateProps {
    catalogs?: Catalog[];
}

type DropdownCatalogProps = WithT & StateProps;

const _DropdownCatalog = (props: DropdownCatalogProps): JSX.Element | null => {
    const { catalogs, t } = props;

    if (catalogs === undefined) {
        return null;
    }

    const hiddenCatalogsCode = catalogs
        .filter((catalog): boolean => catalog.hidden)
        .map((catalog): string => catalog.code);

    return (
        <Dropdown className="dropdown-catalog" hoverable={true}>
            <Dropdown.Item value="item" aria-expanded="false">
                {t('catalogs')}
            </Dropdown.Item>
            <Dropdown.Item value="fr">
                {!hiddenCatalogsCode.includes('fr') && (
                    <button
                        className="catalog-link"
                        onClick={(): Promise<boolean> => {
                            return Router.push({ pathname: '/movies', query: { catalogType: 'fr' } }, '/catalog/fr');
                        }}
                    >
                        {t('common:cineFR')}
                    </button>
                )}
            </Dropdown.Item>
            <Dropdown.Item value="afr">
                {!hiddenCatalogsCode.includes('afr') && (
                    <button
                        className="catalog-link"
                        onClick={(): Promise<boolean> => {
                            return Router.push({ pathname: '/movies', query: { catalogType: 'afr' } }, '/catalog/afr');
                        }}
                    >
                        {t('common:cineAF')}
                    </button>
                )}
            </Dropdown.Item>
            <Dropdown.Item value="alt">
                {!hiddenCatalogsCode.includes('alt') && (
                    <button
                        className="catalog-link"
                        onClick={(): Promise<boolean> => {
                            return Router.push({ pathname: '/movies', query: { catalogType: 'alt' } }, '/catalog/alt');
                        }}
                    >
                        {t('common:altContent')}
                    </button>
                )}
            </Dropdown.Item>
            <Dropdown.Item value="">
                <button className="button is-stroke-effect" onClick={(): Promise<boolean> => Router.push('/movies')}>
                    <span>{t('wholeCatalog')}</span>
                </button>
            </Dropdown.Item>
            <Dropdown.Item value="">
                <svg width="216px" height="247px" viewBox="0 0 216 247" className="rect" aria-hidden="true">
                    <rect
                        aria-hidden="true"
                        transform="translate(128, 123) rotate(-150) translate(-128, -123) "
                        x="103"
                        y="-4"
                        width="50"
                        height="255"
                    />
                    <rect
                        aria-hidden="true"
                        transform="translate(108, 134) rotate(-135) translate(-108, -134) "
                        x="83"
                        y="6"
                        width="50"
                        height="255"
                    />
                </svg>
            </Dropdown.Item>
        </Dropdown>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    catalogs: state.catalogs.data,
});

export const DropdownCatalog = connect(mapStateToProps)(_DropdownCatalog);
