import { takeEvery, call } from 'redux-saga/effects';
import * as actions from './FaqCategoryQuestionActions';
import get from 'lodash/get';
import { FaqCategoryQuestionActionType } from './FaqCategoryQuestionActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';

function* fetchFaqCategoryQuestion(action: actions.FaqCategoryQuestionAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: action.id ? `questions-answers/${action.id}` : 'questions-answers',
            method: 'GET',
            params: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                q_code: action.code,
            },
        },
        onSuccess: (result): actions.FaqCategoryQuestionAction =>
            actions.createFetchCategoryQuestionSuccessAction(
                action.code ? get(result.data, '_embedded.questionAnswers', [])[0] : result.data
            ),
        onError: actions.createFetchCategoryQuestionErrorAction,
    });
}

export default function* faqSaga(): SagaIterator {
    yield takeEvery(FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION, fetchFaqCategoryQuestion);
}
