import { IPActionType } from './IPActionType';
import { IPAction } from './IPActions';
import { Reducer } from 'redux';

export const initialState = '';

export const ipReducer: Reducer<string, IPAction> = (state = initialState, action: IPAction): string => {
    if (action.type === IPActionType.SET) {
        return action.ip;
    }
    return state;
};
