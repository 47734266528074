import { call, takeEvery, select, put, take } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import findIndex from 'lodash/findIndex';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import { FavoriteActionType } from './FavoriteActionType';
import { FrontUser } from '../../domain/user';
import { AppState } from '../../store/reducers';
import { LoginStatus } from '../auth';
import { createToggleConnectionModalAction } from '../UI';
import { ProfileActionType } from '../profile';
import { Favorite } from '../../domain/community';
import {
    FavoriteAction,
    createAddToFavoritesErrorAction,
    createAddToFavoritesSuccessAction,
    createRemoveFromFavoritesSuccessAction,
    createRemoveFromFavoritesErrorAction,
    AddToFavoritesProps,
    RemoveFromFavoritesProps,
} from './FavoriteActions';

const selectLoginStatus = (state: AppState): LoginStatus => state.auth.loginStatus;
const selectUser = (state: AppState): FrontUser | undefined => state.profile.data;

function* addToFavorites(action: FavoriteAction<AddToFavoritesProps>): SagaIterator {
    const status = yield select(selectLoginStatus);

    if (status === LoginStatus.ANONYMOUS) {
        yield put(createToggleConnectionModalAction());
    }

    let user: FrontUser = yield select(selectUser);
    if (!user) {
        yield take([ProfileActionType.FETCH_SUCCESS, ProfileActionType.FETCH_ERROR]);
    }
    user = yield select(selectUser);
    if (!user) {
        return;
    }

    const favorites = yield select((state: AppState): Favorite[] | undefined => state.favorites.data);
    if (!favorites) {
        return;
    }

    const payload: AddToFavoritesProps = action.payload as AddToFavoritesProps;
    const isAlreadyInFavorites =
        findIndex(favorites, (favorite: Favorite): boolean => favorite.favorite.id === payload.favorite) !== -1;

    if (isAlreadyInFavorites) {
        return;
    }

    yield call(callApi, {
        endpoint: {
            url: 'users/favorites',
            method: 'POST',
            body: { favorite: { id: payload.favorite }, type: payload.favoriteType, frontUser: { id: user.id } },
        },
        onSuccess: (res: AxiosResponse): FavoriteAction<Favorite> => {
            return createAddToFavoritesSuccessAction(res.data);
        },
        onError: createAddToFavoritesErrorAction,
    });
}

function* removeFromFavorites(action: FavoriteAction<RemoveFromFavoritesProps>): SagaIterator {
    const payload = action.payload as RemoveFromFavoritesProps;
    yield call(callApi, {
        endpoint: {
            url: `users/favorites/${payload.favoriteId}`,
            method: 'DELETE',
        },
        onSuccess: (): FavoriteAction<Favorite> => {
            return createRemoveFromFavoritesSuccessAction();
        },
        onError: createRemoveFromFavoritesErrorAction,
    });
}

export default function* favoriteSaga(): SagaIterator {
    yield takeEvery(FavoriteActionType.ADD_TO_FAVORITES, addToFavorites);
    yield takeEvery(FavoriteActionType.REMOVE_FROM_FAVORITES, removeFromFavorites);
}
