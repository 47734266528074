import { CycleActionType } from './CycleActionType';
import { Cycle } from '../../domain/editorial';
import { uuid } from '../../domain/core';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { CycleStateData } from './CycleStateReducer';
import { VideoProduct } from '../../domain/commerce';
import { Action } from 'redux';

export interface CycleAction extends FetchAction<CycleActionType, Cycle> {
    id?: uuid;
    slug?: string;
}

export interface FetchCycleMoviesProductsAction extends Action<CycleActionType> {
    payload: Cycle;
}

export interface SetCycleMoviesProductsAction extends Action<CycleActionType> {
    payload: VideoProduct[][];
}

export function createFetchCycleAction(id: uuid): CycleAction {
    return {
        type: CycleActionType.FETCH_CYCLE,
        id,
    };
}

export function createFetchCycleBySlugAction(slug: string): CycleAction {
    return {
        type: CycleActionType.FETCH_CYCLE_BY_SLUG,
        slug,
    };
}

export function createFetchCycleSuccessAction(cycle: CycleStateData): CycleAction {
    return {
        type: CycleActionType.FETCH_CYCLE_SUCCESS,
        payload: cycle,
    };
}

export function createFetchCycleErrorAction(error: RequestError): CycleAction {
    return {
        type: CycleActionType.FETCH_CYCLE_ERROR,
        payload: error,
    };
}

export function createFetchCycleMoviesProductsAction(cycle: Cycle): FetchCycleMoviesProductsAction {
    return { type: CycleActionType.FETCH_CYCLE_MOVIE_PRODUCTS, payload: cycle };
}

export function createSetCycleMoviesProductsAction(products: VideoProduct[][]): SetCycleMoviesProductsAction {
    return { type: CycleActionType.FETCH_CYCLE_MOVIE_PRODUCTS_SUCCESS, payload: products };
}
