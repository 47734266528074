import classNames from 'clsx';
import React from 'react';
import deburr from 'lodash/deburr';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';

/**
 * This component takes two strings (content and query) and renders the
 * content with the query matches highlighted
 */

export interface HighlighterProps {
    content: string;

    /**
     * the query string
     */
    query: string;

    /**
     * CSS class names applied to the whole content
     */
    className?: string;

    /**
     * CSS class names applied to the query matches parts in the content
     */
    highlightedClassName: string;

    /**
     * If set to true, the highlighter is sensitive to diacritics when highlighting
     * the matches.
     * Defaults to false, meaning that the highlighter is insensitive to diacritics by default.
     */
    diacriticsSensitive?: boolean;
}

interface HighlightedPart {
    text: string;
    isHighlighted: boolean;
}

function escapeRegExp(text: string): string {
    if (text) {
        return text.replace(/[-(){}\/\\&^*?+|.[\]]/g, '\\$&');
    }
    return '';
}

function findMatches(
    originalContent: string,
    originalQuery: string,
    diaCriticsSensitive: boolean = false
): HighlightedPart[] {
    const content: string = diaCriticsSensitive ? originalContent : deburr(originalContent);
    const query: string = diaCriticsSensitive ? originalQuery : deburr(originalQuery);
    let results: HighlightedPart[] = [];
    const filter = new RegExp(escapeRegExp(query), 'i');
    const matches: RegExpExecArray | null = filter.exec(content);
    if (query && query.length > 0 && matches) {
        const startIndex: number = matches.index;
        const endIndex: number = matches.index + matches[0].length;

        const noMatch: string = originalContent.slice(0, startIndex);
        const match: string = originalContent.slice(startIndex, endIndex);
        const remaining: string = originalContent.slice(endIndex);
        if (noMatch) {
            results.push({
                text: noMatch,
                isHighlighted: false,
            });
        }
        if (match) {
            results.push({
                text: match,
                isHighlighted: true,
            });
        }

        results = results.concat(findMatches(remaining, originalQuery, diaCriticsSensitive));
    } else if (originalContent) {
        results.push({
            text: originalContent,
            isHighlighted: false,
        });
    }
    return results;
}

function renderPart(part: HighlightedPart, highlightedClassName: string): JSX.Element {
    const className: string = classNames({
        [`${highlightedClassName}`]: part.isHighlighted,
    });
    return (
        <span key={uniqueId()} className={className}>
            {part.text}
        </span>
    );
}

function renderResults(
    content: string,
    query: string,
    highlightedClassName: string,
    diaCriticsSensitive: boolean = false
): JSX.Element[] {
    const results: HighlightedPart[] = findMatches(content, query, diaCriticsSensitive);

    return map(
        results,
        (part: HighlightedPart): JSX.Element => {
            return renderPart(part, highlightedClassName);
        }
    );
}

export function Highlighter({
    content,
    query,
    className,
    highlightedClassName,
    diacriticsSensitive,
}: HighlighterProps): JSX.Element {
    return (
        <span className={className} aria-label={content}>
            <span aria-hidden={true}>{renderResults(content, query, highlightedClassName, diacriticsSensitive)}</span>
        </span>
    );
}
