import { FaqCategoriesActionType } from './FaqCategoriesActionType';
import { FaqCategoriesAction } from './FaqCategoriesActions';
import { FetchState } from '../Fetch';
import { FaqCategory } from '../../domain/editorial';
import { RequestError } from '../../sagas/APICallSaga';

export type FaqCategoriesState = FetchState<FaqCategory[]>;

export const initialState: FaqCategoriesState = {
    isLoading: false,
};

export const FaqCategoriesReducer = (
    state: FaqCategoriesState = initialState,
    action: FaqCategoriesAction
): FaqCategoriesState => {
    switch (action.type) {
        case FaqCategoriesActionType.FETCH_FAQ_CATEGORIES:
            return {
                ...state,
                isLoading: true,
            };

        case FaqCategoriesActionType.FETCH_FAQ_CATEGORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload as FaqCategory[],
                error: undefined,
            };

        case FaqCategoriesActionType.FETCH_FAQ_CATEGORIES_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
