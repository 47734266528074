import { CyclesActionType } from './CyclesActionType';
import { Cycle } from '../../domain/editorial';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { PaginationData, PaginationAction, SortingAction, SortOption } from '../../domain/listings';

export const FETCHED_PAGE_SIZE = 10;

export type CyclesAction = FetchAction<CyclesActionType, Cycle[]> & PaginationAction<CyclesActionType>;

export interface FetchCyclesAction extends CyclesAction {
    sort: SortOption;
}

export interface CyclesSearchAction extends CyclesAction {
    searchTerm: string;
}

export function createFetchCyclesAction(sort: SortOption, requestedPage: number = 0): FetchCyclesAction {
    return { type: CyclesActionType.FETCH_CYCLES, requestedPage, pageSize: FETCHED_PAGE_SIZE, sort };
}

export function createFetchCyclesSuccessAction(cycles: Cycle[], pagination: PaginationData): CyclesAction {
    return {
        type: CyclesActionType.FETCH_CYCLES_SUCCESS,
        payload: cycles,
        pagination,
    };
}

export function createFetchCyclesErrorAction(error: RequestError): CyclesAction {
    return {
        type: CyclesActionType.FETCH_CYCLES_ERROR,
        payload: error,
    };
}

export function createUpdateCyclesSortingAction(sort: SortOption): SortingAction<CyclesActionType> {
    return {
        type: CyclesActionType.UPDATE_SORTING,
        sort,
    };
}
