import React, { FunctionComponent } from 'react';
import { WithTranslation } from 'next-i18next';
import { withTranslation } from 'react-i18next';
import { Link } from '../common/A11y/Link';
import { ActionButton } from '../common/Button';
import {
    CookiesState,
    CookiesAction,
    createAcceptCookiesAction,
    createDeclineCookiesAction,
    CookiesConsent,
} from '../../modules/cookies';
import { compose, ActionCreator } from 'redux';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';

type StateProps = CookiesState;
interface DispatchProps {
    accept: ActionCreator<CookiesAction>;
    decline: ActionCreator<CookiesAction>;
}
type CookiesBannerProps = StateProps & DispatchProps & WithTranslation;

const _CookiesBanner: FunctionComponent<CookiesBannerProps> = ({
    t,
    accept,
    decline,
    consent,
}: CookiesBannerProps): JSX.Element | null => {
    if (consent === CookiesConsent.ACCEPTED || consent === CookiesConsent.DECLINED) {
        return null;
    }
    return (
        <div className="CookiesBanner">
            <p className="CookiesBanner-Message">
                {t('cookiesMessage')}
                <Link href="/terms-of-use">{t('moreInfo')}</Link>
            </p>
            <ActionButton text={t('decline')} onClick={decline} className="CookiesBanner-DeclineButton" />
            <ActionButton text={t('accept')} onClick={accept} className="CookiesBanner-AcceptButton" />
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => state.cookies;
const mapDispatchToProps = {
    accept: createAcceptCookiesAction,
    decline: createDeclineCookiesAction,
};
export const CookiesBanner = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('cookies')
)(_CookiesBanner) as FunctionComponent;
