import React, { FunctionComponent } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../common/ModalDialog';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { WithTranslation } from 'next-i18next';
import { withTranslation } from 'react-i18next';
import { AppState } from '../../store/reducers';
import { UIState } from '../../modules/UI';
import { connect } from 'react-redux';
import pick from 'lodash/pick';

library.add(faTimes);

interface OwnProps {
    isOpen?: boolean;
    onClose?: () => void;
}

type StateProps = Pick<UIState, 'genericErrorMessage'>;
type GenericErrorModalProps = OwnProps & WithTranslation & StateProps;

export const _GenericErrorModal: FunctionComponent<GenericErrorModalProps> = ({
    t,
    isOpen,
    onClose,
    genericErrorMessage,
}: GenericErrorModalProps): JSX.Element | null => {
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel={t('genericErrorTitle')}
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
        >
            <ModalDialogHeader className="GenericErrorModal-Header" onClose={handleClose} t={t}>
                <FontAwesomeIcon
                    size="1x"
                    aria-hidden="true"
                    icon={faTimes}
                    className="GenericErrorModal-Header-Icon"
                />
                {t('genericErrorTitle')}
            </ModalDialogHeader>
            <ModalDialogBody className="GenericErrorModal-Body">{t(genericErrorMessage)}</ModalDialogBody>
        </ModalDialog>
    );
};

const mapStateToProps = (state: AppState): StateProps => pick(state.UI, 'genericErrorMessage');
export const GenericErrorModal = connect(mapStateToProps)(withTranslation('common')(_GenericErrorModal));
