import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './UserJobsActions';
import { UserJobsActionType } from './UserJobsActionType';
import { callApi } from '../../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { UserJob } from '../../../domain/user';
import { EmbeddedEntities, getEmbeddedEntities } from '../../../domain/core';

function* fetchUserJobs(): SagaIterator {
    yield call(callApi, {
        endpoint: { url: `user-jobs`, params: { size: 1000 } },
        onSuccess: (res: AxiosResponse<EmbeddedEntities<'userJobs', UserJob>>): actions.UserJobsAction =>
            actions.createFetchUserJobsSuccessAction(getEmbeddedEntities(res.data, 'userJobs')),
        onError: actions.createFetchUserJobsErrorAction,
    });
}

export default function* userJobsSaga(): SagaIterator {
    yield takeLatest(UserJobsActionType.FETCH, fetchUserJobs);
}
