import 'focus-visible';
import React, { ComponentType } from 'react';
import App, { AppProps, AppInitialProps } from 'next/app';
import { appWithTranslation } from '../i18n';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import { initStore } from '../store/init';
import { NextComponentType, NextPageContext } from 'next';
import { Store, compose } from 'redux';
import { AppState } from '../store/reducers';
import Router from 'next/router';
import OAuthIframe from '../components/common/OAuthIframe';
import { createResetCartAction } from '../modules/cart';
import Layout from '../components/Layout';
import { LayoutType } from '../domain/app';
import { CookiesBanner } from '../components/CookiesBanner';
import get from 'lodash/get';
import { createGetCookiesConsentAction } from '../modules/cookies';
import { setLocale } from '../modules/locale';
import { createFetchSearchEditorialSuggestionsAction } from '../modules/searchEditorialSuggestions';
import { createSetIPAction } from '../modules/ip';
import isArray from 'lodash/isArray';
import { setDayjsLocale } from '../utils/DateAndTimeUtils';
import init from '../utils/matomo';

import 'bulma/css/bulma.css';
import './App.scss';
import './Accessibility.scss';

Router.events.on('routeChangeComplete', (): void => {
    if (process.env.NODE_ENV !== 'production') {
        const els = document.querySelectorAll<HTMLLinkElement>('link[href*="/_next/static/css/styles.chunk.css"]');
        const timestamp = new Date().valueOf();
        els[0].href = '/_next/static/css/styles.chunk.css?v=' + timestamp;
    }
});

interface WithPageProps {
    pageProps: object;
    layout: LayoutType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseAppPropsWithStore = WithPageProps & { store: Store<AppState> } & AppInitialProps & AppProps<any>;

class MyApp extends App<BaseAppPropsWithStore> {
    public constructor(props: BaseAppPropsWithStore) {
        super(props);
        this.handleStorage = this.handleStorage.bind(this);
    }

    private getLayout(layout: string): ComponentType {
        switch (layout) {
            case 'default':
                return Layout;

            case 'none':
            default:
                return React.Fragment;
        }
    }

    public static async getInitialProps({
        Component,
        ctx,
    }: {
        Component: NextComponentType;
        ctx: NextPageContext;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }): Promise<WithPageProps> {
        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        const cookies: string =
            typeof window !== 'object' ? get(ctx, 'req.headers.cookie', '') : window.document.cookie;
        const store = (ctx as NextPageContext & { store: Store<AppState> }).store;

        store.dispatch(createGetCookiesConsentAction(cookies));

        if (typeof window !== 'object' && ctx.req && ctx.req.headers) {
            let forwardedFor = ctx.req.headers['x-forwarded-for'];
            if (isArray(forwardedFor)) {
                forwardedFor = forwardedFor[0];
            }
            store.dispatch(createSetIPAction(forwardedFor || ''));
        }

        if (ctx.req && (ctx.req as any).language) {
            store.dispatch(setLocale((ctx.req as any).language));
        }
        const { searchEditorialSuggestions } = store.getState();
        if (!searchEditorialSuggestions.data && !searchEditorialSuggestions.isLoading) {
            store.dispatch(createFetchSearchEditorialSuggestionsAction());
        }

        return {
            pageProps,
            layout: (pageProps as any).layout ? (pageProps as any).layout : 'none',
        };
    }

    private handleStorage(event: StorageEvent): void {
        const { store } = this.props;
        if (event.key === 'cart') {
            store.dispatch(createResetCartAction());
        }
    }

    private handleImageLoading(): void {
        if ('loading' in HTMLImageElement.prototype) {
            const images = document.querySelectorAll<HTMLImageElement>('img[loading="lazy"]');
            images.forEach((img: HTMLImageElement): void => {
                if (img.dataset.src) {
                    img.src = img.dataset.src;
                }
            });
        } else {
            if (document.querySelector<HTMLScriptElement>('#lazysizes') === null) {
                const script = document.createElement('script');
                script.id = 'lazysizes';
                script.src = 'https://cdn.jsdelivr.net/npm/lazysizes@5.3.2/lazysizes.min.js';
                document.body.appendChild(script);
            }
        }
    }

    public componentDidMount(): void {
        const { store } = this.props;
        setDayjsLocale(store.getState().locale);
        window.addEventListener('storage', this.handleStorage);
        this.handleImageLoading();

        init({
            url: '//matomo.institutfrancais.com/',
            siteId: '9',
        });
    }

    public componentWillUnmount(): void {
        window.removeEventListener('storage', this.handleStorage);
    }

    public render(): JSX.Element {
        const { Component, layout, pageProps, store } = this.props;
        const Layout = this.getLayout(layout);
        return (
            <Provider store={store}>
                <Layout>
                    <Component {...pageProps} />
                    <CookiesBanner />
                </Layout>
                <OAuthIframe />
                <div id="aria-live-container"></div>
            </Provider>
        );
    }
}

export default compose(
    withRedux(initStore),
    appWithTranslation,
    withReduxSaga
)(MyApp);
