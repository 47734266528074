import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './CycleActions';
import { CycleActionType } from './CycleActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { Cycle } from '../../domain/editorial';
import { CycleStateData } from './CycleStateReducer';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { isFree } from '../../domain/utils/CycleUtils';
import { Movie } from '../../domain/work';
import { VideoProduct } from '../../domain/commerce';
import map from 'lodash/map';
import first from 'lodash/first';
import { AnyAction } from 'redux';

function* fetchCycle(action: actions.CycleAction): SagaIterator {
    yield call(callApi, {
        endpoint: [`cycles/${action.id}`, `cycles/${action.id}/related`],
        onSuccess: (
            cycle: AxiosResponse<Cycle>,
            relatedCycles: AxiosResponse<EmbeddedEntities<'cycles', Cycle>>
        ): actions.CycleAction => {
            const payload: CycleStateData = {
                ...cycle.data,
                relatedCycles: getEmbeddedEntities(relatedCycles.data, 'cycles'),
            };
            return actions.createFetchCycleSuccessAction(payload);
        },
        onError: actions.createFetchCycleErrorAction,
    });
}

function* fetchCycleBySlug(action: actions.CycleAction): SagaIterator {
    yield call(callApi, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        endpoint: { url: 'cycles', params: { q_slug: action.slug } },
        onSuccess: (cycles: AxiosResponse<EmbeddedEntities<'cycles', Cycle>>): actions.CycleAction => {
            const cycle = first(getEmbeddedEntities(cycles.data, 'cycles'));
            const payload: CycleStateData = {
                ...(cycle as Cycle),
            };
            return actions.createFetchCycleSuccessAction(payload);
        },
        onError: actions.createFetchCycleErrorAction,
    });
}

function* fetchCycleMovieProducts(action: actions.FetchCycleMoviesProductsAction): SagaIterator {
    if (action.payload == null) {
        return;
    }
    if (isFree(action.payload)) {
        yield call(callApi, {
            endpoint: (action.payload as Cycle).works!.map((movie: Movie): string => `movies/${movie.id}/products`),
            onSuccess: (
                ...responses: AxiosResponse<EmbeddedEntities<'videoProducts', VideoProduct>>[]
            ): actions.SetCycleMoviesProductsAction => {
                return actions.createSetCycleMoviesProductsAction(
                    map(responses, (response): VideoProduct[] => getEmbeddedEntities(response.data, 'videoProducts'))
                );
            },
            onError: (): AnyAction => ({
                type: 'NO_OP',
            }),
        });
    }
}

export default function* cycleSaga(): SagaIterator {
    yield takeLatest(CycleActionType.FETCH_CYCLE, fetchCycle);
    yield takeLatest(CycleActionType.FETCH_CYCLE_BY_SLUG, fetchCycleBySlug);
    yield takeLatest(CycleActionType.FETCH_CYCLE_MOVIE_PRODUCTS, fetchCycleMovieProducts);
}
