import { CatalogsActionType } from './CatalogsActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { Catalog } from '../../domain/editorial';

export type CatalogsAction = FetchAction<CatalogsActionType, Catalog[]>;

export function createFetchCatalogsAction(): CatalogsAction {
    return { type: CatalogsActionType.FETCH_CATALOGS };
}

export function createFetchCatalogsSuccessAction(catalogs: Catalog[]): CatalogsAction {
    return {
        type: CatalogsActionType.FETCH_CATALOGS_SUCCESS,
        payload: catalogs,
    };
}

export function createFetchCatalogsErrorAction(error: RequestError): CatalogsAction {
    return {
        type: CatalogsActionType.FETCH_CATALOGS_ERROR,
        payload: error,
    };
}
