import React, { useState } from 'react';
import { CommunicationKitModal } from './CommunicationKitModal';
import { WithTranslation } from 'next-i18next';

export type CommunicationKitProps = WithTranslation;

export function CommunicationKit({ i18n, t }: CommunicationKitProps): JSX.Element {
    const [isModalOpen, setModalIsOpen] = useState<boolean>(false);

    return (
        <>
            <a
                href="#"
                onClick={(): void => {
                    setModalIsOpen(true);
                }}
            >
                {t('communicationKit')}
            </a>
            <CommunicationKitModal
                isOpen={isModalOpen}
                onClose={(): void => {
                    setModalIsOpen(false);
                }}
                t={t}
                i18n={i18n}
                tReady={true}
            />
        </>
    );
}
