import { takeLatest, call } from 'redux-saga/effects';
import * as actions from './CentreTypesActions';
import { CentreTypesActionType } from './CentreTypesActionType';
import { callApi } from '../../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { EmbeddedEntities, getEmbeddedEntities } from '../../../domain/core';
import { CentreType } from '../../../domain/user';

function* fetchCentreTypes(): SagaIterator {
    yield call(callApi, {
        endpoint: { url: `centre-types`, params: { size: 1000 } },
        onSuccess: (res: AxiosResponse<EmbeddedEntities<'centreTypes', CentreType>>): actions.CentreTypesAction =>
            actions.createFetchCentreTypesSuccessAction(getEmbeddedEntities(res.data, 'centreTypes')),
        onError: actions.createFetchCentreTypesErrorAction,
    });
}

export default function* centreTypesSaga(): SagaIterator {
    yield takeLatest(CentreTypesActionType.FETCH, fetchCentreTypes);
}
