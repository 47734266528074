import { SearchEditorialSuggestionsActionType } from './SearchEditorialSuggestionsActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { SearchEditorialSuggestion } from '../../domain/search';

export interface SearchEditorialSuggestionsAction
    extends FetchAction<SearchEditorialSuggestionsActionType, SearchEditorialSuggestion[]> {
    terms?: string;
}

export function createFetchSearchEditorialSuggestionsAction(): SearchEditorialSuggestionsAction {
    return { type: SearchEditorialSuggestionsActionType.FETCH_EDITORIAL_SUGGESTIONS };
}

export function createFetchSearchEditorialSuggestionsSuccessAction(
    suggestions: SearchEditorialSuggestion[]
): SearchEditorialSuggestionsAction {
    return {
        type: SearchEditorialSuggestionsActionType.FETCH_EDITORIAL_SUGGESTIONS_SUCCESS,
        payload: suggestions,
    };
}

export function createFetchSearchEditorialSuggestionsErrorAction(
    error: RequestError
): SearchEditorialSuggestionsAction {
    return {
        type: SearchEditorialSuggestionsActionType.FETCH_EDITORIAL_SUGGESTIONS_ERROR,
        payload: error,
    };
}
