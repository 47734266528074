import React, { useEffect, FunctionComponent, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { WithT } from 'i18next';

import { Button } from '../../common/A11y/Button';

interface CartMenuItemProps extends WithT {
    cartItemsLength: number;
    cartIsReady: boolean;
    toggleMiniCart: () => void;
}

export const CartMenuItem: FunctionComponent<CartMenuItemProps> = ({
    cartItemsLength,
    cartIsReady,
    toggleMiniCart,
    t,
}: CartMenuItemProps): JSX.Element | null => {
    const [count, setCount] = useState<number>(0);
    const [counterShouldFlash, setCounterShouldFlash] = useState<boolean>(false);

    useEffect((): void => {
        if (cartIsReady && count < cartItemsLength) {
            setCounterShouldFlash(true);
        }
        setCount(cartItemsLength);
    }, [cartIsReady, cartItemsLength, count]);

    if (cartItemsLength === 0) {
        return null;
    }

    return (
        <Button
            className="CartMenuItem"
            textClassName="CartMenuItem-Text"
            onClick={toggleMiniCart}
            customIconPath="/static/img/logo_basket.svg"
            iconPosition="right"
            iconClassName="CartMenuItem-Icon"
            text={
                <>
                    <span className="CartMenuItem-Label">{t('cart:myOrder')}</span>
                    <CSSTransition
                        in={counterShouldFlash}
                        timeout={1000}
                        classNames="CartMenuItem-Count"
                        onEntered={(): void => setCounterShouldFlash(false)}
                    >
                        <span className="CartMenuItem-Count">{cartItemsLength}</span>
                    </CSSTransition>
                </>
            }
        />
    );
};
