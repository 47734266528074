import React from 'react';
import classNames from 'clsx';
import { SearchEditorialSuggestion } from '../../../domain/search';
import { Link } from '../../common/A11y/Link';
import isEmpty from 'lodash/isEmpty';
import { Router } from '../../../i18n';
import { UIAction } from '../../../modules/UI';
import { ActionCreator } from 'redux';
import { getSearchTermUrl } from '../../../utils/RouterUtils';
import { WithT } from 'i18next';

interface SearchEditorialSuggestionsProps extends WithT {
    isDisplayed: boolean;
    suggestions?: SearchEditorialSuggestion[];
    toggleSearchModal: ActionCreator<UIAction>;
}

export const SearchEditorialSuggestions = ({
    isDisplayed,
    suggestions,
    toggleSearchModal,
    t,
}: SearchEditorialSuggestionsProps): JSX.Element | null => {
    if (isEmpty(suggestions)) {
        return null;
    }
    return (
        <div
            className={classNames('SearchEditorialSuggestions', {
                'SearchEditorialSuggestions-displayed': isDisplayed,
            })}
        >
            <h3 className="SearchEditorialSuggestions-Title">{t('IFSuggestsYou')}</h3>
            <ul className="SearchEditorialSuggestions-List">
                {suggestions!.map(
                    (suggestion: SearchEditorialSuggestion, i: number): JSX.Element => {
                        const termsLink = getSearchTermUrl(suggestion.title);
                        return (
                            <li className="SearchEditorialSuggestions-List-Item" key={i}>
                                <Link
                                    href={termsLink}
                                    className="SearchEditorialSuggestions-List-Item-Link"
                                    tabIndex={isDisplayed ? 0 : -1}
                                    onClick={(): void => {
                                        toggleSearchModal();
                                        Router.push(termsLink);
                                    }}
                                >
                                    {suggestion.title}
                                </Link>
                            </li>
                        );
                    }
                )}
            </ul>
        </div>
    );
};
