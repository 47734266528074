import { AnyAction } from 'redux';

import { FetchState } from '../Fetch';
import { GeoLocation } from '../../server/geoip-middleware';

import { UserGeoLocationType } from './UserGeoLocationType';
import { UserGeoLocationAction } from './UserGeoLocationActions';

export interface UserGeoLocationStateData {
    userGeoLocation?: GeoLocation | null;
}

export type UserGeoLocationState = FetchState<UserGeoLocationStateData>;

export const initialState: UserGeoLocationState = {
    isLoading: false,
};

export const userGeolocationReducer = (
    state: UserGeoLocationState = initialState,
    action: AnyAction
): UserGeoLocationState => {
    switch (action.type) {
        case UserGeoLocationType.FETCH_USER_COUNTRY_SUCCESS:
            return {
                ...state,
                data: { ...state.data, userGeoLocation: (action as UserGeoLocationAction).payload },
            };
        default:
            return state;
    }
};
