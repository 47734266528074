import React from 'react';
import { Link, Router } from '../../i18n';
import { Navbar } from 'react-bulma-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import DropdownHelp from './DropdownHelp';
import { TFunction } from 'i18next';
import { DropdownCatalog } from './DropdownCatalog';
import { NavbarMenu } from '../common/TypedBulmaComponents';
import { UserMenu } from '../UserMenu';

library.add(faSearch);

export interface NavBarMenuProps {
    t: TFunction;
    openSearchModal: () => void;
}

const NavBarMenu: React.FC<NavBarMenuProps> = ({ t, openSearchModal }: NavBarMenuProps): JSX.Element => {
    return (
        <NavbarMenu renderAs="nav" aria-label={t('mainMenu')} role="navigation">
            <ul>
                <Navbar.Item renderAs="li">
                    <DropdownCatalog t={t} />
                </Navbar.Item>
                <Navbar.Item renderAs="li">
                    <Link href="/cycles">
                        <a>{t('common:cycles')}</a>
                    </Link>
                </Navbar.Item>
                <Navbar.Item renderAs="li">
                    <button
                        className="button"
                        onClick={(): Promise<boolean> => {
                            return Router.push('/#collections');
                        }}
                    >
                        <a>{t('homepage:collections')}</a>
                    </button>
                </Navbar.Item>
                <Navbar.Item renderAs="li">
                    <Link href="/education">
                        <a>{t('homepage:education')}</a>
                    </Link>
                </Navbar.Item>
                <Navbar.Item renderAs="li">
                    <button className="button" onClick={openSearchModal}>
                        <span className="header-icon">
                            <FontAwesomeIcon aria-hidden="true" icon={faSearch} flip="horizontal" />
                        </span>
                        <span className="visually-hidden">{t('search')}</span>
                    </button>
                </Navbar.Item>
                <Navbar.Item renderAs="li">
                    <DropdownHelp t={t} />
                </Navbar.Item>
                <UserMenu t={t} />
            </ul>
        </NavbarMenu>
    );
};

export default NavBarMenu;
