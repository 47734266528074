import { FaqCategoryQuestionActionType } from './FaqCategoryQuestionActionType';
import { RequestError } from '../../sagas/APICallSaga';
import { FetchAction } from '../Fetch';
import { FaqCategoryQuestion } from '../../domain/editorial';
import { uuid } from '../../domain/core';

export interface FaqCategoryQuestionAction extends FetchAction<FaqCategoryQuestionActionType, FaqCategoryQuestion> {
    id?: uuid;
    code?: string;
}

export function createFetchCategoryQuestionAction(id: uuid): FaqCategoryQuestionAction {
    return {
        type: FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION,
        id,
    };
}

export function createFetchCategoryQuestionByCodeAction(code: string): FaqCategoryQuestionAction {
    return {
        type: FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION,
        code,
    };
}

export function createFetchCategoryQuestionSuccessAction(
    faqCategoryQuestion: FaqCategoryQuestion
): FaqCategoryQuestionAction {
    return {
        type: FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION_SUCCESS,
        payload: faqCategoryQuestion,
    };
}

export function createFetchCategoryQuestionErrorAction(error: RequestError): FaqCategoryQuestionAction {
    return {
        type: FaqCategoryQuestionActionType.FETCH_FAQ_CATEGORY_QUESTION_ERROR,
        payload: error,
    };
}
