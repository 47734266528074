import { CookiesActionType } from './CookiesActionType';
import { Action } from 'redux';
import { CookiesConsent } from './CookiesStateReducer';

export type CookiesAction = Action<CookiesActionType>;
export interface GetCookiesConsentSuccessAction extends CookiesAction {
    consent: CookiesConsent;
}
export interface GetCookiesConsentAction extends CookiesAction {
    cookies: string;
}

export function createAcceptCookiesAction(): CookiesAction {
    return { type: CookiesActionType.ACCEPT };
}

export function createDeclineCookiesAction(): CookiesAction {
    return { type: CookiesActionType.DECLINE };
}

export function createGetCookiesConsentAction(cookies: string): GetCookiesConsentAction {
    return { type: CookiesActionType.GET_CONSENT, cookies };
}

export function createGetCookiesConsentSuccessAction(consent: CookiesConsent): GetCookiesConsentSuccessAction {
    return { type: CookiesActionType.GET_CONSENT_SUCCESS, consent };
}
