import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store/reducers';
import { Action } from 'redux';
import { createFetchCentresAction, CentresFetchParams } from './centres';
import { createFetchCentreTypesAction } from './centreTypes';
import { createFetchUserJobsAction } from './userJobs';
import { createFetchLegalCountriesAction } from '../legalCountries/LegalCountriesActions';

export interface FetchFormDataConfig {
    fetchCentres?: boolean;
    fetchCentreTypes?: boolean;
    fetchUserJobs?: boolean;
    fetchLegalCountries?: boolean;
    centresParams?: CentresFetchParams;
}

const defaultFetchFormDataConfig = {
    fetchCentres: true,
    fetchCentreTypes: true,
    fetchUserJobs: true,
    fetchLegalCountries: true,
};

export function fetchFormData(config?: FetchFormDataConfig): (dispatch: ThunkDispatch<AppState, {}, Action>) => void {
    config = { ...defaultFetchFormDataConfig, ...config };
    const { fetchCentres, fetchCentreTypes, fetchUserJobs, fetchLegalCountries, centresParams } = config;
    return (dispatch: ThunkDispatch<AppState, {}, Action>): void => {
        if (fetchCentres) {
            dispatch(createFetchCentresAction(centresParams));
        }
        if (fetchCentreTypes) {
            dispatch(createFetchCentreTypesAction());
        }
        if (fetchUserJobs) {
            dispatch(createFetchUserJobsAction());
        }
        if (fetchLegalCountries) {
            dispatch(createFetchLegalCountriesAction());
        }
    };
}
