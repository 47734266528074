import React from 'react';
import { SearchSuggestions as SearchSuggestionsDomain } from '../../../domain/search';
import classNames from 'clsx';
import { SearchSuggestionsStatus } from './SearchSuggestionsStatus';
import { SearchSuggestionsTerms } from './SearchSuggestionsTerms';
import { SearchSuggestionsMovies } from './SearchSuggestionsMovies';
import { useCycleThroughSuggestions } from './useCycleThroughSuggestions';
import size from 'lodash/size';
import { ActionCreator } from 'redux';
import { UIAction } from '../../../modules/UI';
import { WithT } from 'i18next';

export interface SearchSuggestionsProps extends Partial<SearchSuggestionsDomain>, WithT {
    isDisplayed: boolean;
    isLoading: boolean;
    query: string;
    toggleSearchModal: ActionCreator<UIAction>;
}

export const SearchSuggestions = (props: SearchSuggestionsProps): JSX.Element | null => {
    useCycleThroughSuggestions(props.terms);
    return (
        <div
            className={classNames('SearchSuggestions', {
                'SearchSuggestions-displayed': props.isDisplayed,
                'SearchSuggestions-no-results': !props.isLoading && size(props.terms) === 0 && size(props.movies) === 0,
            })}
        >
            <SearchSuggestionsStatus {...props} />
            {props.terms && (
                <SearchSuggestionsTerms
                    terms={props.terms}
                    query={props.query}
                    toggleSearchModal={props.toggleSearchModal}
                />
            )}
            {props.cycles && props.movies && (
                <SearchSuggestionsMovies
                    movies={props.movies}
                    cycles={props.cycles}
                    toggleSearchModal={props.toggleSearchModal}
                    t={props.t}
                />
            )}
        </div>
    );
};
