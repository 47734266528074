import React from 'react';
import { WithT } from 'i18next';
import { NonBreakingWhiteSpace } from '../NonBreakingSpace';

export interface TotalMoviesProp extends WithT {
    total: number;
    label?: string;
}

export const TotalMovies = (props: TotalMoviesProp): JSX.Element => (
    <span className="Total-Movies">
        <span className="Total-Movies__Nb">{props.total}</span>
        <NonBreakingWhiteSpace />
        {props.label || props.t('films')}
    </span>
);
